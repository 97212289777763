import React from 'react';
import PropTypes from 'prop-types';

import BrandLogo from './gearro-logo.svg';

function Logo(props) {
  const { className, format } = props;

  return <img className={className} src={BrandLogo} alt="Gearro logo"/>;
}

const { oneOf, string } = PropTypes;

Logo.defaultProps = {
  className: null,
  format: 'desktop',
};

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
};

export default Logo;
