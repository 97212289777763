import { removeCurrentUserFavoriteListingsSuccess } from '../../ducks/user.duck';
import { fetchUserFavoriteListings, removeUserFavoriteListing } from '../../util/api';

export const FETCH_FAVORITE_LISTINGS_REQUEST = 'app/FavoriteListingsPage/FETCH_FAVORITE_LISTINGS_REQUEST';
export const FETCH_FAVORITE_LISTINGS_SUCCESS = 'app/FavoriteListingsPage/FETCH_FAVORITE_LISTINGS_SUCCESS';
export const FETCH_FAVORITE_LISTINGS_ERROR = 'app/FavoriteListingsPage/FETCH_FAVORITE_LISTINGS_ERROR';

export const REMOVE_FAVORITE_LISTINGS_REQUEST = 'app/FavoriteListingsPage/REMOVE_FAVORITE_LISTINGS_REQUEST';
export const REMOVE_FAVORITE_LISTINGS_SUCCESS = 'app/FavoriteListingsPage/REMOVE_FAVORITE_LISTINGS_SUCCESS';
export const REMOVE_FAVORITE_LISTINGS_ERROR = 'app/FavoriteListingsPage/REMOVE_FAVORITE_LISTINGS_ERROR';

export const UPDATE_PREVIOUS_PATH = 'app/FavoriteListingsPage/UPDATE_PREVIOUS_PATH';

const initialState = {
  favoriteListings: [],
  favoriteListingsLoading: false,
  favoriteListingsError: null,
  previousPath: '/',
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_FAVORITE_LISTINGS_REQUEST:
      return { ...state, favoriteListingsLoading: true };
    case FETCH_FAVORITE_LISTINGS_SUCCESS:
      return {
        ...state,
        favoriteListings: payload.data,
        favoriteListingsLoading: false,
      };
    case FETCH_FAVORITE_LISTINGS_ERROR:
      console.error(payload);
      return {
        ...state,
        favoriteListingsLoading: false,
        favoriteListingsError: payload,
      };

    case REMOVE_FAVORITE_LISTINGS_REQUEST:
      return { ...state, favoriteListingsLoading: false };
    case REMOVE_FAVORITE_LISTINGS_SUCCESS:
      return {
        ...state,
        favoriteListings: state.favoriteListings.filter(listing => listing.id.uuid != payload.listingId),
        favoriteListingsLoading: false,
      };
    case REMOVE_FAVORITE_LISTINGS_ERROR:
      console.error(payload);
      return {
        ...state,
        favoriteListingsLoading: false,
        favoriteListingsError: payload,
      };

    case UPDATE_PREVIOUS_PATH:
      return { ...state, previousPath: payload.data };
    default:
      return state;
  }
}

const fetchFavoriteListingsRequest = () => ({
  type: FETCH_FAVORITE_LISTINGS_REQUEST,
});

const fetchFavoriteListingsSuccess = favoriteListings => ({
  type: FETCH_FAVORITE_LISTINGS_SUCCESS,
  payload: { data: favoriteListings },
});

const fetchFavoriteListingsError = error => ({
  type: FETCH_FAVORITE_LISTINGS_ERROR,
  payload: error,
});

const removeFavoriteListingsRequest = () => ({
  type: REMOVE_FAVORITE_LISTINGS_REQUEST,
});

const removeFavoriteListingsSuccess = listingId => ({
  type: REMOVE_FAVORITE_LISTINGS_SUCCESS,
  payload: { listingId },
});

const removeFavoriteListingsError = error => ({
  type: REMOVE_FAVORITE_LISTINGS_ERROR,
  payload: error,
});

export const updateFavoriteListingsPreviousPath = path => ({
  type: UPDATE_PREVIOUS_PATH,
  payload: { data: path },
});

export const fetchFavoriteListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchFavoriteListingsRequest());

  const { currentUser } = getState().user;
  const currentUserId = currentUser?.id?.uuid;

  return fetchUserFavoriteListings(currentUserId)
    .then(response => {
      dispatch(fetchFavoriteListingsSuccess(response.data.data.favoriteListings));
      return response;
    })
    .catch(e => {
      dispatch(fetchFavoriteListingsError(e));
    });
};

export const removeFavoriteListings = listingId => (dispatch, getState, sdk) => {
  dispatch(removeFavoriteListingsRequest());

  const { currentUser } = getState().user;
  const currentUserId = currentUser?.id?.uuid;

  return removeUserFavoriteListing(currentUserId, listingId)
    .then(response => {
      dispatch(removeFavoriteListingsSuccess(listingId));
      dispatch(removeCurrentUserFavoriteListingsSuccess(listingId));
      return response;
    })
    .catch(e => {
      dispatch(removeFavoriteListingsError(e));
    });
};
