import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import css from './FieldToggle.module.css';

function FieldToggle(props) {
  const { formId, name, title, description } = props;

  return (
    <div className={css.container}>
      <div>
        <label className={css.title}>{title}</label>
        <div className={css.description}>{description}</div>
      </div>

      <Field name={name} type="checkbox">
        {props => (
          <div className={css.toggleButton}>
            <input
              className={css.toggleButtonInput}
              id={formId ? `${formId}.isContactAllowed` : 'isContactAllowed'}
              {...props.input}
            />
            <label
              className={css.toggleButtonLabel}
              htmlFor={formId ? `${formId}.isContactAllowed` : 'isContactAllowed'}
            >
              {title}
            </label>
          </div>
        )}
      </Field>
    </div>
  );
}

const { string } = PropTypes;

FieldToggle.propTypes = {
  title: string,
  description: string,
  formId: string,
  name: string.isRequired,
};

export default FieldToggle;
