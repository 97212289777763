import React, { useState } from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { createSlug, LISTING_PAGE_PARAM_TYPE_EDIT } from '../../util/urlHelpers';
import { pathByRouteName } from '../../util/routes';
import routeConfiguration from '../../routing/routeConfiguration';
import { NamedLink } from '..';

import css from './ListingCard.module.css';
import ChevronIcon from '../../assets/icons/right-arrow.png';
import Spacer, { HORIZONTALL_AXIS, SMALL_SPACER } from '../Spacer/Spacer';
import DeleteListingModal from './DeleteListingModal';

const priceData = (price, intl) => {
  const formattedPrice = formatMoney(intl, price);
  return { formattedPrice, priceTitle: formattedPrice };
};

function ListingCardActionButtons(props) {
  const { id, slug, intl, listingType, onDeleteClick } = props;

  return (
    <div className={css.actionButtons}>
      <NamedLink
        name="EditListingPage"
        className={css.editListingButton}
        params={{
          listingType,
          id,
          slug,
          type: LISTING_PAGE_PARAM_TYPE_EDIT,
          tab: 'categories',
        }}
      >
        {intl.formatMessage({ id: 'profile_page.edit_listing' })}
      </NamedLink>
      <Spacer size={SMALL_SPACER} axis={HORIZONTALL_AXIS} />
      <button className={css.deleteListingButton} onClick={onDeleteClick} type="button">
        {intl.formatMessage({ id: 'profile_page.delete_listing' })}
      </button>
    </div>
  );
}

export function ListingCardComponent(props) {
  const { className, rootClassName, intl, listing, history, onListingDelete } = props;
  const { id, sharetribeItemId, title, price, type, availableActions } = listing;

  const classes = classNames(rootClassName || css.root, className);
  const slug = createSlug(title);
  const firstImageUrl = listing.imagesUrls && listing.imagesUrls.length > 0 ? listing.imagesUrls[0] : null;
  const canEdit = availableActions.includes('edit');
  const canDelete = availableActions.includes('delete');

  const { formattedPrice, priceTitle } = priceData(price, intl);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleListingClick = e => {
    const element = e.target.nodeName.toLocaleLowerCase();

    if (element === 'button' || element === 'a') return;

    const routes = routeConfiguration();
    const path = pathByRouteName('ListingPageWithReturn', routes, { id: sharetribeItemId, slug });

    const location = {
      pathname: path,
      state: { prevPath: history.location.pathname },
    };

    history.push(location);
  };

  const handleDeleteClick = () => {
    setIsDeleteModalOpen(true);
  };

  const handleModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <div className={classes} onClick={handleListingClick}>
        <img className={css.listingImage} src={firstImageUrl} />
        <Spacer size={SMALL_SPACER} axis={HORIZONTALL_AXIS} />
        <div className={css.info}>
          <div>
            <span className={css.itemTitle}>{title}</span>
            <div className={css.priceValue} title={priceTitle}>
              {formattedPrice}
            </div>
          </div>
          {canEdit && canDelete ? (
            <ListingCardActionButtons
              id={sharetribeItemId}
              onDeleteClick={handleDeleteClick}
              slug={slug}
              intl={intl}
              listingType={type}
            />
          ) : null}
        </div>
        <div className={css.chevronWrapper}>
          <span className={css.chevronDescription}>{intl.formatMessage({ id: 'profile_page.listing.view' })}</span>
          <img className={css.chevron} src={ChevronIcon} />
        </div>
      </div>
      {isDeleteModalOpen && (
        <DeleteListingModal
          onCloseModal={handleModalClose}
          intl={intl}
          listingId={id}
          onListingDelete={onListingDelete}
        />
      )}
    </>
  );
}

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

export default injectIntl(withRouter(ListingCardComponent));
