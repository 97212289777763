import { Stack, Typography } from '@mui/material';
import { Form as FinalForm } from 'react-final-form';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { Button, FieldPhoneNumberInput, FieldSelect, FieldTextInput, Form } from '..';
import getCountryCodes from '../../translations/countryCodes';
import * as validators from '../../util/validators';

function AddressInformationForm(props) {
  return (
    <FinalForm
      {...props}
      render={({
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        updated,
        updateInProgress,
        apiFormError,
      }) => {
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;
        const countryCodes = getCountryCodes(intl.locale);

        return (
          <Form onSubmit={handleSubmit}>
            <Stack gap={2}>
              <FieldTextInput
                id="firstName"
                name="firstName"
                type="text"
                label={intl.formatMessage({ id: 'listing_order_page.delivery_info.first_name' })}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'listing_order_page.delivery_info.first_name_required',
                  })
                )}
                autoFocus={false}
                isMandatory
                placeholder={intl.formatMessage({
                  id: 'listing_order_page.delivery_info.first_name_placeholder',
                })}
                customErrorText={apiFormError?.errors?.firstName?.[0]}
              />

              <FieldTextInput
                id="lastName"
                name="lastName"
                type="text"
                label={intl.formatMessage({ id: 'listing_order_page.delivery_info.last_name' })}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'listing_order_page.delivery_info.last_name_required',
                  })
                )}
                autoFocus={false}
                isMandatory
                placeholder={intl.formatMessage({
                  id: 'listing_order_page.delivery_info.last_name_placeholder',
                })}
                customErrorText={apiFormError?.errors?.lastName?.[0]}
              />

              <FieldSelect
                id="countryCode"
                name="countryCode"
                disabled
                label={intl.formatMessage({ id: 'listing_order_page.delivery_info.country' })}
              >
                <option disabled value="">
                  {intl.formatMessage({ id: 'listing_order_page.delivery_info.country_placeholder' })}
                </option>
                {countryCodes.map(country => (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </FieldSelect>

              <FieldTextInput
                id="line1"
                name="line1"
                type="text"
                label={intl.formatMessage({ id: 'listing_order_page.delivery_info.address_line_1' })}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'listing_order_page.delivery_info.address_line_1_required',
                  })
                )}
                autoFocus={false}
                isMandatory
                placeholder={intl.formatMessage({
                  id: 'listing_order_page.delivery_info.address_line_1_placeholder',
                })}
                customErrorText={apiFormError?.errors?.line1?.[0]}
              />

              <FieldTextInput
                id="line2"
                name="line2"
                type="text"
                label={intl.formatMessage({ id: 'listing_order_page.delivery_info.address_line_2' })}
                autoFocus={false}
                placeholder={intl.formatMessage({
                  id: 'listing_order_page.delivery_info.address_line_2_placeholder',
                })}
                customErrorText={apiFormError?.errors?.line2?.[0]}
              />

              <FieldTextInput
                id="postalCode"
                name="postalCode"
                type="text"
                label={intl.formatMessage({ id: 'listing_order_page.delivery_info.postal_code' })}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'listing_order_page.delivery_info.postal_code_required',
                  })
                )}
                autoFocus={false}
                isMandatory
                placeholder={intl.formatMessage({
                  id: 'listing_order_page.delivery_info.postal_code_placeholder',
                })}
                customErrorText={apiFormError?.errors?.postalCode?.[0]}
              />

              <FieldTextInput
                id="city"
                name="city"
                type="text"
                label={intl.formatMessage({ id: 'listing_order_page.delivery_info.city' })}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'listing_order_page.delivery_info.city_required',
                  })
                )}
                autoFocus={false}
                isMandatory
                placeholder={intl.formatMessage({
                  id: 'listing_order_page.delivery_info.city_placeholder',
                })}
                customErrorText={apiFormError?.errors?.city?.[0]}
              />

              <FieldPhoneNumberInput
                type="phoneNumber"
                name="phoneNumber"
                id="phoneNumber"
                label={intl.formatMessage({ id: 'listing_order_page.delivery_info.phone_number' })}
                placeholder={intl.formatMessage({
                  id: 'listing_order_page.delivery_info.phone_number_placeholder',
                })}
                isMandatory
                validate={validators.composeValidators(
                  validators.required(
                    intl.formatMessage({
                      id: 'listing_order_page.delivery_info.phone_number_required',
                    })
                  )
                )}
                customErrorText={apiFormError?.errors?.phoneNumber?.[0]}
              />

              <Typography variant="body2" color="textSecondary" fontSize={12} fontWeight={400} my={1}>
                {intl.formatMessage({ id: 'listing_order_page.shipping.delivery_info_disclaimer' })}
              </Typography>

              <Button type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                {intl.formatMessage({ id: 'listing_order_page.shipping.save_address' })}
              </Button>
            </Stack>
          </Form>
        );
      }}
    />
  );
}

export default compose(injectIntl)(AddressInformationForm);
