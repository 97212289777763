import { useApi } from './useApi';

const USER_KEY = 'currentUser';

export const useLoadUser = isAuthenticated => {
  const savedUser = typeof window !== 'undefined' && window.sessionStorage.getItem(USER_KEY);
  const loadedUser = useApi(
    async api => {
      if (!isAuthenticated) {
        return null;
      }
      const result = await api.currentUser();
      if (typeof window !== 'undefined') {
        window.sessionStorage.setItem(USER_KEY, JSON.stringify(result.data.user));
      }
      return result.data.user;
    },
    [isAuthenticated]
  );

  const user = loadedUser.data || savedUser ? JSON.parse(savedUser) : null;
  return { ...user, loading: loadedUser.loading, refresh: loadedUser.execute };
};
