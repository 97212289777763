// Add here the translations of the country names using key "<language_code>: 'transalation'" e.g. fi: 'Afganistan'
// prettier-ignore
const countryCodes = [
  {
    code: 'AF', en: 'Afghanistan', fr: 'Afghanistan', es: 'Afganistán', de: 'Afghanistan', lt: 'Afganistanas', pl: 'Afganistan',
  },
  {
    code: 'AX', en: 'Åland Islands', fr: 'Îles Åland', es: 'Islas Áland', de: 'Åland', lt: 'Alandų salos', pl: 'Wyspy Alandzkie',
  },
  {
    code: 'AL', en: 'Albania', fr: 'Albanie', es: 'Albania', de: 'Albanien', lt: 'Albanija', pl: 'Albania',
  },
  {
    code: 'DZ', en: 'Algeria', fr: 'Algérie', es: 'Argel', de: 'Algerien', lt: 'Alžyras', pl: 'Algieria',
  },
  {
    code: 'AS', en: 'American Samoa', fr: 'Samoa américaines', es: 'Samoa Americana', de: 'Amerikanisch-Samoa', lt: 'Amerikos Samoa', pl: 'Samoa Amerykańskie',
  },
  {
    code: 'AD', en: 'Andorra', fr: 'Andorre', es: 'Andorra', de: 'Andorra', lt: 'Andora', pl: 'Andora',
  },
  {
    code: 'AO', en: 'Angola', fr: 'Angola', es: 'Angola', de: 'Angola', lt: 'Angola', pl: 'Angola',
  },
  {
    code: 'AI', en: 'Anguilla', fr: 'Anguilla', es: 'Anguila', de: 'Anguilla', lt: 'Angilija', pl: 'Anguilla',
  },
  {
    code: 'AQ', en: 'Antarctica', fr: 'Antarctique', es: 'Antártida', de: 'Antarktika', lt: 'Antarktida', pl: 'Antarktyda',
  },
  {
    code: 'AG', en: 'Antigua and Barbuda', fr: 'Antigua-et-Barbuda', es: 'Antigua y Barbuda', de: 'Antigua und Barbuda', lt: 'Antigva ir Barbuda', pl: 'Antigua i Barbuda',
  },
  {
    code: 'AR', en: 'Argentina', fr: 'Argentine', es: 'Argentina', de: 'Argentinien', lt: 'Argentina', pl: 'Argentyna',
  },
  {
    code: 'AM', en: 'Armenia', fr: 'Arménie', es: 'Armenia', de: 'Armenien', lt: 'Armėnija', pl: 'Armenia',
  },
  {
    code: 'AW', en: 'Aruba', fr: 'Aruba', es: 'Aruba', de: 'Aruba', lt: 'Aruba', pl: 'Aruba',
  },
  {
    code: 'AU', en: 'Australia', fr: 'Australie', es: 'Australia', de: 'Australien', lt: 'Australija', pl: 'Australia',
  },
  {
    code: 'AT', en: 'Austria', fr: 'Autriche', es: 'Austria', de: 'Österreich', lt: 'Austrija', pl: 'Austria',
  },
  {
    code: 'AZ', en: 'Azerbaijan', fr: 'Azerbaïdjan', es: 'Azerbaiyán', de: 'Aserbaidschan', lt: 'Azerbaidžanas', pl: 'Azerbejdżan',
  },
  {
    code: 'BS', en: 'Bahamas', fr: 'Bahamas', es: 'Bahamas', de: 'Bahamas', lt: 'Bahamos', pl: 'Bahamy',
  },
  {
    code: 'BH', en: 'Bahrain', fr: 'Bahreïn', es: 'Bahréin', de: 'Bahrain', lt: 'Bahreinas', pl: 'Bahrajn',
  },
  {
    code: 'BD', en: 'Bangladesh', fr: 'Bangladesh', es: 'Bangladesh', de: 'Bangladesch', lt: 'Bangladešas', pl: 'Bangladesz',
  },
  {
    code: 'BB', en: 'Barbados', fr: 'Barbade', es: 'Barbados', de: 'Barbados', lt: 'Barbadosas', pl: 'Barbados',
  },
  {
    code: 'BY', en: 'Belarus', fr: 'Biélorussie', es: 'Belarús', de: 'Belarus', lt: 'Baltarusija', pl: 'Białoruś',
  },
  {
    code: 'BE', en: 'Belgium', fr: 'Belgique', es: 'Bélgica', de: 'Belgien', lt: 'Belgija', pl: 'Belgia',
  },
  {
    code: 'BZ', en: 'Belize', fr: 'Belize', es: 'Belice', de: 'Belize', lt: 'Belizas', pl: 'Belize',
  },
  {
    code: 'BJ', en: 'Benin', fr: 'Bénin', es: 'Benin', de: 'Benin', lt: 'Beninas', pl: 'Benin',
  },
  {
    code: 'BM', en: 'Bermuda', fr: 'Bermudes', es: 'Bermudas', de: 'Bermuda', lt: 'Bermuda', pl: 'Bermudy',
  },
  {
    code: 'BT', en: 'Bhutan', fr: 'Bhoutan', es: 'Bhután', de: 'Bhutan', lt: 'Butanas', pl: 'Bhutan',
  },
  {
    code: 'BO', en: 'Bolivia', fr: 'Bolivie', es: 'Bolivia', de: 'Bolivien', lt: 'Bolivija', pl: 'Boliwia',
  },
  {
    code: 'BQ', en: 'Bonaire, Sint Eustatius and Saba', fr: 'Pays-Bas caribéens', es: 'Caribe Neerlandés', de: 'Bonaire, Sint Eustatius und Saba', lt: 'Bonaire, Sint Eustatius ir Saba', pl: 'Bonaire, Sint Eustatius i Saba',
  },
  {
    code: 'BA', en: 'Bosnia and Herzegovina', fr: 'Bosnie-Herzégovine', es: 'Bosnia y Herzegovina', de: 'Bosnien und Herzegowina', lt: 'Bosnija ir Hercegovina', pl: 'Bośnia i Hercegowina',
  },
  {
    code: 'BW', en: 'Botswana', fr: 'Botswana', es: 'Botsuana', de: 'Botswana', lt: 'Botsvana', pl: 'Botswana',
  },
  {
    code: 'BV', en: 'Bouvet Island', fr: 'Île Bouvet', es: 'Isla Bouvet', de: 'Bouvetinsel', lt: 'Buvė Sala', pl: 'Wyspa Bouveta',
  },
  {
    code: 'BR', en: 'Brazil', fr: 'Brésil', es: 'Brasil', de: 'Brasilien', lt: 'Brazilija', pl: 'Brazylia',
  },
  {
    code: 'IO', en: 'British Indian Ocean Territory', fr: 'Territoire britannique de l’Océan Indien', es: 'Territorio Británico del Océano Índico', de: 'Britisches Territorium im Indischen Ozean', lt: 'Didžiosios Britanijos Indijos Vandenyno Teritorija', pl: 'Brytyjskie Terytorium Oceanu Indyjskiego',
  },
  {
    code: 'BN', en: 'Brunei Darussalam', fr: 'Brunei Darussalam', es: 'Brunéi', de: 'Brunei Darussalam', lt: 'Brunėjus', pl: 'Brunei',
  },
  {
    code: 'BG', en: 'Bulgaria', fr: 'Bulgarie', es: 'Bulgaria', de: 'Bulgarien', lt: 'Bulgarija', pl: 'Bułgaria',
  },
  {
    code: 'BF', en: 'Burkina Faso', fr: 'Burkina Faso', es: 'Burkina Faso', de: 'Burkina Faso', lt: 'Burkina Fasas', pl: 'Burkina Faso',
  },
  {
    code: 'BI', en: 'Burundi', fr: 'Burundi', es: 'Burundi', de: 'Burundi', lt: 'Burundis', pl: 'Burundi',
  },
  {
    code: 'KH', en: 'Cambodia', fr: 'Cambodge', es: 'Camboya', de: 'Kambodscha', lt: 'Kambodža', pl: 'Kambodża',
  },
  {
    code: 'CM', en: 'Cameroon', fr: 'Cameroun', es: 'Camerún', de: 'Kamerun', lt: 'Kamerūnas', pl: 'Kamerun',
  },
  {
    code: 'CA', en: 'Canada', fr: 'Canada', es: 'Canadá', de: 'Kanada', lt: 'Kanada', pl: 'Kanada',
  },
  {
    code: 'CV', en: 'Cape Verde', fr: 'Cap-Vert', es: 'Cabo Verde', de: 'Kap Verde', lt: 'Žaliasis Kyšulys', pl: 'Republika Zielonego Przylądka',
  },
  {
    code: 'KY', en: 'Cayman Islands', fr: 'Iles Cayman', es: 'Islas Caimán', de: 'Kaimaninseln', lt: 'Kaimanų salos', pl: 'Kajmany',
  },
  {
    code: 'CF', en: 'Central African Republic', fr: 'République centrafricaine', es: 'República Centro-Africana', de: 'Zentralafrikanische Republik', lt: 'Centrinės Afrikos Respublika', pl: 'Republika Środkowoafrykańska',
  },
  {
    code: 'TD', en: 'Chad', fr: 'Tchad', es: 'Chad', de: 'Tschad', lt: 'Čadas', pl: 'Czad',
  },
  {
    code: 'CL', en: 'Chile', fr: 'Chili', es: 'Chile', de: 'Chile', lt: 'Čilė', pl: 'Chile',
  },
  {
    code: 'CN', en: 'China', fr: 'Chine', es: 'China', de: 'China, Volksrepublik', lt: 'Kinija', pl: 'Chiny',
  },
  {
    code: 'CX', en: 'Christmas Island', fr: 'Île Christmas', es: 'Islas Christmas', de: 'Weihnachtsinsel', lt: 'Kalėdų sala', pl: 'Wyspa Bożego Narodzenia',
  },
  {
    code: 'CC', en: 'Cocos (Keeling) Islands', fr: 'Îles Cocos', es: 'Islas Cocos', de: 'Kokosinseln', lt: 'Kokosų salos', pl: 'Wyspy Kokosowe',
  },
  {
    code: 'CO', en: 'Colombia', fr: 'Colombie', es: 'Colombia', de: 'Kolumbien', lt: 'Kolumbija', pl: 'Kolumbia',
  },
  {
    code: 'KM', en: 'Comoros', fr: 'Comores', es: 'Comoros', de: 'Komoren', lt: 'Komorai', pl: 'Komory',
  },
  {
    code: 'CG', en: 'Congo', fr: 'République du Congo', es: 'Congo', de: 'Kongo, Republik', lt: 'Kongas', pl: 'Kongo',
  },
  {
    code: 'CD', en: 'Congo, the Democratic Republic of the', fr: 'République démocratique du Congo', es: 'República democrática del Congo', de: 'Kongo, Demokratische Republik', lt: 'Kongo Demokratinė Respublika', pl: 'Demokratyczna Republika Konga',
  },
  {
    code: 'CK', en: 'Cook Islands', fr: 'Îles Cook', es: 'Islas Cook', de: 'Cookinseln', lt: 'Kuko salos', pl: 'Wyspy Cooka',
  },
  {
    code: 'CR', en: 'Costa Rica', fr: 'Costa Rica', es: 'Costa Rica', de: 'Costa Rica', lt: 'Kosta Rika', pl: 'Kostaryka',
  },
  {
    code: 'CI', en: 'Côte d\'Ivoire', fr: 'Côte d’Ivoire', es: 'Costa de Marfil', de: 'Côte d’Ivoire', lt: 'Dramblio Kaulo Krantas', pl: 'Wybrzeże Kości Słoniowej',
  },
  {
    code: 'HR', en: 'Croatia', fr: 'Croatie', es: 'Croacia', de: 'Kroatien', lt: 'Kroatija', pl: 'Chorwacja',
  },
  {
    code: 'CU', en: 'Cuba', fr: 'Cuba', es: 'Cuba', de: 'Kuba', lt: 'Kuba', pl: 'Kuba',
  },
  {
    code: 'CW', en: 'Curaçao', fr: 'Curaçao', es: 'Curazao', de: 'Curaçao', lt: 'Kiurasao', pl: 'Curaçao',
  },
  {
    code: 'CY', en: 'Cyprus', fr: 'Chypre', es: 'Chipre', de: 'Zypern', lt: 'Kipras', pl: 'Cypr',
  },
  {
    code: 'CZ', en: 'Czech Republic', fr: 'République tchèque', es: 'República Checa', de: 'Tschechien', lt: 'Čekija', pl: 'Czechy',
  },
  {
    code: 'DK', en: 'Denmark', fr: 'Danemark', es: 'Dinamarca', de: 'Dänemark', lt: 'Danija', pl: 'Dania',
  },
  {
    code: 'DJ', en: 'Djibouti', fr: 'Djibouti', es: 'Yibuti', de: 'Dschibuti', lt: 'Džibutis', pl: 'Dżibuti',
  },
  {
    code: 'DM', en: 'Dominica', fr: 'Dominique', es: 'Domínica', de: 'Dominica', lt: 'Dominika', pl: 'Dominika',
  },
  {
    code: 'DO', en: 'Dominican Republic', fr: 'République dominicaine', es: 'República Dominicana', de: 'Dominikanische Republik', lt: 'Dominikos Respublika', pl: 'Dominikana',
  },
  {
    code: 'EC', en: 'Ecuador', fr: 'Équateur', es: 'Ecuador', de: 'Ecuador', lt: 'Ekvadoras', pl: 'Ekwador',
  },
  {
    code: 'EG', en: 'Egypt', fr: 'Égypte', es: 'Egipto', de: 'Ägypten', lt: 'Egiptas', pl: 'Egipt',
  },
  {
    code: 'SV', en: 'El Salvador', fr: 'Salvador', es: 'El Salvador', de: 'El Salvador', lt: 'Salvadoras', pl: 'Salwador',
  },
  {
    code: 'GQ', en: 'Equatorial Guinea', fr: 'Guinée équatoriale', es: 'Guinea Ecuatorial', de: 'Äquatorialguinea', lt: 'Pusiaujo Gvinėja', pl: 'Gwinea Równikowa',
  },
  {
    code: 'ER', en: 'Eritrea', fr: 'Érythrée', es: 'Eritrea', de: 'Eritrea', lt: 'Eritrėja', pl: 'Erytrea',
  },
  {
    code: 'EE', en: 'Estonia', fr: 'Estonie', es: 'Estonia', de: 'Estland', lt: 'Estija', pl: 'Estonia',
  },
  {
    code: 'ET', en: 'Ethiopia', fr: 'Éthiopie', es: 'Etiopía', de: 'Äthiopien', lt: 'Etiopija', pl: 'Etiopia',
  },
  {
    code: 'FK', en: 'Falkland Islands (Malvinas)', fr: 'Îles Falkland', es: 'Islas Malvinas', de: 'Falklandinseln', lt: 'Folklandų Salos', pl: 'Falklandy',
  },
  {
    code: 'FO', en: 'Faroe Islands', fr: 'Îles Féroé', es: 'Islas Faroe', de: 'Färöer', lt: 'Farerų salos', pl: 'Wyspy Owcze',
  },
  {
    code: 'FJ', en: 'Fiji', fr: 'Fidji', es: 'Fiji', de: 'Fidschi', lt: 'Fidžis', pl: 'Fidżi',
  },
  {
    code: 'FI', en: 'Finland', fr: 'Finlande', es: 'Finlandia', de: 'Finnland', lt: 'Suomija', pl: 'Finlandia',
  },
  {
    code: 'FR', en: 'France', fr: 'France', es: 'Francia', de: 'Frankreich', lt: 'Prancūzija', pl: 'Francja',
  },
  {
    code: 'GF', en: 'French Guiana', fr: 'Guyane française', es: 'Guayana Francesa', de: 'Französisch-Guayana', lt: 'Prancūzijos Gviana', pl: 'Gujana Francuska',
  },
  {
    code: 'PF', en: 'French Polynesia', fr: 'Polynésie française', es: 'Polinesia Francesa', de: 'Französisch-Polynesien', lt: 'Prancūzijos Polinezija', pl: 'Polinezja Francuska',
  },
  {
    code: 'TF', en: 'French Southern Territories', fr: 'Terres australes et antarctiques françaises', es: 'Territorios Australes Franceses', de: 'Französische Süd- und Antarktisgebiete', lt: 'Prancūzijos Pietų Ir Antarktikos Žemės', pl: 'Francuskie Terytoria Południowe i Antarktyczne',
  },
  {
    code: 'GA', en: 'Gabon', fr: 'Gabon', es: 'Gabón', de: 'Gabun', lt: 'Gabonas', pl: 'Gabon',
  },
  {
    code: 'GM', en: 'Gambia', fr: 'Gambie', es: 'Gambia', de: 'Gambia', lt: 'Gambija', pl: 'Gambia',
  },
  {
    code: 'GE', en: 'Georgia', fr: 'Géorgie', es: 'Georgia', de: 'Georgien', lt: 'Gruzija', pl: 'Gruzja',
  },
  {
    code: 'DE', en: 'Germany', fr: 'Allemagne', es: 'Alemania', de: 'Deutschland', lt: 'Vokietija', pl: 'Niemcy',
  },
  {
    code: 'GH', en: 'Ghana', fr: 'Ghana', es: 'Ghana', de: 'Ghana', lt: 'Gana', pl: 'Ghana',
  },
  {
    code: 'GI', en: 'Gibraltar', fr: 'Gibraltar', es: 'Gibraltar', de: 'Gibraltar', lt: 'Gibraltaras', pl: 'Gibraltar',
  },
  {
    code: 'GR', en: 'Greece', fr: 'Grèce', es: 'Grecia', de: 'Griechenland', lt: 'Graikija', pl: 'Grecja',
  },
  {
    code: 'GL', en: 'Greenland', fr: 'Groenland', es: 'Groenlandia', de: 'Grönland', lt: 'Grenlandija', pl: 'Grenlandia',
  },
  {
    code: 'GD', en: 'Grenada', fr: 'Grenade', es: 'Granada', de: 'Grenada', lt: 'Grenada', pl: 'Grenada',
  },
  {
    code: 'GP', en: 'Guadeloupe', fr: 'Guadeloupe', es: 'Guadalupe', de: 'Guadeloupe', lt: 'Gvadelupa', pl: 'Gwadelupa',
  },
  {
    code: 'GU', en: 'Guam', fr: 'Guam', es: 'Guam', de: 'Guam', lt: 'Guamas', pl: 'Guam',
  },
  {
    code: 'GT', en: 'Guatemala', fr: 'Guatemala', es: 'Guatemala', de: 'Guatemala', lt: 'Gvatemala', pl: 'Gwatemala',
  },
  {
    code: 'GG', en: 'Guernsey', fr: 'Guernesey', es: 'Guernsey', de: 'Guernsey', lt: 'Gernsis', pl: 'Guernsey',
  },
  {
    code: 'GN', en: 'Guinea', fr: 'Guinée', es: 'Guinea', de: 'Guinea', lt: 'Gvinėja', pl: 'Gwinea',
  },
  {
    code: 'GW', en: 'Guinea-Bissau', fr: 'Guinée-Bissau', es: 'Guinea-Bissau', de: 'Guinea-Bissau', lt: 'Bisau Gvinėja', pl: 'Gwinea Bissau',
  },
  {
    code: 'GY', en: 'Guyana', fr: 'Guyane', es: 'Guayana', de: 'Guyana', lt: 'Gajana', pl: 'Gujana',
  },
  {
    code: 'HT', en: 'Haiti', fr: 'Haïti', es: 'Haití', de: 'Haiti', lt: 'Haitis', pl: 'Haiti',
  },
  {
    code: 'HM', en: 'Heard Island and McDonald Islands', fr: 'Îles Heard-et-MacDonald', es: 'Islas Heard y McDonald', de: 'Heard und McDonaldinseln', lt: 'Herdo ir Makdonaldo salos', pl: 'Wyspy Heard i McDonalda',
  },
  {
    code: 'VA', en: 'Holy See (Vatican City State)', fr: 'Saint-Siège (Vatican)', es: 'Ciudad del Vaticano', de: 'Vatikanstadt', lt: 'Šventasis Sostas (Vatikano Miesto Valstybė)', pl: 'Stolica Apostolska (Państwo Watykańskie)',
  },
  {
    code: 'HN', en: 'Honduras', fr: 'Honduras', es: 'Honduras', de: 'Honduras', lt: 'Hondūras', pl: 'Honduras',
  },
  {
    code: 'HK', en: 'Hong Kong', fr: 'Hong Kong', es: 'Hong Kong', de: 'Hongkong', lt: 'Honkongas', pl: 'Hongkong',
  },
  {
    code: 'HU', en: 'Hungary', fr: 'Hongrie', es: 'Hungría', de: 'Ungarn', lt: 'Vengrija', pl: 'Węgry',
  },
  {
    code: 'IS', en: 'Iceland', fr: 'Islande', es: 'Islandia', de: 'Island', lt: 'Islandija', pl: 'Islandia',
  },
  {
    code: 'IN', en: 'India', fr: 'Inde', es: 'India', de: 'Indien', lt: 'Indija', pl: 'Indie',
  },
  {
    code: 'ID', en: 'Indonesia', fr: 'Indonésie', es: 'Indonesia', de: 'Indonesien', lt: 'Indonezija', pl: 'Indonezja',
  },
  {
    code: 'IR', en: 'Iran, Islamic Republic of', fr: 'Iran', es: 'Irán', de: 'Iran, Islamische Republik', lt: 'Iranas', pl: 'Iran',
  },
  {
    code: 'IQ', en: 'Iraq', fr: 'Irak', es: 'Irak', de: 'Irak', lt: 'Irakas', pl: 'Irak',
  },
  {
    code: 'IE', en: 'Ireland', fr: 'Irlande', es: 'Irlanda', de: 'Irland', lt: 'Airija', pl: 'Irlandia',
  },
  {
    code: 'IM', en: 'Isle of Man', fr: 'Ile de Man', es: 'Isla de Man', de: 'Insel Man', lt: 'Meno Sala', pl: 'Wyspa Man',
  },
  {
    code: 'IL', en: 'Israel', fr: 'Israël', es: 'Israel', de: 'Israel', lt: 'Izraelis', pl: 'Izrael',
  },
  {
    code: 'IT', en: 'Italy', fr: 'Italie', es: 'Italia', de: 'Italien', lt: 'Italija', pl: 'Włochy',
  },
  {
    code: 'JM', en: 'Jamaica', fr: 'Jamaïque', es: 'Jamaica', de: 'Jamaika', lt: 'Jamaika', pl: 'Jamajka',
  },
  {
    code: 'JP', en: 'Japan', fr: 'Japon', es: 'Japón', de: 'Japan', lt: 'Japonija', pl: 'Japonia',
  },
  {
    code: 'JE', en: 'Jersey', fr: 'Jersey', es: 'Jersey', de: 'Jersey (Kanalinsel)', lt: 'Džersis', pl: 'Jersey',
  },
  {
    code: 'JO', en: 'Jordan', fr: 'Jordanie', es: 'Jordania', de: 'Jordanien', lt: 'Jordanija', pl: 'Jordania',
  },
  {
    code: 'KZ', en: 'Kazakhstan', fr: 'Kazakhstan', es: 'Kazajstán', de: 'Kasachstan', lt: 'Kazachstanas', pl: 'Kazachstan',
  },
  {
    code: 'KE', en: 'Kenya', fr: 'Kenya', es: 'Kenia', de: 'Kenia', lt: 'Kenija', pl: 'Kenia',
  },
  {
    code: 'KI', en: 'Kiribati', fr: 'Kiribati', es: 'Kiribati', de: 'Kiribati', lt: 'Kiribatis', pl: 'Kiribati',
  },
  {
    code: 'KP', en: 'Korea, Democratic People\'s Republic of', fr: 'Corée du Nord', es: 'Corea del Norte', de: 'Korea, Demokratische Volksrepublik (Nordkorea)', lt: 'Šiaurės Korėja', pl: 'Koreańska Republika Ludowo-Demokratyczna (Korea Północna)',
  },
  {
    code: 'KR', en: 'Korea, Republic of', fr: 'Corée du Sud', es: 'Corea del Sur', de: 'Korea, Republik (Südkorea)', lt: 'Pietų Korėja', pl: 'Republika Korei (Korea Południowa)',
  },
  {
    code: 'KW', en: 'Kuwait', fr: 'Koweït', es: 'Kuwait', de: 'Kuwait', lt: 'Kuveitas', pl: 'Kuwejt',
  },
  {
    code: 'KG', en: 'Kyrgyzstan', fr: 'Kirghizistan', es: 'Kirguistán', de: 'Kirgisistan', lt: 'Kirgizija', pl: 'Kirgistan',
  },
  {
    code: 'LA', en: 'Laos', fr: 'Laos', es: 'Laos', de: 'Laos', lt: 'Laosas', pl: 'Laos',
  },
  {
    code: 'LV', en: 'Latvia', fr: 'Lettonie', es: 'Letonia', de: 'Lettland', lt: 'Latvija', pl: 'Łotwa',
  },
  {
    code: 'LB', en: 'Lebanon', fr: 'Liban', es: 'Líbano', de: 'Libanon', lt: 'Libanas', pl: 'Liban',
  },
  {
    code: 'LS', en: 'Lesotho', fr: 'Lesotho', es: 'Lesotho', de: 'Lesotho', lt: 'Lesotas', pl: 'Lesotho',
  },
  {
    code: 'LR', en: 'Liberia', fr: 'Libéria', es: 'Liberia', de: 'Liberia', lt: 'Liberija', pl: 'Liberia',
  },
  {
    code: 'LY', en: 'Libya', fr: 'Libye', es: 'Libia', de: 'Libyen', lt: 'Libija', pl: 'Libia',
  },
  {
    code: 'LI', en: 'Liechtenstein', fr: 'Liechtenstein', es: 'Liechtenstein', de: 'Liechtenstein', lt: 'Lichtenšteinas', pl: 'Liechtenstein',
  },
  {
    code: 'LT', en: 'Lithuania', fr: 'Lituanie', es: 'Lituania', de: 'Litauen', lt: 'Lietuva', pl: 'Litwa',
  },
  {
    code: 'LU', en: 'Luxembourg', fr: 'Luxembourg', es: 'Luxemburgo', de: 'Luxemburg', lt: 'Liuksemburgas', pl: 'Luksemburg',
  },
  {
    code: 'MO', en: 'Macao', fr: 'Macao', es: 'Macao', de: 'Macau', lt: 'Makao', pl: 'Makau',
  },
  {
    code: 'MK', en: 'North Macedonia', fr: 'Macédoine du Nord', es: 'Macedonia del Norte', de: 'Nordmazedonien', lt: 'Šiaurės Makedonija', pl: 'Macedonia Północna',
  },
  {
    code: 'MG', en: 'Madagascar', fr: 'Madagascar', es: 'Madagascar', de: 'Madagaskar', lt: 'Madagaskaras', pl: 'Madagaskar',
  },
  {
    code: 'MW', en: 'Malawi', fr: 'Malawi', es: 'Malawi', de: 'Malawi', lt: 'Malavis', pl: 'Malawi',
  },
  {
    code: 'MY', en: 'Malaysia', fr: 'Malaisie', es: 'Malasia', de: 'Malaysia', lt: 'Malaizija', pl: 'Malezja',
  },
  {
    code: 'MV', en: 'Maldives', fr: 'Maldives', es: 'Maldivas', de: 'Malediven', lt: 'Maldyvai', pl: 'Malediwy',
  },
  {
    code: 'ML', en: 'Mali', fr: 'Mali', es: 'Mali', de: 'Mali', lt: 'Malis', pl: 'Mali',
  },
  {
    code: 'MT', en: 'Malta', fr: 'Malte', es: 'Malta', de: 'Malta', lt: 'Malta', pl: 'Malta',
  },
  {
    code: 'MH', en: 'Marshall Islands', fr: 'Îles Marshall', es: 'Islas Marshall', de: 'Marshallinseln', lt: 'Maršalo Salos', pl: 'Wyspy Marshalla',
  },
  {
    code: 'MQ', en: 'Martinique', fr: 'Martinique', es: 'Martinica', de: 'Martinique', lt: 'Martinika', pl: 'Martynika',
  },
  {
    code: 'MR', en: 'Mauritania', fr: 'Mauritanie', es: 'Mauritania', de: 'Mauretanien', lt: 'Mauritanija', pl: 'Mauretania',
  },
  {
    code: 'MU', en: 'Mauritius', fr: 'Maurice', es: 'Mauricio', de: 'Mauritius', lt: 'Mauricijus', pl: 'Mauritius',
  },
  {
    code: 'YT', en: 'Mayotte', fr: 'Mayotte', es: 'Mayotte', de: 'Mayotte', lt: 'Majotas', pl: 'Majotta',
  },
  {
    code: 'MX', en: 'Mexico', fr: 'Mexique', es: 'México', de: 'Mexiko', lt: 'Meksika', pl: 'Meksyk',
  },
  {
    code: 'FM', en: 'Micronesia, Federated States of', fr: 'Micronésie', es: 'Micronesia', de: 'Mikronesien', lt: 'Mikronezija', pl: 'Mikronezja',
  },
  {
    code: 'MD', en: 'Moldova', fr: 'Moldavie', es: 'Moldova', de: 'Moldawien', lt: 'Moldova', pl: 'Mołdawia',
  },
  {
    code: 'MC', en: 'Monaco', fr: 'Monaco', es: 'Mónaco', de: 'Monaco', lt: 'Monakas', pl: 'Monako',
  },
  {
    code: 'MN', en: 'Mongolia', fr: 'Mongolie', es: 'Mongolia', de: 'Mongolei', lt: 'Mongolija', pl: 'Mongolia',
  },
  {
    code: 'ME', en: 'Montenegro', fr: 'Monténégro', es: 'Montenegro', de: 'Montenegro', lt: 'Juodkalnija', pl: 'Czarnogóra',
  },
  {
    code: 'MS', en: 'Montserrat', fr: 'Montserrat', es: 'Montserrat', de: 'Montserrat', lt: 'Montseratas', pl: 'Montserrat',
  },
  {
    code: 'MA', en: 'Morocco', fr: 'Maroc', es: 'Marruecos', de: 'Marokko', lt: 'Marokas', pl: 'Maroko',
  },
  {
    code: 'MZ', en: 'Mozambique', fr: 'Mozambique', es: 'Mozambique', de: 'Mosambik', lt: 'Mozambikas', pl: 'Mozambik',
  },
  {
    code: 'MM', en: 'Myanmar', fr: 'Myanmar', es: 'Myanmar', de: 'Myanmar', lt: 'Mianmaras', pl: 'Myanmar',
  },
  {
    code: 'NA', en: 'Namibia', fr: 'Namibie', es: 'Namibia', de: 'Namibia', lt: 'Namibija', pl: 'Namibia',
  },
  {
    code: 'NR', en: 'Nauru', fr: 'Nauru', es: 'Nauru', de: 'Nauru', lt: 'Nauru', pl: 'Nauru',
  },
  {
    code: 'NP', en: 'Nepal', fr: 'Népal', es: 'Nepal', de: 'Nepal', lt: 'Nepalas', pl: 'Nepal',
  },
  {
    code: 'NL', en: 'Netherlands', fr: 'Pays-Bas', es: 'Países Bajos', de: 'Niederlande', lt: 'Nyderlandai', pl: 'Holandia',
  },
  {
    code: 'NC', en: 'New Caledonia', fr: 'Nouvelle-Calédonie', es: 'Nueva Caledonia', de: 'Neukaledonien', lt: 'Naujoji Kaledonija', pl: 'Nowa Kaledonia',
  },
  {
    code: 'NZ', en: 'New Zealand', fr: 'Nouvelle-Zélande', es: 'Nueva Zelanda', de: 'Neuseeland', lt: 'Naujoji Zelandija', pl: 'Nowa Zelandia',
  },
  {
    code: 'NI', en: 'Nicaragua', fr: 'Nicaragua', es: 'Nicaragua', de: 'Nicaragua', lt: 'Nikaragva', pl: 'Nikaragua',
  },
  {
    code: 'NE', en: 'Niger', fr: 'Niger', es: 'Níger', de: 'Niger', lt: 'Nigeris', pl: 'Niger',
  },
  {
    code: 'NG', en: 'Nigeria', fr: 'Nigeria', es: 'Nigeria', de: 'Nigeria', lt: 'Nigerija', pl: 'Nigeria',
  },
  {
    code: 'NU', en: 'Niue', fr: 'Niue', es: 'Niue', de: 'Niue', lt: 'Niujė', pl: 'Niue',
  },
  {
    code: 'NF', en: 'Norfolk Island', fr: 'Île Norfolk', es: 'Islas Norkfolk', de: 'Norfolkinsel', lt: 'Norfolko Sala', pl: 'Wyspa Norfolk',
  },
  {
    code: 'MP', en: 'Northern Mariana Islands', fr: 'Îles Mariannes du Nord', es: 'Islas Marianas del Norte', de: 'Nördliche Marianen', lt: 'Šiaurinės Marianos Salos', pl: 'Mariany Północne',
  },
  {
    code: 'NO', en: 'Norway', fr: 'Norvège', es: 'Noruega', de: 'Norwegen', lt: 'Norvegija', pl: 'Norwegia',
  },
  {
    code: 'OM', en: 'Oman', fr: 'Oman', es: 'Omán', de: 'Oman', lt: 'Omanas', pl: 'Oman',
  },
  {
    code: 'PK', en: 'Pakistan', fr: 'Pakistan', es: 'Pakistán', de: 'Pakistan', lt: 'Pakistanas', pl: 'Pakistan',
  },
  {
    code: 'PW', en: 'Palau', fr: 'Palau', es: 'Islas Palaos', de: 'Palau', lt: 'Palau', pl: 'Palau',
  },
  {
    code: 'PS', en: 'Palestine, State of', fr: 'Palestine', es: 'Palestina', de: 'Staat Palästina', lt: 'Palestina', pl: 'Palestyna',
  },
  {
    code: 'PA', en: 'Panama', fr: 'Panama', es: 'Panamá', de: 'Panama', lt: 'Panama', pl: 'Panama',
  },
  {
    code: 'PG', en: 'Papua New Guinea', fr: 'Papouasie-Nouvelle-Guinée', es: 'Papúa Nueva Guinea', de: 'Papua-Neuguinea', lt: 'Papua Naujoji Gvinėja', pl: 'Papua-Nowa Gwinea',
  },
  {
    code: 'PY', en: 'Paraguay', fr: 'Paraguay', es: 'Paraguay', de: 'Paraguay', lt: 'Paragvajus', pl: 'Paragwaj',
  },
  {
    code: 'PE', en: 'Peru', fr: 'Pérou', es: 'Perú', de: 'Peru', lt: 'Peru', pl: 'Peru',
  },
  {
    code: 'PH', en: 'Philippines', fr: 'Philippines', es: 'Filipinas', de: 'Philippinen', lt: 'Filipinai', pl: 'Filipiny',
  },
  {
    code: 'PN', en: 'Pitcairn', fr: 'Pitcairn', es: 'Islas Pitcairn', de: 'Pitcairninseln', lt: 'Pitkernas', pl: 'Pitcairn',
  },
  {
    code: 'PL', en: 'Poland', fr: 'Pologne', es: 'Polonia', de: 'Polen', lt: 'Lenkija', pl: 'Polska',
  },
  {
    code: 'PT', en: 'Portugal', fr: 'Portugal', es: 'Portugal', de: 'Portugal', lt: 'Portugalija', pl: 'Portugalia',
  },
  {
    code: 'PR', en: 'Puerto Rico', fr: 'Puerto Rico', es: 'Puerto Rico', de: 'Puerto Rico', lt: 'Puerto Rikas', pl: 'Portoryko',
  },
  {
    code: 'QA', en: 'Qatar', fr: 'Qatar', es: 'Qatar', de: 'Katar', lt: 'Kataras', pl: 'Katar',
  },
  {
    code: 'RE', en: 'Réunion', fr: 'Réunion', es: 'Reunión', de: 'Réunion', lt: 'Reunionas', pl: 'Reunion',
  },
  {
    code: 'RO', en: 'Romania', fr: 'Roumanie', es: 'Rumanía', de: 'Rumänien', lt: 'Rumunija', pl: 'Rumunia',
  },
  {
    code: 'RU', en: 'Russian Federation', fr: 'Russie', es: 'Rusia', de: 'Russische Föderation', lt: 'Rusijos Federacija', pl: 'Federacja Rosyjska',
  },
  {
    code: 'RW', en: 'Rwanda', fr: 'Rwanda', es: 'Ruanda', de: 'Ruanda', lt: 'Ruanda', pl: 'Rwanda',
  },
  {
    code: 'BL', en: 'Saint Barthélemy', fr: 'Saint-Barthélemy', es: 'San Bartolomé', de: 'Saint-Barthélemy', lt: 'Saint Barthélemy', pl: 'Saint-Barthélemy',
  },
  {
    code: 'SH', en: 'Saint Helena, Ascension and Tristan da Cunha', fr: 'Sainte-Hélène', es: 'Santa Elena', de: 'St. Helena', lt: 'Šv. Elenos sala', pl: 'Święta Helena',
  },
  {
    code: 'KN', en: 'Saint Kitts and Nevis', fr: 'Saint-Kitts-et-Nevis', es: 'San Cristóbal y Nieves', de: 'St. Kitts und Nevis', lt: 'Sent Kitsas ir Nevis', pl: 'Saint Kitts i Nevis',
  },
  {
    code: 'LC', en: 'Saint Lucia', fr: 'Sainte-Lucie', es: 'Santa Lucía', de: 'St. Lucia', lt: 'Sent Lusija', pl: 'Saint Lucia',
  },
  {
    code: 'MF', en: 'Saint Martin (French part)', fr: 'Saint-Martin (partie française)', es: 'San Martín (parte francesa)', de: 'Saint-Martin (franz. Teil)', lt: 'Sent Martinas (Prancūzijos dalis)', pl: 'Saint-Martin (część francuska)',
  },
  {
    code: 'PM', en: 'Saint Pierre and Miquelon', fr: 'Saint-Pierre-et-Miquelon', es: 'San Pedro y Miquelón', de: 'Saint-Pierre und Miquelon', lt: 'Sent Pjeras ir Mikelonas', pl: 'Saint-Pierre i Miquelon',
  },
  {
    code: 'VC', en: 'Saint Vincent and the Grenadines', fr: 'Saint-Vincent-et-les Grenadines', es: 'San Vicente y las Granadinas', de: 'St. Vincent und die Grenadinen', lt: 'Sent Vinsentas ir Grenadinai', pl: 'Saint Vincent i Grenadyny',
  },
  {
    code: 'WS', en: 'Samoa', fr: 'Samoa', es: 'Samoa', de: 'Samoa', lt: 'Samoa', pl: 'Samoa',
  },
  {
    code: 'SM', en: 'San Marino', fr: 'Saint-Marin', es: 'San Marino', de: 'San Marino', lt: 'San Marinas', pl: 'San Marino',
  },
  {
    code: 'ST', en: 'Sao Tome and Principe', fr: 'Sao Tomé-et-Principe', es: 'Santo Tomé y Príncipe', de: 'São Tomé und Príncipe', lt: 'San Tomė ir Prinsipė', pl: 'Wyspy Świętego Tomasza i Książęca',
  },
  {
    code: 'SA', en: 'Saudi Arabia', fr: 'Arabie Saoudite', es: 'Arabia Saudita', de: 'Saudi-Arabien', lt: 'Saudo Arabija', pl: 'Arabia Saudyjska',
  },
  {
    code: 'SN', en: 'Senegal', fr: 'Sénégal', es: 'Senegal', de: 'Senegal', lt: 'Senegalas', pl: 'Senegal',
  },
  {
    code: 'RS', en: 'Serbia', fr: 'Serbie', es: 'Serbia y Montenegro', de: 'Serbien', lt: 'Serbija', pl: 'Serbia',
  },
  {
    code: 'SC', en: 'Seychelles', fr: 'Seychelles', es: 'Seychelles', de: 'Seychellen', lt: 'Seišeliai', pl: 'Seszele',
  },
  {
    code: 'SL', en: 'Sierra Leone', fr: 'Sierra Leone', es: 'Sierra Leona', de: 'Sierra Leone', lt: 'Siera Leonė', pl: 'Sierra Leone',
  },
  {
    code: 'SG', en: 'Singapore', fr: 'Singapour', es: 'Singapur', de: 'Singapur', lt: 'Singapūras', pl: 'Singapur',
  },
  {
    code: 'SX', en: 'Sint Maarten (Dutch part)', fr: 'Saint-Martin (partie néerlandaise)', es: 'San Martín (parte neerlandesa)', de: 'Sint Maarten (niederl. Teil)', lt: 'Sint Martenas (Olandijos dalis)', pl: 'Sint Maarten (część holenderska)',
  },
  {
    code: 'SK', en: 'Slovakia', fr: 'Slovaquie', es: 'Eslovaquia', de: 'Slowakei', lt: 'Slovakija', pl: 'Słowacja',
  },
  {
    code: 'SI', en: 'Slovenia', fr: 'Slovénie', es: 'Eslovenia', de: 'Slowenien', lt: 'Slovėnija', pl: 'Słowenia',
  },
  {
    code: 'SB', en: 'Solomon Islands', fr: 'Îles Salomon', es: 'Islas Solomón', de: 'Salomonen', lt: 'Saliamono Salos', pl: 'Wyspy Salomona',
  },
  {
    code: 'SO', en: 'Somalia', fr: 'Somalie', es: 'Somalia', de: 'Somalia', lt: 'Somalis', pl: 'Somalia',
  },
  {
    code: 'ZA', en: 'South Africa', fr: 'Afrique du Sud', es: 'Sudáfrica', de: 'Südafrika', lt: 'Pietų Afrika', pl: 'Republika Południowej Afryki',
  },
  {
    code: 'GS', en: 'South Georgia and the South Sandwich Islands', fr: 'Géorgie du Sud et les îles Sandwich du Sud', es: 'Georgia del Sur e Islas Sandwich deSur', de: 'Südgeorgien und die Südlichen Sandwichinseln', lt: 'Pietų Džordžija ir Pietų Sandvičo Salos', pl: 'Georgia Południowa i Sandwich Południowy',
  },
  {
    code: 'SS', en: 'South Sudan', fr: 'Sud-Soudan', es: 'Sudán del Sur', de: 'Südsudan', lt: 'Pietų Sudanas', pl: 'Sudan Południowy',
  },
  {
    code: 'ES', en: 'Spain', fr: 'Espagne', es: 'España', de: 'Spanien', lt: 'Ispanija', pl: 'Hiszpania',
  },
  {
    code: 'LK', en: 'Sri Lanka', fr: 'Sri Lanka', es: 'Sri Lanka', de: 'Sri Lanka', lt: 'Šri Lanka', pl: 'Sri Lanka',
  },
  {
    code: 'SD', en: 'Sudan', fr: 'Soudan', es: 'Sudán', de: 'Sudan', lt: 'Sudanas', pl: 'Sudan',
  },
  {
    code: 'SR', en: 'Suriname', fr: 'Suriname', es: 'Surinam', de: 'Suriname', lt: 'Surinamas', pl: 'Surinam',
  },
  {
    code: 'SJ', en: 'Svalbard and Jan Mayen', fr: 'Svalbard et Jan Mayen', es: 'Islas Svalbard y Jan Mayen', de: 'Svalbard und Jan Mayen', lt: 'Svalbardas ir Jan Majenas', pl: 'Svalbard i Jan Mayen',
  },
  {
    code: 'SZ', en: 'Swaziland', fr: 'Eswatini', es: 'Suazilandia', de: 'Swasiland', lt: 'Svazilandas', pl: 'Eswatini',
  },
  {
    code: 'SE', en: 'Sweden', fr: 'Suède', es: 'Suecia', de: 'Schweden', lt: 'Švedija', pl: 'Szwecja',
  },
  {
    code: 'CH', en: 'Switzerland', fr: 'Suisse', es: 'Suiza', de: 'Schweiz', lt: 'Šveicarija', pl: 'Szwajcaria',
  },
  {
    code: 'SY', en: 'Syrian Arab Republic', fr: 'Syrie', es: 'Siria', de: 'Syrien, Arabische Republik', lt: 'Sirijos Arabų Respublika', pl: 'Syryjska Republika Arabska',
  },
  {
    code: 'TW', en: 'Taiwan', fr: 'Taiwan', es: 'Taiwán', de: 'Taiwan', lt: 'Taivanas', pl: 'Tajwan',
  },
  {
    code: 'TJ', en: 'Tajikistan', fr: 'Tadjikistan', es: 'Tayikistán', de: 'Tadschikistan', lt: 'Tadžikija', pl: 'Tadżykistan',
  },
  {
    code: 'TZ', en: 'Tanzania', fr: 'Tanzanie', es: 'Tanzania', de: 'Tansania', lt: 'Tanzanija', pl: 'Tanzania',
  },
  {
    code: 'TH', en: 'Thailand', fr: 'Thaïlande', es: 'Tailandia', de: 'Thailand', lt: 'Tailandas', pl: 'Tajlandia',
  },
  {
    code: 'TL', en: 'Timor-Leste', fr: 'Timor-Leste', es: 'Timor-Leste', de: 'Osttimor ', lt: 'Timoras-Leste', pl: 'Timor Wschodni',
  },
  {
    code: 'TG', en: 'Togo', fr: 'Togo', es: 'Togo', de: 'Togo', lt: 'Togas', pl: 'Togo',
  },
  {
    code: 'TK', en: 'Tokelau', fr: 'Tokelau', es: 'Tokelau', de: 'Tokelau', lt: 'Tokelau', pl: 'Tokelau',
  },
  {
    code: 'TO', en: 'Tonga', fr: 'Tonga', es: 'Tonga', de: 'Tonga', lt: 'Tonga', pl: 'Tonga',
  },
  {
    code: 'TT', en: 'Trinidad and Tobago', fr: 'Trinité-et-Tobago', es: 'Trinidad y Tobago', de: 'Trinidad und Tobago', lt: 'Trinidadas ir Tobagas', pl: 'Trynidad i Tobago',
  },
  {
    code: 'TN', en: 'Tunisia', fr: 'Tunisie', es: 'Túnez', de: 'Tunesien', lt: 'Tunisas', pl: 'Tunezja',
  },
  {
    code: 'TR', en: 'Turkey', fr: 'Turquie', es: 'Turquía', de: 'Türkei', lt: 'Turkija', pl: 'Turcja',
  },
  {
    code: 'TM', en: 'Turkmenistan', fr: 'Turkménistan', es: 'Turkmenistán', de: 'Turkmenistan', lt: 'Turkmėnistanas', pl: 'Turkmenistan',
  },
  {
    code: 'TC', en: 'Turks and Caicos Islands', fr: 'Îles Turques-et-Caïques', es: 'Islas Turcas y Caicos', de: 'Turks- und Caicosinseln', lt: 'Terkso ir Kaikoso Salos', pl: 'Turks i Caicos',
  },
  {
    code: 'TV', en: 'Tuvalu', fr: 'Tuvalu', es: 'Tuvalu', de: 'Tuvalu', lt: 'Tuvalu', pl: 'Tuvalu',
  },
  {
    code: 'UG', en: 'Uganda', fr: 'Ouganda', es: 'Uganda', de: 'Uganda', lt: 'Uganda', pl: 'Uganda',
  },
  {
    code: 'UA', en: 'Ukraine', fr: 'Ukraine', es: 'Ucrania', de: 'Ukraine', lt: 'Ukraina', pl: 'Ukraina',
  },
  {
    code: 'AE', en: 'United Arab Emirates', fr: 'Émirats Arabes Unis', es: 'Emiratos Árabes Unidos', de: 'Vereinigte Arabische Emirate', lt: 'Jungtiniai Arabų Emyratai', pl: 'Zjednoczone Emiraty Arabskie',
  },
  {
    code: 'GB', en: 'United Kingdom', fr: 'Royaume-Uni', es: 'Reino Unido', de: 'Vereinigtes Königreich Großbritannien und Nordirland', lt: 'Jungtinė Karalystė', pl: 'Zjednoczone Królestwo',
  },
  {
    code: 'US', en: 'United States', fr: 'États-Unis', es: 'Estados Unidos de América', de: 'Vereinigte Staaten von Amerika', lt: 'Jungtinės Amerikos Valstijos', pl: 'Stany Zjednoczone',
  },
  {
    code: 'UM', en: 'United States Minor Outlying Islands', fr: 'Îles mineures éloignées des États-Unis', es: 'Islas Ultramarinas Menores de Estados Unidos', de: 'United States Minor Outlying Islands', lt: 'Jungtinių Valstijų Mažosios Tolimosios Salos', pl: 'Dalekie Wyspy Mniejsze Stanów Zjednoczonych',
  },
  {
    code: 'UY', en: 'Uruguay', fr: 'Uruguay', es: 'Uruguay', de: 'Uruguay', lt: 'Urugvajus', pl: 'Urugwaj',
  },
  {
    code: 'UZ', en: 'Uzbekistan', fr: 'Ouzbékistan', es: 'Uzbekistán', de: 'Usbekistan', lt: 'Uzbekistanas', pl: 'Uzbekistan',
  },
  {
    code: 'VU', en: 'Vanuatu', fr: 'Vanuatu', es: 'Vanuatu', de: 'Vanuatu', lt: 'Vanuatu', pl: 'Vanuatu',
  },
  {
    code: 'VE', en: 'Venezuela', fr: 'Venezuela', es: 'Venezuela', de: 'Venezuela', lt: 'Venesuela', pl: 'Wenezuela',
  },
  {
    code: 'VN', en: 'Viet Nam', fr: 'Viêt Nam', es: 'Vietnam', de: 'Vietnam', lt: 'Vietnamas', pl: 'Wietnam',
  },
  {
    code: 'VG', en: 'Virgin Islands, British', fr: 'British Virgin Islands', es: 'Islas Vírgenes Británicas', de: 'Britische Jungferninseln', lt: 'Didžiosios Britanijos Mergelių Salos', pl: 'Brytyjskie Wyspy Dziewicze',
  },
  {
    code: 'VI', en: 'Virgin Islands, U.S.', fr: 'Îles Vierges américaines', es: 'Islas Vírgenes de los Estados Unidos de América', de: 'Amerikanische Jungferninseln', lt: 'Jungtinių Valstijų Mergelių Salos', pl: 'Wyspy Dziewicze Stanów Zjednoczonych',
  },
  {
    code: 'WF', en: 'Wallis and Futuna', fr: 'Wallis-et-Futuna', es: 'Wallis y Futuna', de: 'Wallis und Futuna', lt: 'Wallis ir Futuna', pl: 'Wallis i Futuna',
  },
  {
    code: 'EH', en: 'Western Sahara', fr: 'Sahara occidental', es: 'Sahara Occidental', de: 'Westsahara', lt: 'Vakarų Sachara', pl: 'Sahara Zachodnia',
  },
  {
    code: 'YE', en: 'Yemen', fr: 'Yémen', es: 'Yemen', de: 'Jemen', lt: 'Jemenas', pl: 'Jemen',
  },
  {
    code: 'ZM', en: 'Zambia', fr: 'Zambie', es: 'Zambia', de: 'Sambia', lt: 'Zambija', pl: 'Zambia',
  },
  {
    code: 'ZW', en: 'Zimbabwe', fr: 'Zimbabwe', es: 'Zimbabue', de: 'Simbabwe', lt: 'Zimbabvė', pl: 'Zimbabwe',
  },
];

const getCountryCodes = lang => {
  // Add the lnew locale here so that the correct translations will be returned.
  // If locale is unknown or the translation is missing, this will default to english coutnry name.
  const codes = countryCodes.map(c => {
    const countryName = c[lang] ? c[lang] : c.en;
    const counryCode = c.code;

    return { code: counryCode, name: countryName };
  });
  return codes;
};

export default getCountryCodes;
