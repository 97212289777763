import { Box, Modal, Stack, Typography } from '@mui/material';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { InlineTextButton } from '..';
import config from '../../config';
import { useRootContext } from '../../contexts/RootContext';
import DeliveryInformationForm from './AddressInformationForm';
import CloseIcon from './close.svg';

const AddressInformationModal = ({ intl, title, open, address, onClose, onSubmit, submitting, apiFormError }) => {
  const root = useRootContext();
  const initialValues = address || {
    firstName: root.user.firstName,
    lastName: root.user.lastName,
    countryCode: root.user.countryCode || config.countryCode,
  };

  return (
    <Modal open={open} disablePortal onClose={onClose} sx={{ overflow: 'auto' }}>
      <Stack
        mx="auto"
        height="fit-content"
        width={{ xs: '100%', md: 360 }}
        bgcolor={{ xs: 'var(--matterColorBright)', md: 'var(--matterColorLight)' }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          position="relative"
          width="100%"
          height={50}
          sx={{ boxShadow: { xs: 'var(--boxShadowLight)', md: 'unset' } }}
        >
          <Typography textAlign="center" variant="h3" fontSize={16} fontWeight={500} mx={{ md: 'auto' }}>
            {title}
          </Typography>
          <Box
            component="button"
            type="button"
            sx={{
              border: 'none',
              background: 'none',
              padding: 0,
              margin: 0,
              cursor: 'pointer',
              position: 'absolute',
              top: theme => theme.spacing(1),
              right: theme => theme.spacing(2),
            }}
            onClick={onClose}
          >
            <img src={CloseIcon} height={24} width={24} alt="close" />
          </Box>
        </Stack>
        <Stack padding={2} gap={2}>
          <DeliveryInformationForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            updateInProgress={submitting}
            apiFormError={apiFormError}
          />
          <InlineTextButton inProgress={false} disabled={false} onClick={onClose}>
            {intl.formatMessage({ id: 'actions.cancel' })}
          </InlineTextButton>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default compose(injectIntl)(AddressInformationModal);
