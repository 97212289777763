import { element, string } from 'prop-types';
import css from './InfoSection.module.css';

export const InfoSection = ({ title, message, icon }) => (
  <div className={css.sectionContainer}>
    <div className={css.sectionIcon}>{icon}</div>
    <div className={css.sectionContent}>
      <h3>{title}</h3>
      <p>{message}</p>
    </div>
  </div>
);

InfoSection.propTypes = {
  title: string,
  message: string,
  icon: element,
};

InfoSection.defaultProps = {
  icon: null,
  title: null,
  message: null,
};

export default InfoSection;
