import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import css from './IconEmailCheck.module.css';

function IconEmailCheck(props) {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="74" height="74" rx="8" fill="#EDF1F0" />
      <path
        d="M50.3525 22.3846C50.1492 22.2202 49.9116 22.1034 49.6572 22.0428C49.4028 21.9822 49.1381 21.9794 48.8825 22.0346C47.0125 22.4265 45.0821 22.4315 43.2101 22.0493C41.3381 21.6671 39.5642 20.9057 37.9975 19.8121C37.7046 19.6089 37.3565 19.5 37 19.5C36.6435 19.5 36.2955 19.6089 36.0025 19.8121C34.4358 20.9057 32.6619 21.6671 30.7899 22.0493C28.9179 22.4315 26.9875 22.4265 25.1175 22.0346C24.8619 21.9794 24.5972 21.9822 24.3428 22.0428C24.0884 22.1034 23.8508 22.2202 23.6475 22.3846C23.4445 22.5493 23.2809 22.7574 23.1689 22.9936C23.0568 23.2299 22.9991 23.4882 23 23.7496V36.7871C22.9985 39.2962 23.5964 41.7693 24.7439 44.0006C25.8915 46.2318 27.5556 48.1566 29.5975 49.6146L35.985 54.1646C36.2814 54.3756 36.6362 54.489 37 54.489C37.3638 54.489 37.7186 54.3756 38.015 54.1646L44.4025 49.6146C46.4444 48.1566 48.1085 46.2318 49.2561 44.0006C50.4037 41.7693 51.0016 39.2962 51 36.7871V23.7496C51.0009 23.4882 50.9432 23.2299 50.8311 22.9936C50.7191 22.7574 50.5555 22.5493 50.3525 22.3846ZM47.5 36.7871C47.5014 38.7379 47.0368 40.6609 46.1449 42.3959C45.253 44.1309 43.9597 45.6279 42.3725 46.7621L37 50.5946L31.6275 46.7621C30.0403 45.6279 28.747 44.1309 27.8551 42.3959C26.9633 40.6609 26.4987 38.7379 26.5 36.7871V25.7621C30.1688 26.0761 33.8431 25.2249 37 23.3296C40.1569 25.2249 43.8313 26.0761 47.5 25.7621V36.7871ZM39.695 32.7796L34.9875 37.5046L33.43 35.9296C33.1005 35.6001 32.6535 35.415 32.1875 35.415C31.7215 35.415 31.2745 35.6001 30.945 35.9296C30.6155 36.2592 30.4304 36.7061 30.4304 37.1721C30.4304 37.6382 30.6155 38.0851 30.945 38.4146L33.745 41.2146C33.9077 41.3786 34.1012 41.5088 34.3145 41.5977C34.5278 41.6865 34.7565 41.7323 34.9875 41.7323C35.2185 41.7323 35.4473 41.6865 35.6605 41.5977C35.8738 41.5088 36.0673 41.3786 36.23 41.2146L42.25 35.2471C42.5795 34.9176 42.7647 34.4706 42.7647 34.0046C42.7647 33.5386 42.5795 33.0917 42.25 32.7621C41.9205 32.4326 41.4735 32.2475 41.0075 32.2475C40.5415 32.2475 40.0945 32.4326 39.765 32.7621L39.695 32.7796Z"
        fill="#235641"
      />
    </svg>
  );
}

IconEmailCheck.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconEmailCheck.propTypes = { rootClassName: string, className: string };

export default IconEmailCheck;
