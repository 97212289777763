import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import css from './IconPaymentInfo.module.css';

function IconPaymentInfo(props) {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="6.5625"
        y="11.625"
        width="37.5"
        height="30.75"
        rx="3.5"
        stroke="#212529"
        fill="transparent"
        strokeWidth="3"
      />
      <path
        d="M10.5469 16.875C11.2459 16.875 11.8125 16.3084 11.8125 15.6094C11.8125 14.9104 11.2459 14.3438 10.5469 14.3438C9.84789 14.3438 9.28125 14.9104 9.28125 15.6094C9.28125 16.3084 9.84789 16.875 10.5469 16.875Z"
        fill="#212529"
      />
      <path
        d="M14.7656 16.875C15.4646 16.875 16.0312 16.3084 16.0312 15.6094C16.0312 14.9104 15.4646 14.3438 14.7656 14.3438C14.0666 14.3438 13.5 14.9104 13.5 15.6094C13.5 16.3084 14.0666 16.875 14.7656 16.875Z"
        fill="#212529"
      />
      <path
        d="M18.9844 16.875C19.6834 16.875 20.25 16.3084 20.25 15.6094C20.25 14.9104 19.6834 14.3438 18.9844 14.3438C18.2854 14.3438 17.7188 14.9104 17.7188 15.6094C17.7188 16.3084 18.2854 16.875 18.9844 16.875Z"
        fill="#212529"
      />
      <path
        d="M25.045 26.8771C25.1158 25.5377 26.1755 24.5212 27.4989 24.3027C28.6811 24.1075 30.1161 23.8304 31.2454 23.4884C32.69 23.051 34.6348 22.2046 35.9108 21.6214C36.7507 21.2374 37.7181 21.2374 38.5579 21.6214C39.834 22.2046 41.7788 23.051 43.2234 23.4884C44.3527 23.8304 45.7877 24.1075 46.9699 24.3027C48.2932 24.5212 49.353 25.5377 49.4237 26.8771C49.6903 31.9252 49.1126 44.0026 38.0649 47.7406C37.5318 47.921 36.937 47.921 36.4039 47.7406C25.3562 44.0026 24.7784 31.9252 25.045 26.8771Z"
        fill="#235641"
        stroke="#212529"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.7101 28.0978C45.9351 28.4662 46.0697 28.8912 46.0827 29.3541C46.1671 32.3673 45.7803 37.7445 42.4558 41.407L45.7101 28.0978ZM44.0209 26.8268L39.9574 43.4454C39.3858 43.7844 38.7622 44.0848 38.0821 44.339C37.5369 44.5429 36.9297 44.5429 36.3845 44.339C36.1837 44.264 35.9879 44.1849 35.7969 44.102L40.2804 25.7656C40.7315 25.9491 41.1765 26.116 41.5702 26.2395C42.199 26.4367 42.9588 26.6052 43.6656 26.739C43.7864 26.7619 43.905 26.7913 44.0209 26.8268Z"
        fill="#EDF1F0"
      />
    </svg>
  );
}

IconPaymentInfo.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconPaymentInfo.propTypes = { rootClassName: string, className: string };

export default IconPaymentInfo;
