import { Stack, Typography } from '@mui/material';
import DPDIcon from './dpd.svg';

export const ShippingProviderCard = ({ title, description }) => (
  <Stack
    padding={2}
    borderRadius="8px"
    border="1px solid #E9ECEF"
    bgcolor="#FAFAFA"
    direction="row"
    gap={2}
    alignItems="center"
  >
    <img src={DPDIcon} height={56} width={56} alt="dpd" />
    <Stack>
      <Typography variant="h4" fontSize={16} fontWeight={600}>
        {title}
      </Typography>
      {description && (
        <Typography variant="body2" fontSize={16} fontWeight={400}>
          {description}
        </Typography>
      )}
    </Stack>
  </Stack>
);
