import { Box, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSpringCarousel } from 'react-spring-carousel';
import CheckOutlinedIcon from '../assets/icons/check-outlined.svg';

const ITEMS = ['banner.secure_payments','banner.verified_sellers', 'banner.buyer_protection', 'banner.delivery'];

export default function Banner() {
  const intl = useIntl();

  return (
    <Stack width="100vw" height={36} bgcolor="black" overflow="hidden">
      <Stack
        height="100%"
        width="100%"
        display={{ xs: 'none', md: 'flex' }}
        direction="row"
        justifyContent="space-around"
        maxWidth={1320}
        alignSelf="center"
        px={{ xs: 0, md: '10%', lg: '16px' }}
      >
        {ITEMS.map(key => (
          <BannerItem key={key} text={intl.formatMessage({ id: key })} />
        ))}
      </Stack>

      <Box height="100%" width="100%" display={{ xs: 'block', md: 'none' }}>
        <Carousel />
      </Box>
    </Stack>
  );
}

const BannerItem = ({ text }) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    height="100%"
    width={{ xs: '100vw', md: 'fit-content' }}
  >
    <Typography component="span" color="white" display="flex" alignItems="center" gap={0.5} fontSize={14} noWrap>
      <img src={CheckOutlinedIcon} alt="check" />
      {text}
    </Typography>
  </Box>
);

const Carousel = () => {
  const intl = useIntl();
  const { carouselFragment, slideToNextItem } = useSpringCarousel({
    withLoop: true,
    items: ITEMS.map(key => ({
      id: key,
      renderItem: <BannerItem text={intl.formatMessage({ id: key })} />,
    })),
  });

  useEffect(() => {
    const interval = setInterval(() => {
      slideToNextItem();
    }, 5000);

    return () => clearInterval(interval);
  }, [slideToNextItem]);

  return carouselFragment;
};
