// These helpers are calling FTW's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import Decimal from 'decimal.js';
import { get } from 'lodash';

import axios from 'axios';
import config from '../config';
import { types as sdkTypes, transit } from './sdkLoader';

export const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the same domain and port as the frontend
  return `${window.location.origin}`;
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => transit.write(data, { typeHandlers, verbose: config.sdk.transitVerbose });

const deserialize = str => transit.read(str, { typeHandlers });

const post = (path, body) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: serialize(body),
  };
  return window.fetch(url, options).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    }
    if (contentType === 'application/json') {
      return res.json();
    }
    return res.text();
  });
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => post('/api/transaction-line-items', body);

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the order data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => post('/api/initiate-privileged', body);

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => post('/api/transition-privileged', body);

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Flex with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => post('/api/auth/create-user-with-idp', body);

export const createNewConversation = body => post('/api/conversations', body);

export const fetchUserConversations = () => post('/api/current_user/conversations');

export const fetchUnreadConversationsCount = () => post('/api/current_user/unread_conversations');

export const fetchConversationById = conversationId => post(`/api/conversations/${conversationId}`);

export const sendConversationMessage = (conversationId, messageBody, photos) => {
  const url = `${apiBaseUrl()}/api/conversations/${conversationId}/messages`;

  const formData = new FormData();
  formData.append('body', messageBody);
  photos.forEach(photo => formData.append('photos', photo));

  return axios.post(url, formData, {
    withCredentials: true,
    headers: { 'Content-type': 'multipart/form-data' },
  });
};

export const markConversationAsRead = conversationId => {
  const url = `${apiBaseUrl()}/api/conversations/${conversationId}/mark_as_read`;

  return axios.post(url, {}, { withCredentials: true, headers: { 'Content-Type': 'application/json' } });
};

export const fetchUserFavoriteListingsIds = userId => {
  const url = `${apiBaseUrl()}/api/users/${userId}/favorite_listings_ids`;

  return axios.get(url, { withCredentials: true, headers: { 'Content-Type': 'application/json' } });
};

export const fetchUserFavoriteListings = userId => {
  const url = `${apiBaseUrl()}/api/users/${userId}/favorite_listings`;

  return axios.get(url, { withCredentials: true, headers: { 'Content-Type': 'application/json' } });
};

export const addUserFavoriteListing = (userId, listingId, listingType) => {
  const url = `${apiBaseUrl()}/api/users/${userId}/favorite_listings`;

  return axios.post(
    url,
    { listingId, listingType },
    { withCredentials: true, headers: { 'Content-Type': 'application/json' } }
  );
};

export const removeUserFavoriteListing = (userId, listingId) => {
  const url = `${apiBaseUrl()}/api/users/${userId}/favorite_listings`;

  return axios.delete(url, {
    data: { listingId },
    withCredentials: true,
    headers: { 'Content-Type': 'application/json' },
  });
};
