import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

function IconChevronRight(props) {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);

  return (
    <svg className={classes} width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.8336 5.29361L2.59361 1.05361C2.50064 0.959882 2.39004 0.885488 2.26818 0.834719C2.14632 0.783951 2.01562 0.757812 1.88361 0.757812C1.75159 0.757812 1.62089 0.783951 1.49903 0.834719C1.37717 0.885488 1.26657 0.959882 1.1736 1.05361C0.987354 1.24097 0.882812 1.49442 0.882812 1.75861C0.882812 2.0228 0.987354 2.27625 1.1736 2.46361L4.7136 6.00361L1.1736 9.54361C0.987354 9.73097 0.882812 9.98443 0.882812 10.2486C0.882813 10.5128 0.987354 10.7662 1.1736 10.9536C1.26705 11.0463 1.37786 11.1196 1.4997 11.1694C1.62154 11.2191 1.752 11.2444 1.88361 11.2436C2.01521 11.2444 2.14567 11.2191 2.26751 11.1694C2.38935 11.1196 2.50016 11.0463 2.59361 10.9536L6.8336 6.71361C6.92733 6.62065 7.00173 6.51005 7.0525 6.38819C7.10326 6.26633 7.1294 6.13562 7.1294 6.00361C7.1294 5.8716 7.10326 5.74089 7.0525 5.61903C7.00173 5.49717 6.92733 5.38657 6.8336 5.29361Z"
        fill="#ADB5BD"
      />
    </svg>
  );
}

const { string } = PropTypes;

IconChevronRight.defaultProps = {
  className: null,
  rootClassName: null,
};

IconChevronRight.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconChevronRight;
