/* eslint-disable no-use-before-define */
import { Box } from '@mui/material';
import { string } from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { PrivacyPolicyEN } from './PrivacyPolicy.en';
import { PrivacyPolicyLT } from './PrivacyPolicy.lt';
import { PrivacyPolicyPL } from './PrivacyPolicy.pl';

const PROPS = {
  legalEntityCode: '306745687',
  registeredAddress: 'Jogailos g. 4, LT-01116 Vilnius',
  email: 'gearro@gearro.com',
  phone: '+37060428218',
  lastUpdated: '10.07.2024',
};

function PrivacyPolicy({ intl }) {
  if (intl.locale === 'pl') {
    return (
      <Box sx={boxStyle}>
        <PrivacyPolicyPL {...PROPS} />
      </Box>
    );
  }

  if (intl.locale === 'lt') {
    return (
      <Box sx={boxStyle}>
        <PrivacyPolicyLT {...PROPS} />
      </Box>
    );
  }

  return (
    <Box sx={boxStyle}>
      <PrivacyPolicyEN {...PROPS} />
    </Box>
  );
}

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default compose(injectIntl)(PrivacyPolicy);

const boxStyle = {
  '& h1': {
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: '30px',
    textAlign: 'left',
  },

  '& h2': {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '25px',
    textAlign: 'left',
  },

  '& .date': {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'left',
  },

  '& ol': {
    counterReset: 'section',
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },

  '& ol > li': {
    counterIncrement: 'section',
    marginBottom: '0.6em',
  },

  '& ol > li:before': {
    content: 'counter(section) ". "',
    fontWeight: 'bold',
  },

  '& ol ol': {
    counterReset: 'subsection',
  },

  '& ol ol > li': {
    counterIncrement: 'subsection',
    display: 'block',
  },

  '& ol ol > li:before': {
    content: 'counter(section) "." counter(subsection) ". "',
    fontWeight: 'normal',
  },

  '& ol ol ol': {
    counterReset: 'subsubsection',
  },

  '& ol ol ol > li': {
    counterIncrement: 'subsubsection',
    display: 'block',
  },

  '& ol ol ol > li:before': {
    content: 'counter(section) "." counter(subsection) "." counter(subsubsection) ". "',
  },

  '& ul': {
    listStyleType: 'disc',
    paddingLeft: '15px',
  },

  '& ul, ol ol, ol ul': {
    marginLeft: '20px',
    fontWeight: 'normal',
  },
};
