import React from 'react';
import css from './Loader.module.css';

function Loader(props) {
  return (
    <div className={css.loaderRoot}>
      <span className={css.loader} />
    </div>
  );
}

export default Loader;
