import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRootContext } from '../contexts/RootContext';
import routeConfiguration from '../routing/routeConfiguration';
import { findRouteByRouteName } from '../util/routes';

export const useMandatoryVerificationRedirect = isAuthenticated => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const { user } = useRootContext();

  useEffect(() => {
    if (!isAuthenticated || !user?.id || user.loading) {
      return;
    }

    const routeConfig = routeConfiguration();

    const verifyEmailRoute = findRouteByRouteName('VerifyYourEmailPage', routeConfig);
    if (!user.isEmailVerified) {
      if (history.location.pathname !== verifyEmailRoute.path && !loading) {
        setLoading(true);
        history.push(verifyEmailRoute.path);
        return;
      }
      setLoading(false);
      return;
    }

    const verifyPhoneRoute = findRouteByRouteName('VerifyYourPhonePage', routeConfig);
    if (!user.isPhoneVerified) {
      if (history.location.pathname !== verifyPhoneRoute.path && !loading) {
        setLoading(true);
        history.push(verifyPhoneRoute.path);
        return;
      }
      setLoading(false);
      return;
    }

    const acceptTermsOfServiceRoute = findRouteByRouteName('AcceptTermsOfServicePage', routeConfig);
    if (!user.areTermsAndConditionsConfirmed) {
      if (history.location.pathname !== acceptTermsOfServiceRoute.path && !loading) {
        setLoading(true);
        history.push(acceptTermsOfServiceRoute.path);
        return;
      }
      setLoading(false);
      return;
    }

    const verifyBusinessRoute = findRouteByRouteName('VerifyYourBusinessPage', routeConfiguration());
    const { isBusiness, arePaymentTransfersEnabled } = user || {};
    if (isBusiness && !arePaymentTransfersEnabled) {
      if (history.location.pathname !== verifyBusinessRoute.path && !loading) {
        setLoading(true);
        history.push(verifyBusinessRoute.path);
        return;
      }
      setLoading(false);
    }
  }, [history.location.pathname, user?.id, user.loading, loading, isAuthenticated]);

  return null;
};
