import config from '../config';
import { LOCALES } from '../translations/locales';

export const getLocale = () => {
  if (typeof window === 'undefined') {
    return config.locale;
  }
  return window.localStorage.getItem('language_locale') || config.locale;
};

export const getDefaultLocale = host => {
  const currentHost = host || (typeof window !== 'undefined' && window.location.host);
  if (!currentHost) {
    return LOCALES.POLISH;
  }
  return currentHost === 'nextvelo.pl' ||
    currentHost === 'www.nextvelo.pl' ||
    currentHost === 'gearro.pl' ||
    currentHost === 'www.gearro.pl'
    ? LOCALES.POLISH
    : LOCALES.ENGLISH;
};
