import { createContext, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLoadUser } from '../hooks/useLoadUser';

const RootContext = createContext({});

export const useRootContext = () => {
  if (!RootContext) {
    throw new Error('useRootContext must be used within a RootContextProvider');
  }
  return useContext(RootContext);
};

export const RootContextProvider = ({ children }) => {
  const isAuthenticated = useSelector(state => state.Auth.isAuthenticated);
  const loadedUser = useLoadUser(isAuthenticated);

  return (
    <RootContext.Provider value={useMemo(() => ({ user: loadedUser }), [loadedUser?.id, loadedUser.loading])}>
      {children}
    </RootContext.Provider>
  );
};
