import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';

import css from './CookieConsent.module.css';

class CookieConsent extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
    this.onAcceptCookies = this.onAcceptCookies.bind(this);
    this.saveCookieConsent = this.saveCookieConsent.bind(this);
  }

  componentDidMount() {
    const cookies = document.cookie.split('; ').reduce((acc, c) => {
      const [name, value] = c.split('=');
      return { ...acc, [name]: decodeURIComponent(value) };
    }, {});

    if (cookies.euCookiesAccepted !== '1') {
      this.setState({ show: true });
    }
  }

  onAcceptCookies() {
    this.saveCookieConsent();
    this.setState({ show: false });
  }

  saveCookieConsent() {
    // We create date object and modify it to show date 10 years into the future.
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 10);
    // Save the cookie with expiration date
    document.cookie = `euCookiesAccepted=1; path=/; expires=${expirationDate.toGMTString()}`;
  }

  render() {
    const { intl } = this.props;
    const isServer = typeof window === 'undefined';

    // Server side doesn't know about cookie consent
    if (isServer || !this.state.show) {
      return null;
    }
    return (
      <aside className={css.root}>
        <div className={css.cookieContent}>
          <div className={css.cookieMessageWrapper}>
            <label className={css.cookieConsentTitle}>{intl.formatMessage({ id: 'cookies.title' })}</label>
            <span className={css.cookieConsentMessage}>{intl.formatMessage({ id: 'cookies.consent_message' })}</span>
          </div>
          <button className={css.continueBtn} onClick={this.onAcceptCookies}>
            {intl.formatMessage({ id: 'cookies.accept' })}
          </button>
        </div>
      </aside>
    );
  }
}

export default compose(injectIntl)(CookieConsent);
