import React from 'react';
import css from './TopBarWithClose.module.css';
import BackArrowIcon from '../../assets/icons/arrows/back.png';

function TopBarWithClose(props) {
  const {
    onCloseClick,
    itemTitle,
    isLiked,
    addUserFavorite,
    removeUserFavorite,
    listingId,
    listingType,
  } = props;

  const onLikeClick = e => {
    e.stopPropagation();
    e.preventDefault();

    isLiked ? removeUserFavorite(listingId) : addUserFavorite(listingId, listingType);
  };

  return (
    <div className={css.root}>
      <img onClick={onCloseClick} className={css.closeIcon} src={BackArrowIcon} />
      <span className={css.itemTitle}>{itemTitle}</span>
    </div>
  );
}

export default TopBarWithClose;
