import { Modal, Stack, Typography } from '@mui/material';

export const SimpleModal = ({ open, title, description, actions, onClose }) => (
  <Modal open={open} onClose={onClose} aria-labelledby="modal-title" aria-describedby="modal-description">
    <Stack
      gap={2}
      position="absolute"
      top="50%"
      left="50%"
      minWidth={340}
      maxWidth={500}
      bgcolor="white"
      boxShadow={24}
      px={4}
      py={4}
      borderRadius="4px"
      sx={{ transform: 'translate(-50%, -50%)' }}
    >
      {title && (
        <Typography id="modal-title" component="h2" fontSize={24} fontWeight={500} mb={0}>
          {title}
        </Typography>
      )}
      {description && <Typography id="modal-description">{description}</Typography>}
      {actions && (
        <Stack mt={1} gap={2}>
          {actions}
        </Stack>
      )}
    </Stack>
  </Modal>
);
