import { string } from 'prop-types';
import React from 'react';

export const TermsOfServiceEN = ({ legalEntityCode, registeredAddress, email, lastUpdated }) => (
  <div>
    <p className="date">Last Updated: {lastUpdated}</p>
    <h1>TERMS AND CONDITIONS OF USE</h1>

    <h2>Welcome to Gearro!</h2>
    <p>
      We are pleased that you are visiting our online site – Gearro marketplace. By accessing or using Gearro
      marketplace, services, website, applications or in connection with the Gearro services, you signify that you have
      read, understood, and agree to be bound by this terms and conditions of use (the "Terms"). Accordingly, we kindly
      ask you to read Terms carefully, since, as example, provisions stated in Terms affects your rights and will have a
      substantial impact on how claims between you and us are resolved.
    </p>
    <p>
      We reserve the right to amend these Terms at any time and without notice. If we do this, we will post the amended
      Terms on this page and indicate at the top of the page the date the Terms were last revised. Your continued use of
      the Gearro services after any such changes constitutes your acceptance of the new Terms. If you do not agree to
      any of these terms or any future Term, do not use or access (or continue to access) the Gearro service. These
      Terms applies to all users, visitors and others who access the Gearro service.
    </p>
    <p>Read the Terms carefully and have a fun ride!</p>

    <ol>
      <li>
        <strong>WHO ARE WE</strong>
        <ol>
          <li>
            Gearro is a company, with the legal entity code {legalEntityCode}, with the registered address{' '}
            {registeredAddress}.
          </li>
          <li>
            Gearro is a marketplace on: https://www.gearro.com (the "Site"), through which users can sell and (or) buy
            from other users, in both B2B, B2C and C2C forms, sports gear, equipment and related components, new and
            (or) used (the "Items").
          </li>
        </ol>
      </li>

      <li>
        <strong>OUR USERS</strong>
        <ol>
          <li>
            Only users who register on the Site through their own profile, by entering a valid e-mail address and
            password (the "Profile"), may sell and (or) purchase Items by executing transactions. For users under the
            age of 18 years old, the use of the Site must be through registration by and under the supervision of an
            adult.
          </li>
          <li>
            Users of Gearro can be:
            <ul>
              <li>
                individuals acting as consumers within the meaning of the Consumer Rights Directive 2011/83/EU and the
                relevant national implementing laws (the “Consumer Users“);
              </li>
              <li>
                shops, companies, sole proprietorships, no-profit organisations and any other entity marketing the Items
                in the course of its business activity (the “Business Users“), (Consumer Users and Business Users both
                referred to the „User“ or „Users“).
              </li>
            </ul>
          </li>
          <li>
            Items that can be sold and purchased on Gearro Site:
            <ul>
              <li>sports gear and equipment (such as bicycles, snowboards, skis, surfboards and etc.);</li>
              <li>
                sports gear and equipment components (such as: pedals, wetsuits, wheels, helmets, shoes and etc.).
              </li>
            </ul>
          </li>
          <li>
            Items, which are illegal under European Union legal regulations, cannot be sold and purchased on Gearro
            site. In case, the User tries to sell or buy illegal Items, he takes full responsibility for it.
          </li>
          <li>
            Gearro has the right to check the Items offered for sale by Users and will immediately remove advertisements
            relating to those that are unsaleable under this clause or any others at its discretion.
          </li>
        </ol>
      </li>

      <li>
        <strong>USER'S OBLIGATIONS</strong>
        <ol>
          <li>
            When using the Site, the User declares:
            <ul>
              <li>
                that the User – person is an adult natural person and has reached the age of majority (18 years old; if
                not, the person's parents or legal guardian must register on the minor's behalf and oversee their use of
                the Site);
              </li>
              <li>that the User – person has the capacity and authority to conduct transactions on the Site;</li>
              <li>
                that the User – person is aware of and fully responsible for fulfilling all obligations related to the
                purchase and sale of Items in accordance with these Terms and applicable law;
              </li>
              <li>that User – person is aware and liable to pay the costs of the services rendered by Gearro.</li>
            </ul>
          </li>
          <li>
            Users undertake to fully comply with these Terms and, in any case, following rules on the use of the Site:
            <ul>
              <li>
                provide accurate information when register on the Site, including, but not limited to, name and email
                address;
              </li>
              <li>
                register on the Site only once and do not create multiple Profiles unless: (i) the User wants to have a
                business Profile and a personal Profile; in this case, the User cannot register using the same email
                address and both Profiles must be clearly separated, meaning that the personal Profile cannot be used
                for commercial sales and vice versa; (ii) a third party gains unauthorized access to the User's Profile;
                in this scenario, the User can only create a new Profile after notifying Gearro of the third party's
                unauthorized access and only after Gearro has blocked the original Profile;
              </li>
              <li>not to use the Site in an attempt to carry out illegal or fraudulent transactions;</li>
              <li>give accurate, thorough, and detailed prices and objective information on the Items;</li>
              <li>having the authority to assign ownership of the Items that are being sold;</li>
              <li>not to duplicate information or content posted by Gearro or other users;</li>
              <li>
                not to offer, sell, buy, and (or) transfer Items that infringe upon the intellectual property rights of
                third parties, including those related to trademarks, design rights, copyrights, and other signs that
                are protected by applicable law (such as, for instance, distinctive company signs in some
                jurisdictions);
              </li>
              <li>
                not to breach third parties' property rights and (or) personal rights (including intellectual property
                rights);
              </li>
              <li>
                not to act in a way that compromises public safety and (or) morals or in violation of applicable laws
                and (or) regulations;
              </li>
              <li>
                not to use programs and (or) computer files (especially those shared, published, or containing viruses)
                that could: (i) prevent the Site and (or) Gearro services from operating normally, (ii) infect Users'
                systems and cause loss or harm to other Users and third parties, and to Gearro;
              </li>
              <li>
                not to provide, distribute, or otherwise advertise links to other websites and (or) businesses that
                offer services comparable to those of Gearro and the Site;
              </li>
              <li>
                not provide, distribute, or advocate in any way for links to any websites or businesses that offer
                services comparable to those of Gearro and the Site;
              </li>
              <li>
                not engage in data mining, screen scraping, or "crawling" any portion of the Site; also do not break
                down, decompile, or reverse engineer any portion of the Site or adapt, duplicate, vary, alter,
                distribute, or advertise any of the website's content and (or) any Gearro data.
              </li>
            </ul>
          </li>
          <li>
            Users consent to refrain from gathering, concurrently holding, transmitting to third parties, publishing, or
            disclosing the information on Site Users or the information pertaining to the actions of the Site Users,
            including transactions, their numbers, type, price etc;
          </li>
          <li>
            Users undertake not to collect, simultaneously hold, transmit to third parties, make public, publish or
            disclose the information that appears on the Site, if this could infringe the rights of other Users,
            including, but not limited to, intellectual property rights or privacy rights. This restriction does not
            apply to the "share" function that exists on the Site and which allows Users to share public information
            available on the Site, as well as to send such information to themselves or others.
          </li>
          <li>
            Users undertake to keep Profile login information and password confidential and not to disclose it to third
            parties. The User is solely responsible for all activities that occur under his/her Profile.
          </li>
          <li>
            Users agree to immediately update any information on the Site that is no longer current or accurate,
            including information relating to Items, and information provided by the User during registration on the
            Site and information in the Profile.
          </li>
          <li>
            In the event of one or more violations by the User of the rules set out in this Terms part, Gearro reserves
            the right to immediately remove the relevant Profile from the Site, without prejudice to compensation for
            damages.
          </li>
        </ol>
      </li>

      <li>
        <strong>HOW TO SELL ITEM</strong>
        <ol>
          <li>
            Listing and selling Items through Gearro is free for Consumer Users. For Business Users, paid conditions
            might apply, each time Business User shall contact the Gearro in case they want to sell Items at Gearro.
          </li>
          <li>To create a sales ad on the Site, the User must access it through their Profile.</li>
          <li>
            Once logged in with their Profile, the User must go to the 'Sell' section and complete the relevant
            procedure, which includes:
            <ol>
              <li>selecting the category of the Items ';</li>
              <li>
                indicating: i) Location and shipping method; ii) Type of the Item; iii) Details – general information
                about Item; iv) Photos (at least 3), price and other details (such as condition, title and etc.)
              </li>
              <li>
                uploading at least 3 photographs of it in its entirety (in the case of a bike, the first photograph must
                show the bike from the side) in original format and coming from the User themselves. For example,
                non-original images and (or) images downloaded from ads on other platforms cannot be used;
              </li>
              <li>
                the User's contact information. The description and photographs of the Item must reflect the actual
                quality and actual external appearance of the Item itself, as well as any defects or alterations
                affecting it.
              </li>
            </ol>
          </li>
          <li>
            In the event of any violation by the User of the provisions pertaining to the aforementioned, Gearro
            reserves the right to promptly implement the necessary modifications by providing written notice to the User
            through email.
          </li>
          <li>
            Gearro also reserves the right to immediately remove ads that are deemed to be untrue and contain any scams,
            as well as to deactivate the Profile of the relevant User.
          </li>
          <li>
            No limit to the number of advertisements User may have. The User shall not in any way upload or insert onto
            the Site any expressions, photos, or images that are deemed immoral, contrary to common decency, or that
            constitute forms of discrimination (including those based on sex, race, religion, political opinions,
            personal or social conditions) and (or) incitement to engage in unlawful or violent behavior. Additionally,
            the User shall not in any way engage in any activity that could endanger the reputation or honor of Gearro,
            Users, or other third parties, or that could potentially spark 'flaming' incidents.
          </li>
          <li>
            In this respect, Gearro retains the right, upon verification of the aforementioned conditions, to instantly
            delete any material created by the User and to terminate the associated Profile; furthermore, this right
            does not exclude Gearro from providing compensation for any harm, including harm to the User's image. In any
            event, the User must indemnify and hold Gearro completely blameless with regard to any request and (or)
            claim made by third parties resulting from the User's breach of this paragraph, including any punitive
            and/or economic damages.
          </li>
          <li>
            The User has the option to make any adjustment to the advertisement until the request for the purchase is
            received and the Transaction is initiated. After this moment, it is no longer possible to make any changes
            or adjustment to the User’s published advertisement.
          </li>
        </ol>
      </li>

      <li>
        <strong>HOW TO BUY ITEM</strong>
        <ol>
          <li>
            The User must log in to the Site using his or her Profile, choose the desired Item, and click on the
            appropriate sale ad in order to purchase the Item through Gearro. The User can either safely purchase the
            Item by utilizing Gearro's services as outlined below.
          </li>
          <li>
            Purchases made by Users directly from one another, without the use of Gearro's services, are solely and
            totally the responsibility of the Users. Only if the Users uses the Gearro services (buying or selling Item
            through Buy now, by using Gearro chat or etc.), Gearro assumes all the responsibilities specified in these
            Terms. Gearro is not responsible for the payment service providing by Stripe.
          </li>
          <li>
            Gearro provides a service that enables users to safeguard their purchases by using an encrypted payment
            method and sending items in an insured manner (the "Buyer's protection"). Buyer's protection is a Gearro
            service, not an insurance or legal protection program. It doesn't take the place of Users' promises or legal
            rights.
          </li>
          <li>
            To utilize the Buyer’s protection service, the buying User must select the appropriate option during the
            purchase process by clicking on the “Buy now” button.
          </li>
          <li>
            Payment for the Item shall be made through the Stripe or any other payment method that may be entered from
            time to time. The money will not be paid immediately to the selling User, but will only be transferred to
            him after the buying User has received delivery of the Item and has accepted it by pressing „Everything is
            OK“ button.
          </li>
          <li>
            The User shall in fact have the possibility of obtaining a full or partial refund, when decided by Gearro.
            Gearro takes particular account of when:
            <ul>
              <li>the Item received differs considerably from the one in the relevant sales ad;</li>
              <li>the Item received is damaged or defective or broken;</li>
              <li>the Item is not delivered.</li>
            </ul>
          </li>
          <li>
            It is clear and understood by the User that in any case the final decision regarding the full or partial
            refund rests with Gearro.
          </li>
          <li>
            Within 48 hours of receipt of the Item or after the delivery deadline, the buying User may report to Gearro
            any of the above issues by accessing the Site under “Purchases” and clicking "I have an issue“ button. In
            this section, the buying User must enter the subject of the request, a description of the problem
            encountered, and the terms of the possibility of returning the Item to the selling User.
          </li>
          <li>
            Gearro will suspend the transaction in favor of the selling User until the issue is resolved and will
            contact the buying User and selling User, via the email address provided by the User when registering for a
            Profile, to confirm demonstrate the property basis of the claim.
          </li>
          <li>
            Item will be considered damaged or defective or broken if it exhibits obvious cosmetic, technical and (or)
            functional alterations that would render it inconsistent or unsuitable and (or) dangerous to the use that
            the User legitimately expects. Item will differ materially from the Item contained in the relevant sales
            announcement to the extent that it presents different relevant characteristics (including type, size, color,
            design) and is likely to ability to affect the use and appearance of the Item. Users make legitimate
            purchases based on the description and images that appear in the sales ad. Minor differences and (or)
            inconsistencies will not be considered valid for the purposes of this Terms.
          </li>
          <li>
            If Gearro, in its sole discretion, approves or solve a claim, it might cancel the transaction. Users who
            make a purchase will receive a full refund for the payment made in the same manner as was made.
          </li>
          <li>
            All the Items, which User would like to return, shall be in like-new condition with no cosmetic damage or
            worn parts, not used. Otherwise, if the Item is used or any of the cosmetic damage or worn parts appears,
            the Gearro may decide that it is not possible to return the Item.
          </li>
          <li>
            48 hours after delivery of the Item where the buyer User does not report a problem, the Item will be deemed
            as definitively accepted and the corresponding price will be paid by Gearro to the seller User in their
            Virtual Wallet thereafter into their bank account.
          </li>
          <li>
            User agrees to comply with the complaint procedures set forth in these Terms and to cooperate with Gearro to
            help Gearro make the best decisions regarding any complaints and (or) issues based on the objective criteria
            are outlined in this document. In this regard, Gearro is not liable in connection with the Terms during the
            verification stage of the subject matter of the complaint.
          </li>
        </ol>
      </li>

      <li>
        <strong>SECURE PAYMENTS</strong>
        <ol>
          <li>
            Payments through the Site are processed by Gearro in partnership with Stripe. Stripe is a payment processing
            service through credit cards, debit cards, PayPal, or any other payment method linked to an IBAN code of a
            bank account where transactions are processed. Gearro does not process payment data directly: it is handled
            by Stripe in complete safety, security, and compliance with applicable regulations.
          </li>
          <li>
            Selling User shall configure on the Site their own virtual wallet on which to receive transactions in total
            security (the “Virtual Wallet”), following the relevant procedure.
          </li>
          <li>
            Once the transaction is completed, the price of the Item will be transferred to the selling User to his
            Virtual Wallet and subsequently to the selling User's bank account, which the latter has associated with the
            Virtual Wallet.
          </li>
          <li>
            Gearro supports its Users in using the Virtual Wallet and provides related assistance, however Gearro is not
            responsible for . Users must provide accurate information associated with credit cards, debit cards and any
            other payment methods offered on the Site.
          </li>
          <li>
            Without prejudice to ongoing transactions, Gearro reserves the right to suspend and/or discontinue, with
            separate notification where possible, the availability of any service for commercial and (or) technical
            reasons.
          </li>
        </ol>
      </li>

      <li>
        <strong>SHIPPING</strong>
        <ol>
          <li>
            Shipment of purchased Items will be handled entirely by Gearro, with its own trusted couriers and at a cost
            borne by the Buyer.
          </li>
          <li>
            In all cases the Seller is responsible for the packaging. It is being understood that Gearro cannot be held
            liable in any way with regard to any problems arising from improper, insufficient packaging.
          </li>
          <li>
            Selling User have five (5) calendar days to pack and order a pick-up time. If the selling User does not book
            a pick-up within five (5) days of receipt of the packaging, the sale of the Item will be deemed cancelled by
            Gearro and the purchasing User will be refunded.
          </li>
          <li>
            It is recommended by Gearro to use the strong outer packaging, to protect the Item from dirt, moisture, any
            of the damage or etc. It is also recommended by Gearro to fill in the blank, if any, (bubble wrap, foam,
            paper, newspaper or corrugated cardboard can be used) in order to prevent Items from moving inside. The
            label of the shipping shall be attached properly as well. Gearro strongly recommend to take photos of packed
            Item, since photos might be potential evidence in case of dispute - at what condition was Item packed.
          </li>
          <li>
            In any event, collection of the Item shall not take place earlier than five (5) days after acceptance of the
            sale of the Item by the selling User.
          </li>
          <li>
            Once the Item has been shipped, the buying User will be able to track the status of the delivery using the
            Shipment Tracking System accessible through the appropriate link on the Site. The Shipment Tracking System
            exclusively reflects the tracking information provided by the courier responsible for the package.
          </li>
        </ol>
      </li>

      <li>
        <strong>BUYER PROTECTION FEE</strong>
        <ol>
          <li>
            For the Buyer protection service Gearro shall charge the buying User a fee equal to 8% of the price of the
            purchased Item (including applicable taxes on the price of the Item). The amount of the fee will be
            quantified in Euro (or other local currency) in the order summary, which can be viewed by the buyer prior to
            finalization of payment. The cost of Gearro Buyer's protection service always includes VAT.
          </li>
          <li>
            It is understood that Gearro shall in no way be involved in purchases made outside the Site and disclaims
            any liability arising out of or relating to such purchases.
          </li>
        </ol>
      </li>

      <li>
        <strong>CHAT ON THE SITE</strong>
        <ol>
          <li>
            Gearro chat system on the Site has the sole purpose of allowing Users to share information about Items,
            before and after their purchase.
          </li>
          <li>
            Users may not send via the Site's chat:
            <ul>
              <li>messages for publicizing, advertising purposes;</li>
              <li>spam or content spreading viruses or so-called 'worms';</li>
              <li>mass mailings of any kind;</li>
              <li>
                messages containing writings opposite to open security and ethical quality, with improper, insulting and
                (or) defamatory substance and (or) in any case of an illegal nature and with the point of causing harm
                of any kind to other Clients and (or) third parties.
              </li>
            </ul>
          </li>
          <li>
            In such cases, Gearro reserves the right to delete the User's Profile from the Site and to remove any sales
            advertisements associated with it, without prejudice to compensation for damages.
          </li>
        </ol>
      </li>

      <li>
        <strong>PROCESS OF BUY & SELL</strong>
        <ol>
          <li>
            After the buying User selects the Item, he will be taken to the order summary where he will be shown (i) the
            characteristics of the Item, (ii) the total cost, including the price of the Item, the shipping costs and
            the costs of the Buyers protection service. By clicking on "proceed" the buying User will be directed to
            Stripe to process the payment.
          </li>
          <li>
            Once payment has been made, Gearro will contact the selling User with the purchase proposal. The selling
            User will have 48 hours to accept or reject the purchase proposal. In case of acceptance, the selling User
            must notify Gearro indicating the coordinates for the withdrawal of the Item. If 48 hours have elapsed
            without the selling User having accepted the purchase proposal, it shall be deemed to have been rejected.
          </li>
          <li>
            Gearro courier will collect the Item and deliver it to the buying User. If, within 48 hours of delivery, the
            buying User does not raise any complaints about the received Item, Gearro will transfer the price of the
            Item to the Virtual Wallet of the selling User and, subsequently, to his bank account.
          </li>
          <li>
            After the buying User decides to buy and press the Buy now button, the buying user cannot cancel its
            purchase proposal. If the Users do not reach an agreement on the proposed purchase, or the selling User
            rejects the proposal, Gearro will refund the full payment to the selling User.
          </li>
          <li>
            The selling User may refuse the sale of its Item no later than 48 hours after the relevant purchase
            proposal. In this case, Gearro will inform the buying User and reimburse him in full for the payment made.
          </li>
          <li>
            The User purchasing through the Site with Buyer‘s protection will be party to two separate agreements: (i) a
            purchase and sale with the selling User and (ii) a service agreement with Gearro (Terms).
          </li>
          <li>
            Both the buying User and the Selling User acknowledge that through the Buyer‘s protection procedure a
            legally binding agreement is established between them when the selling User's acceptance reaches the buying
            User.
          </li>
          <li>
            The User who uses Buyer‘s protection acknowledges and agrees to enter into a legally binding agreement with
            Gearro in respect of and in accordance with these Terms.
          </li>
        </ol>
      </li>

      <li>
        <strong>LIABILITY</strong>
        <ol>
          <li>
            All Users are fully responsible for the information they post on the Site as well as the Items they offer
            and sell to other Users through the Site. Users therefore acknowledge and agree that they are fully
            responsible for any Items uploaded to the Site, as well as for the description, photographs and any
            information and features posted on the Site and statements made to other Users, including private messages
            through the chat system and reviews.
          </li>
          <li>
            Users are solely responsible to other Users with respect to disputes that may arise between them, except in
            cases where such disputes are caused by Gearro failure to comply with its obligations in relation to the
            Buyers protection service. In any case, any liability of Gearro shall not exceed the price of the Item
            published on the Site.
          </li>
          <li>
            Users acknowledge and accept that Gearro shall in no event be liable for any damage and (or) loss suffered
            by other Users and (or) third parties in connection with the Items, as well as a result of a breach by Users
            of these Terms. In particular, by way of example and without limitation, Gearro shall in no event be liable
            for: (i) the safety, quality and quantity of the Items that Users sell or purchase through the Site, nor of
            the conformity of them with the description and photographs provided; (ii) the actions or omissions of
            Users, (iii) the adequacy, accuracy, exhaustiveness and (or) lawfulness of any content produced by Users on
            the Site; (iv) any problems relating to transactions, except as expressly provided for against Gearro by
            these Terms.
          </li>
          <li>
            Users must use the Site correctly and comply with all laws, rules and regulations that are applicable to
            them. In particular, Users must refrain from (i) infringing the rights of third parties, including
            intellectual property rights; (ii) offering Items for sale that violate laws or regulations; (iii) inciting
            the commission of crimes and/or acts based on discrimination, hatred or violence due to race, ethnicity or
            nationality; (iv) communicating incorrect or confidential information; (v) posting content or reviews that
            are defamatory, abusive, unlawful or otherwise contrary to public order; (vi) posting the personal data of
            other individuals or violating their privacy.
          </li>
          <li>
            Gearro acts as a mere intermediary between Users. If a User reports misconduct by another User to Gearro,
            Gearro may provide assistance to the party concerned. Where required, Gearro will also cooperate with the
            local authorities.
          </li>
          <li>
            Gearro is not responsible for content created by Users. Users remain solely and exclusively responsible for
            their content. Therefore, they acknowledge and accept that Gearro has no contractual obligation to carry out
            any verification of the content or articles published and that it cannot in any case be held liable for any
            damages and/or losses suffered by Users and/or third parties in connection therewith. In this regard, Gearro
            will remove from the Site any illegal content that has been duly reported to it by Users and/or third
            parties.
          </li>
          <li>
            If an Item has any problems with the safety and quality of the product, Gearro will not be held liable in
            any way and advises Users to stop using the Item immediately.
          </li>
          <li>
            Gearro is not responsible for any tax or reporting liabilities that may arise for Users from activities
            carried out through the Site. The Users themselves are solely responsible for any tax reporting obligations
            that may arise from applicable legislation.
          </li>
          <li>
            In compliance with applicable laws and regulations, Gearro takes all appropriate measures to protect the
            security of the data provided by Users and to prevent the services offered from compromising the data and
            software stored on Users' computers, tablets or smartphones. However, even if appropriate measures are taken
            and comply with legal requirements, Gearro systems may still be compromised by unforeseeable events such as
            cyber-attacks or security breaches affecting data transmission or affecting the volume and speed of data
            transmission. In this regard, Users shall take all appropriate measures to protect their data and/or
            software, in particular from contamination by any viruses circulating on the Internet.
          </li>
          <li>
            The Site and the information and content contained therein are provided by Gearro without warranties of any
            kind. Gearro (and its agents, affiliates, licensors and suppliers) disclaim all warranties, express and/or
            implied, including, without limitation, all implied warranties of merchantability, fitness for a particular
            purpose, title and non-infringement.
          </li>
          <li>
            In no event shall Gearro (or any of its agents, affiliates, licensors, or suppliers) be liable for any
            indirect, punitive, incidental, special, or consequential damages arising out of or in any way connected
            with the use of the Site, the posting of for sale listings of Items, the Buyers protection service,
            including, without limitation, damages deriving from the use of the Site by unauthorized Users and/or not in
            compliance with these Terms, third party claims in relation to possible violations of third party rights,
            inaccessibility and interruptions of the Site, or from problems in any way connected to the network,
            telephone lines, failure and/or malfunction of Users' electronic equipment.
          </li>
        </ol>
      </li>

      <li>
        <strong>INTELLECTUAL PROPERTY</strong>
        <ol>
          <li>
            Gearro retain all right, title and interest in and to this Site and its products and services, including all
            copyrights, patents, trade secrets, trademarks, other intellectual property rights, trade names, logos,
            slogans, custom graphics, button icons, scripts, videos, text, images, software, code, and other material
            available on our Site and nothing on this Site may be copied, imitated or used, in whole or in part, without
            our or the applicable licensor’s prior written permission. Gearro reserves all rights not expressly granted.
          </li>
          <li>Any unauthorized reproduction is prohibited.</li>
          <li>
            User must not otherwise reproduce, adapt, store, transmit, distribute, print, display, commercialize,
            publish or create derivative works from any part of the content, format or design of this Site.
          </li>
          <li>
            Gearro does have the right to use information and material posted to the site and affiliated sites by you,
            the user. Gearro will cite the source of information when necessary and use best practices in protecting any
            users intellectual property rights.
          </li>
        </ol>
      </li>

      <li>
        <strong>APPLICABLE LAW AND DISPUTE RESOLUTION</strong>
        <ol>
          <li>These Terms are governed by Republic of Lithuania law.</li>
          <li>
            In the event of a dispute between the User and Gearro, Users may first contact Gearro in order to reach a
            consensual and amicable settlement. In any case, any dispute between Users and Gearro shall be deferred to
            the exclusive jurisdiction of the Court of Republic of Lithuania.
          </li>
        </ol>
      </li>

      <li>
        <strong>CONTACT US</strong>
        <ol>
          <li>If you have any questions about the Terms, please email us at {email}.</li>
        </ol>
      </li>
    </ol>
  </div>
);

TermsOfServiceEN.defaultProps = {
  legalEntityCode: '[________]',
  registeredAddress: '[_____________]',
  email: '[____________________]',
  lastUpdated: '[__.__.____]',
};

TermsOfServiceEN.propTypes = {
  legalEntityCode: string,
  registeredAddress: string,
  email: string,
  lastUpdated: string,
};
