import { string } from 'prop-types';
import React from 'react';

export const TermsOfServicePL = ({ legalEntityCode, registeredAddress, email, lastUpdated }) => (
  <div>
    <p className="date">Ostatnia aktualizacja: {lastUpdated}</p>
    <h1>WARUNKI UŻYTKOWANIA</h1>

    <h2>Witamy w Gearro!</h2>
    <p>
      Cieszymy się, że odwiedzasz naszą stronę internetową - Gearro marketplace. Uzyskując dostęp lub korzystając z
      Gearro marketplace, usług, strony internetowej, aplikacji lub w związku z usługami Gearro, użytkownik oznacza, że
      przeczytał, zrozumiał i zgadza się na przestrzeganie niniejszych warunków użytkowania (&quot;Warunki&quot;). W
      związku z tym uprzejmie prosimy o uważne przeczytanie Warunków, ponieważ na przykład postanowienia określone w
      Warunkach wpływają na prawa użytkownika i będą miały znaczący wpływ na sposób rozstrzygania roszczeń między
      użytkownikiem a nami.
    </p>
    <p>
      Zastrzegamy sobie prawo do zmiany niniejszych Warunków w dowolnym czasie i bez powiadomienia. Jeśli to zrobimy,
      opublikujemy zmienione Warunki na tej stronie i wskażemy u góry strony datę ostatniej zmiany Warunków. Dalsze
      korzystanie z usług Gearro po wprowadzeniu takich zmian oznacza akceptację nowych Warunków. Jeśli użytkownik nie
      wyraża zgody na którykolwiek z niniejszych Warunków lub jakiekolwiek przyszłe Warunki, nie powinien korzystać z
      usługi Gearro ani uzyskiwać do niej dostępu (ani kontynuować uzyskiwania do niej dostępu). Niniejsze Warunki mają
      zastosowanie do wszystkich użytkowników, odwiedzających i innych osób, które uzyskują dostęp do usługi Gearro.
    </p>
    <p>Przeczytaj uważnie Regulamin i baw się dobrze!</p>

    <ol>
      <li>
        <strong>KIM JESTEŚMY</strong>
        <ol>
          <li>
            Gearro jest spółką o kodzie podmiotu prawnego {legalEntityCode}, z zarejestrowanym adresem{' '}
            {registeredAddress}.
          </li>
          <li>
            Gearro to platforma handlowa pod adresem: https://www.gearro.pl (&quot;Witryna&quot;), za pośrednictwem
            której użytkownicy mogą sprzedawać i (lub) kupować od innych użytkowników, zarówno w formie B2B, B2C, jak i
            C2C, sprzęt sportowy, wyposażenie i powiązane komponenty, nowe i (lub) używane (&quot;Przedmioty&quot;).
          </li>
        </ol>
      </li>

      <li>
        <strong>NASI UŻYTKOWNICY</strong>
        <ol>
          <li>
            Tylko użytkownicy, którzy zarejestrują się w Witrynie za pośrednictwem własnego profilu, wprowadzając
            prawidłowy adres e-mail i hasło (&quot;Profil&quot;), mogą sprzedawać i (lub) kupować Przedmioty, wykonując
            transakcje. Użytkownicy w wieku poniżej 18 lat mogą korzystać z Witryny wyłącznie poprzez rejestrację i pod
            nadzorem osoby dorosłej.
          </li>
          <li>
            Użytkownikami Gearro mogą być:
            <ul>
              <li>
                osoby fizyczne działające jako konsumenci w rozumieniu dyrektywy 2011/83/UE w sprawie praw konsumentów
                oraz odpowiednich krajowych przepisów wykonawczych (&quot;Użytkownicy będący konsumentami&quot;);
              </li>
              <li>
                sklepy, firmy, jednoosobowe działalności gospodarcze, organizacje non-profit i wszelkie inne podmioty
                wprowadzające Przedmioty do obrotu w ramach swojej działalności gospodarczej (&quot;Użytkownicy
                biznesowi&quot;), (zarówno Użytkownicy konsumenccy, jak i Użytkownicy biznesowi zwani
                &quot;Użytkownikiem&quot; lub &quot;Użytkownikami&quot;).
              </li>
            </ul>
          </li>
          <li>
            Przedmioty, które można sprzedawać i kupować na stronie Gearro:
            <ul>
              <li>
                sprzęt i wyposażenie sportowe (takie jak rowery, deski snowboardowe, narty, deski surfingowe itp.);
              </li>
              <li>sprzęt sportowy i elementy wyposażenia (takie jak: pedały, pianki, koła, kaski, buty itp.).</li>
            </ul>
          </li>
          <li>
            Przedmioty, które są nielegalne w świetle przepisów Unii Europejskiej, nie mogą być sprzedawane i kupowane
            na stronie Gearro. W przypadku, gdy Użytkownik próbuje sprzedać lub kupić nielegalne Przedmioty, bierze za
            to pełną odpowiedzialność.
          </li>
          <li>
            Gearro ma prawo do sprawdzania Przedmiotów oferowanych do sprzedaży przez Użytkowników i natychmiast usunie
            ogłoszenia dotyczące tych, które nie nadają się do sprzedaży zgodnie z niniejszą klauzulą lub innymi, według
            własnego uznania.
          </li>
        </ol>
      </li>

      <li>
        <strong>Obowiązki użytkownika</strong>
        <ol>
          <li>
            Korzystając z Serwisu, Użytkownik oświadcza, że:
            <ul>
              <li>
                że użytkownik - osoba jest pełnoletnią osobą fizyczną i osiągnęła wiek pełnoletności (18 lat; w
                przeciwnym razie rodzice lub opiekun prawny osoby muszą zarejestrować się w imieniu osoby
                niepełnoletniej i nadzorować korzystanie przez nią z Witryny);
              </li>
              <li>że użytkownik - osoba ma zdolność i uprawnienia do przeprowadzania transakcji w Witrynie;</li>
              <li>
                że użytkownik - osoba jest świadoma i w pełni odpowiedzialna za wypełnienie wszystkich zobowiązań
                związanych z zakupem i sprzedażą Przedmiotów zgodnie z niniejszymi Warunkami i obowiązującym prawem;
              </li>
              <li>
                t użytkownik - osoba jest świadoma i zobowiązana do pokrycia kosztów usług świadczonych przez Gearro.
              </li>
            </ul>
          </li>
          <li>
            Użytkownicy zobowiązują się w pełni przestrzegać niniejszych Warunków oraz, w każdym przypadku, zasad
            korzystania z Witryny:
            <ul>
              <li>
                podawać dokładne informacje podczas rejestracji w Witrynie, w tym między innymi imię i nazwisko oraz
                adres e-mail;
              </li>
              <li>
                zarejestrować się w Witrynie tylko raz i nie tworzyć wielu Profili, chyba że: (i) Użytkownik chce mieć
                Profil biznesowy i Profil osobisty; w takim przypadku Użytkownik nie może zarejestrować się przy użyciu
                tego samego adresu e-mail, a oba Profile muszą być wyraźnie oddzielone, co oznacza, że Profil osobisty
                nie może być używany do sprzedaży komercyjnej i odwrotnie; (ii) osoba trzecia uzyska nieautoryzowany
                dostęp do Profilu Użytkownika; w takim scenariuszu Użytkownik może utworzyć nowy Profil dopiero po
                powiadomieniu Gearro o nieautoryzowanym dostępie osoby trzeciej i dopiero po zablokowaniu przez Gearro
                pierwotnego Profilu;
              </li>
              <li>nie korzystać z Witryny w celu przeprowadzania nielegalnych lub oszukańczych transakcji;</li>
              <li>podawać dokładne, szczegółowe i szczegółowe ceny oraz obiektywne informacje na temat Produktów;</li>
              <li>posiadanie uprawnień do przeniesienia własności sprzedawanych przedmiotów;</li>
              <li>nie powielać informacji lub treści opublikowanych przez Gearro lub innych użytkowników; </li>
              <li>
                nie oferować, nie sprzedawać, nie kupować i (lub) nie przekazywać Przedmiotów, które naruszają prawa
                własności intelektualnej osób trzecich, w tym te związane ze znakami towarowymi, prawami do wzorów,
                prawami autorskimi i innymi oznaczeniami, które są chronione przez obowiązujące prawo (takie jak na
                przykład charakterystyczne znaki firmowe w niektórych jurysdykcjach);
              </li>
              <li>
                nie naruszać praw własności i (lub) dóbr osobistych osób trzecich (w tym praw własności intelektualnej);
              </li>
              <li>
                nie działać w sposób zagrażający bezpieczeństwu publicznemu i (lub) moralności lub naruszający
                obowiązujące przepisy prawa i (lub) regulacje;
              </li>
              <li>
                nieużywania programów i (lub) plików komputerowych (w szczególności udostępnianych, publikowanych lub
                zawierających wirusy), które mogłyby: (i) uniemożliwić normalne działanie Witryny i (lub) usług Gearro,
                (ii) zainfekować systemy Użytkowników i spowodować straty lub szkody dla innych Użytkowników i osób
                trzecich oraz dla Gearro;
              </li>
              <li>
                nie dostarczać, nie rozpowszechniać ani w żaden inny sposób nie reklamować linków do innych witryn
                internetowych i (lub) firm, które oferują usługi porównywalne z usługami Gearro i Witryny;
              </li>
              <li>
                nie dostarczać, nie rozpowszechniać ani nie promować w żaden sposób linków do jakichkolwiek witryn
                internetowych lub firm oferujących usługi porównywalne z usługami Gearro i Witryny;
              </li>
              <li>
                nie angażować się w eksplorację danych, skrobanie ekranu lub &quot;indeksowanie&quot; jakiejkolwiek
                części Witryny; nie wolno również rozkładać, dekompilować ani odtwarzać kodu źródłowego jakiejkolwiek
                części Witryny ani dostosowywać, powielać, zmieniać, modyfikować, rozpowszechniać ani reklamować
                jakiejkolwiek zawartości Witryny i (lub) jakichkolwiek danych Gearro.
              </li>
            </ul>
          </li>
          <li>
            Użytkownicy wyrażają zgodę na powstrzymanie się od gromadzenia, jednoczesnego posiadania, przekazywania
            osobom trzecim, publikowania lub ujawniania informacji o Użytkownikach Strony lub informacji dotyczących
            działań Użytkowników Strony, w tym transakcji, ich liczby, rodzaju, ceny itp;
          </li>
          <li>
            Użytkownicy zobowiązują się nie gromadzić, jednocześnie przechowywać, przekazywać osobom trzecim,
            upubliczniać, publikować ani ujawniać informacji, które pojawiają się na Stronie, jeśli mogłoby to naruszyć
            prawa innych Użytkowników, w tym między innymi prawa własności intelektualnej lub prawa do prywatności.
            Ograniczenie to nie ma zastosowania do funkcji &quot;udostępniania&quot;, która istnieje w Witrynie i która
            umożliwia Użytkownikom udostępnianie informacji publicznych dostępnych w Witrynie, a także wysyłanie takich
            informacji do siebie lub innych osób.
          </li>
          <li>
            Użytkownicy zobowiązują się do zachowania poufności danych logowania i hasła do Profilu oraz nieujawniania
            ich osobom trzecim. Użytkownik ponosi wyłączną odpowiedzialność za wszelkie działania podejmowane w ramach
            jego Profilu.
          </li>
          <li>
            Użytkownik zgadza się niezwłocznie aktualizować wszelkie informacje w Witrynie, które nie są już aktualne
            lub dokładne, w tym informacje dotyczące Przedmiotów oraz informacje podane przez Użytkownika podczas
            rejestracji w Witrynie i informacje w Profilu.
          </li>
          <li>
            W przypadku jednego lub więcej naruszeń przez Użytkownika zasad określonych w niniejszej części Regulaminu,
            Gearro zastrzega sobie prawo do natychmiastowego usunięcia odpowiedniego Profilu z Witryny, bez uszczerbku
            dla odszkodowania za szkody.
          </li>
        </ol>
      </li>

      <li>
        <strong>jak sprzedać przedmiot</strong>
        <ol>
          <li>
            Wystawianie i sprzedawanie przedmiotów za pośrednictwem Gearro jest bezpłatne dla użytkowników będących
            konsumentami. W przypadku użytkowników biznesowych mogą obowiązywać płatne warunki, za każdym razem, gdy
            użytkownik biznesowy skontaktuje się z Gearro w przypadku chęci sprzedaży przedmiotów w Gearro.
          </li>
          <li>
            Aby utworzyć ogłoszenie sprzedaży w Witrynie, Użytkownik musi uzyskać do niego dostęp za pośrednictwem
            swojego Profilu.
          </li>
          <li>
            Po zalogowaniu się do swojego Profilu, Użytkownik musi przejść do sekcji &quot;Sprzedaż&quot; i wykonać
            odpowiednią procedurę, która obejmuje:
            <ol>
              <li>wybór kategorii elementu pomiędzy &quot;Rower&quot; i &quot;Części rowerowe&quot;;</li>
              <li>
                wskazując: i) lokalizację i sposób wysyłki; ii) typ roweru; iii) szczegóły - ogólne informacje o
                rowerze; iv) zdjęcia (co najmniej 3), cenę i inne szczegóły (takie jak stan, tytuł itp.).
              </li>
              <li>
                przesłanie co najmniej 3 zdjęć w całości (w przypadku roweru pierwsze zdjęcie musi przedstawiać rower z
                boku) w oryginalnym formacie i pochodzących od samego Użytkownika. Na przykład nieoryginalne obrazy i
                (lub) obrazy pobrane z reklam na innych platformach nie mogą być używane;
              </li>
              <li>
                dane kontaktowe Użytkownika. Opis i zdjęcia Przedmiotu muszą odzwierciedlać rzeczywistą jakość i
                rzeczywisty wygląd zewnętrzny samego Przedmiotu, a także wszelkie wady lub zmiany mające na niego wpływ.
              </li>
            </ol>
          </li>
          <li>
            W przypadku naruszenia przez Użytkownika postanowień, o których mowa powyżej, Gearro zastrzega sobie prawo
            do niezwłocznego wprowadzenia niezbędnych zmian poprzez pisemne powiadomienie Użytkownika za pośrednictwem
            poczty elektronicznej.
          </li>
          <li>
            Gearro zastrzega sobie również prawo do natychmiastowego usunięcia ogłoszeń, które zostaną uznane za
            nieprawdziwe i zawierające jakiekolwiek oszustwa, a także do dezaktywacji Profilu danego Użytkownika.
          </li>
          <li>
            Brak ograniczeń co do liczby ogłoszeń zamieszczanych przez Użytkownika. Użytkownik nie może w żaden sposób
            przesyłać ani wstawiać na Stronę żadnych wyrażeń, zdjęć ani obrazów, które są uważane za niemoralne,
            sprzeczne z dobrymi obyczajami lub stanowiące formy dyskryminacji (w tym ze względu na płeć, rasę, religię,
            poglądy polityczne, warunki osobiste lub społeczne) i (lub) podżegające do angażowania się w zachowania
            niezgodne z prawem lub przemoc. Ponadto Użytkownik nie może w żaden sposób angażować się w jakiekolwiek
            działania, które mogłyby zagrozić reputacji lub honorowi Gearro, Użytkowników lub innych osób trzecich, lub
            które mogłyby potencjalnie wywołać incydenty &quot;flamingowe&quot;.
          </li>
          <li>
            W związku z tym Gearro zachowuje prawo, po sprawdzeniu wyżej wymienionych warunków, do natychmiastowego
            usunięcia wszelkich materiałów stworzonych przez Użytkownika i do zamknięcia powiązanego Profilu; ponadto
            prawo to nie wyklucza Gearro z zapewnienia odszkodowania za wszelkie szkody, w tym szkody dla wizerunku
            Użytkownika. W każdym przypadku Użytkownik musi zwolnić Gearro z odpowiedzialności w odniesieniu do
            wszelkich żądań i (lub) roszczeń stron trzecich wynikających z naruszenia przez Użytkownika niniejszego
            paragrafu, w tym wszelkich kar i / lub szkód ekonomicznych.
          </li>
          <li>
            Użytkownik ma możliwość wprowadzania zmian w ogłoszeniu do momentu otrzymania żądania zakupu i rozpoczęcia
            transakcji. Po tym momencie nie jest już możliwe dokonywanie jakichkolwiek zmian lub dostosowań w
            opublikowanym przez Użytkownika ogłoszeniu.
          </li>
        </ol>
      </li>

      <li>
        <strong>JAK KUPIĆ PRZEDMIOT</strong>
        <ol>
          <li>
            Użytkownik musi zalogować się do Witryny przy użyciu swojego Profilu, wybrać żądany Przedmiot i kliknąć
            odpowiednie ogłoszenie sprzedaży, aby kupić Przedmiot za pośrednictwem Gearro. Użytkownik może bezpiecznie
            zakupić Przedmiot, korzystając z usług Gearro, jak opisano poniżej.
          </li>
          <li>
            Zakupy dokonywane przez Użytkowników bezpośrednio od siebie nawzajem, bez korzystania z usług Gearro, są
            wyłącznie i całkowicie odpowiedzialnością Użytkowników. Tylko jeśli Użytkownik korzysta z usług Gearro
            (kupując lub sprzedając Przedmiot poprzez Kup teraz, korzystając z czatu Gearro itp.), Gearro przyjmuje na
            siebie wszystkie obowiązki określone w niniejszych Warunkach. Gearro nie ponosi odpowiedzialności za usługę
            płatności świadczoną przez Stripe.
          </li>
          <li>
            Gearro zapewnia usługę, która umożliwia użytkownikom zabezpieczenie zakupów poprzez korzystanie z
            szyfrowanej metody płatności i wysyłanie przedmiotów w sposób ubezpieczony (&quot;Ochrona kupującego&quot;).
            Ochrona Kupującego jest usługą Gearro, a nie ubezpieczeniem lub programem ochrony prawnej. Nie zastępuje ona
            obietnic ani praw Użytkowników.
          </li>
          <li>
            Aby skorzystać z usługi ochrony kupującego, kupujący Użytkownik musi wybrać odpowiednią opcję podczas
            procesu zakupu, klikając przycisk &quot;Kup teraz&quot;.
          </li>
          <li>
            Płatność za Przedmiot zostanie dokonana za pośrednictwem Stripe lub innej metody płatności, która może być
            wprowadzana od czasu do czasu. Pieniądze nie zostaną natychmiast wypłacone sprzedającemu Użytkownikowi, ale
            zostaną mu przekazane dopiero po tym, jak kupujący Użytkownik otrzyma przedmiot i zaakceptuje go, naciskając
            przycisk &quot;Wszystko jest w porządku&quot;.
          </li>
          <li>
            Użytkownik będzie miał możliwość uzyskania pełnego lub częściowego zwrotu pieniędzy, gdy Gearro podejmie
            taką decyzję. Gearro bierze pod uwagę w szczególności sytuacje, gdy:
            <ul>
              <li>otrzymany przedmiot znacznie różni się od tego w odpowiednim ogłoszeniu o sprzedaży;</li>
              <li>otrzymany Przedmiot jest uszkodzony, wadliwy lub zepsuty;</li>
              <li>Przedmiot nie zostanie dostarczony.</li>
            </ul>
          </li>
          <li>
            Użytkownik rozumie, że w każdym przypadku ostateczna decyzja dotycząca pełnego lub częściowego zwrotu
            kosztów należy do Gearro.
          </li>
          <li>
            W ciągu 48 godzin od otrzymania Towaru lub po upływie terminu dostawy, Użytkownik kupujący może zgłosić
            Gearro którykolwiek z powyższych problemów, wchodząc na Stronę w zakładkę &quot;Zakupy&quot; i klikając
            przycisk &quot;Mam problem&quot;. W tej sekcji Użytkownik kupujący musi wpisać przedmiot zgłoszenia, opis
            napotkanego problemu oraz warunki możliwości zwrotu Przedmiotu do Użytkownika sprzedającego.
          </li>
          <li>
            Gearro zawiesi transakcję na korzyść Użytkownika sprzedającego do czasu rozwiązania problemu i skontaktuje
            się z Użytkownikiem kupującym i Użytkownikiem sprzedającym, za pośrednictwem adresu e-mail podanego przez
            Użytkownika podczas rejestracji Profilu, w celu potwierdzenia wykazania podstawy majątkowej roszczenia.
          </li>
          <li>
            Przedmiot zostanie uznany za uszkodzony, wadliwy lub zepsuty, jeśli wykazuje oczywiste kosmetyczne,
            techniczne i (lub) funkcjonalne zmiany, które uczyniłyby go niezgodnym lub nieodpowiednim i (lub)
            niebezpiecznym dla użytkowania, którego Użytkownik słusznie oczekuje. Przedmiot będzie istotnie różnić się
            od Przedmiotu zawartego w odpowiednim ogłoszeniu o sprzedaży w zakresie, w jakim przedstawia inne istotne
            cechy (w tym typ, rozmiar, kolor, wygląd) i może mieć wpływ na użytkowanie i wygląd Przedmiotu. Użytkownicy
            dokonują legalnych zakupów na podstawie opisu i zdjęć, które pojawiają się w ogłoszeniu sprzedaży. Drobne
            różnice i (lub) niespójności nie będą uznawane za ważne dla celów niniejszych Warunków.
          </li>
          <li>
            Jeśli Gearro, według własnego uznania, zatwierdzi lub rozwiąże roszczenie, może anulować transakcję.
            Użytkownicy, którzy dokonają zakupu, otrzymają pełny zwrot dokonanej płatności w taki sam sposób, w jaki
            została ona dokonana.
          </li>
          <li>
            Wszystkie Przedmioty, które Użytkownik chce zwrócić, muszą być w stanie jak nowe, bez uszkodzeń
            kosmetycznych lub zużytych części, nieużywane. W przeciwnym razie, jeśli Przedmiot jest używany lub
            pojawiają się jakiekolwiek uszkodzenia kosmetyczne lub zużyte części, Gearro może zdecydować, że zwrot
            Przedmiotu nie jest możliwy.
          </li>
          <li>
            48 godzin po dostarczeniu Przedmiotu, jeśli Użytkownik kupujący nie zgłosi problemu, Przedmiot zostanie
            uznany za ostatecznie zaakceptowany, a odpowiednia cena zostanie zapłacona przez Gearro Użytkownikowi
            sprzedającemu w jego Wirtualnym portfelu, a następnie na jego konto bankowe.
          </li>
          <li>
            Użytkownik zgadza się przestrzegać procedur składania skarg określonych w niniejszych Warunkach i
            współpracować z Gearro, aby pomóc Gearro w podejmowaniu najlepszych decyzji dotyczących wszelkich skarg i
            (lub) kwestii w oparciu o obiektywne kryteria określone w tym dokumencie. W związku z tym Gearro nie ponosi
            odpowiedzialności w związku z Warunkami na etapie weryfikacji przedmiotu reklamacji.
          </li>
        </ol>
      </li>

      <li>
        <strong>Bezpieczne płatności</strong>
        <ol>
          <li>
            Płatności za pośrednictwem Witryny są przetwarzane przez Gearro we współpracy z firmą Stripe. Stripe to
            usługa przetwarzania płatności za pośrednictwem kart kredytowych, kart debetowych, PayPal lub dowolnej innej
            metody płatności powiązanej z kodem IBAN konta bankowego, na którym przetwarzane są transakcje. Gearro nie
            przetwarza danych dotyczących płatności bezpośrednio: są one obsługiwane przez Stripe w sposób całkowicie
            bezpieczny i zgodny z obowiązującymi przepisami.
          </li>
          <li>
            Użytkownik sprzedający skonfiguruje w Witrynie swój własny wirtualny portfel, na którym będzie otrzymywać
            transakcje z zachowaniem całkowitego bezpieczeństwa (“<strong>Wirtualny portfel</strong>”),postępując
            zgodnie z odpowiednią procedurą.
          </li>
          <li>
            Po zakończeniu transakcji cena przedmiotu zostanie przekazana sprzedającemu użytkownikowi do jego
            wirtualnego portfela, a następnie na konto bankowe sprzedającego użytkownika, które ten powiązał z
            wirtualnym portfelem.
          </li>
          <li>
            Gearro wspiera swoich Użytkowników w korzystaniu z Wirtualnego Portfela i zapewnia związaną z tym pomoc,
            jednak Gearro nie ponosi odpowiedzialności za . Użytkownicy muszą podać dokładne informacje związane z
            kartami kredytowymi, kartami debetowymi i wszelkimi innymi metodami płatności oferowanymi w Witrynie.
          </li>
          <li>
            Bez uszczerbku dla trwających transakcji, Gearro zastrzega sobie prawo do zawieszenia i/lub zaprzestania, z
            osobnym powiadomieniem, jeśli to możliwe, dostępności dowolnej usługi z powodów komercyjnych i (lub)
            technicznych.
          </li>
        </ol>
      </li>

      <li>
        <strong>Wysyłka</strong>
        <ol>
          <li>
            Wysyłka zakupionych Towarów będzie realizowana w całości przez Gearro, za pośrednictwem własnych zaufanych
            kurierów i na koszt poniesiony przez Kupującego.
          </li>
          <li>
            We wszystkich przypadkach odpowiedzialność za opakowanie ponosi Sprzedawca. Przyjmuje się, że Gearro nie
            ponosi żadnej odpowiedzialności za jakiekolwiek problemy wynikające z niewłaściwego, niewystarczającego
            opakowania.
          </li>
          <li>
            Użytkownik sprzedający ma pięć (5) dni kalendarzowych na spakowanie i zamówienie terminu odbioru. Jeśli
            Użytkownik sprzedający nie zarezerwuje odbioru w ciągu pięciu (5) dni od otrzymania opakowania, sprzedaż
            Przedmiotu zostanie uznana za anulowaną przez Gearro, a Użytkownik kupujący otrzyma zwrot pieniędzy.
          </li>
          <li>
            Gearro zaleca użycie mocnego opakowania zewnętrznego, aby chronić przedmiot przed brudem, wilgocią,
            uszkodzeniami itp. Gearro zaleca również wypełnienie pustego miejsca, jeśli takie istnieje (można użyć folii
            bąbelkowej, pianki, papieru, gazety lub tektury falistej), aby zapobiec przemieszczaniu się przedmiotów
            wewnątrz. Należy również odpowiednio przymocować etykietę wysyłkową. Gearro zdecydowanie zaleca zrobienie
            zdjęć zapakowanego Przedmiotu, ponieważ zdjęcia mogą być potencjalnym dowodem w przypadku sporu - w jakim
            stanie Przedmiot został zapakowany.
          </li>
          <li>
            W żadnym wypadku odbiór Przedmiotu nie może nastąpić wcześniej niż pięć (5) dni po zaakceptowaniu sprzedaży
            Przedmiotu przez sprzedającego Użytkownika.
          </li>
          <li>
            Po wysłaniu Przedmiotu Użytkownik kupujący będzie mógł śledzić status dostawy za pomocą Systemu śledzenia
            przesyłek dostępnego za pośrednictwem odpowiedniego łącza w Witrynie. System śledzenia przesyłek
            odzwierciedla wyłącznie informacje o śledzeniu dostarczone przez kuriera odpowiedzialnego za paczkę.
          </li>
        </ol>
      </li>

      <li>
        <strong>Opłata za ochronę kupującego</strong>
        <ol>
          <li>
            Za usługę ochrony kupującego Gearro pobierze od kupującego Użytkownika opłatę w wysokości 8% ceny
            zakupionego Towaru (w tym obowiązujące podatki od ceny Towaru). Kwota opłaty zostanie określona w euro (lub
            innej lokalnej walucie) w podsumowaniu zamówienia, które kupujący może sprawdzić przed sfinalizowaniem
            płatności. Koszt usługi ochrony kupującego Gearro zawsze zawiera podatek VAT.
          </li>
          <li>
            Przyjmuje się, że Gearro nie jest w żaden sposób zaangażowane w zakupy dokonywane poza Witryną i zrzeka się
            wszelkiej odpowiedzialności wynikającej z takich zakupów lub z nimi związanej.
          </li>
        </ol>
      </li>

      <li>
        <strong>CZAT NA STRONIE</strong>
        <ol>
          <li>
            System czatu Gearro na Stronie ma na celu wyłącznie umożliwienie Użytkownikom dzielenia się informacjami o
            Przedmiotach, przed i po ich zakupie.
          </li>
          <li>
            Użytkownicy nie mogą wysyłać wiadomości za pośrednictwem czatu w Witrynie:
            <ul>
              <li>wiadomości w celach reklamowych; </li>
              <li>spamu lub treści rozprzestrzeniających wirusy lub tak zwane &apos;robaki&apos;;</li>
              <li>masowe wysyłki wszelkiego rodzaju;</li>
              <li>
                wiadomości zawierających treści sprzeczne z otwartym bezpieczeństwem i etyką, zawierające niewłaściwe,
                obraźliwe i (lub) zniesławiające treści oraz (lub) w każdym przypadku o charakterze niezgodnym z prawem
                i mające na celu wyrządzenie jakiejkolwiek szkody innym Klientom i (lub) osobom trzecim.
              </li>
            </ul>
          </li>
          <li>
            W takich przypadkach Gearro zastrzega sobie prawo do usunięcia Profilu Użytkownika z Witryny i usunięcia
            wszelkich powiązanych z nim reklam sprzedaży, bez uszczerbku dla odszkodowania za szkody.
          </li>
        </ol>
      </li>

      <li>
        <strong>PROCES sprzedaży i kupna</strong>
        <ol>
          <li>
            Po wybraniu Towaru przez kupującego Użytkownika zostanie on przeniesiony do podsumowania zamówienia, gdzie
            zostaną mu wyświetlone (i) cechy Towaru, (ii) całkowity koszt, w tym cena Towaru, koszty wysyłki i koszty
            usługi ochrony kupujących. Klikając na &quot;Kontynuuj&quot;, kupujący Użytkownik zostanie przekierowany do
            Stripe w celu przetworzenia płatności.
          </li>
          <li>
            Po dokonaniu płatności Gearro skontaktuje się z Użytkownikiem sprzedającym z propozycją zakupu. Użytkownik
            sprzedający będzie miał 48 godzin na zaakceptowanie lub odrzucenie propozycji zakupu. W przypadku akceptacji
            Użytkownik sprzedający musi powiadomić Gearro, wskazując współrzędne wycofania Przedmiotu. Jeśli po upływie
            48 godzin Użytkownik sprzedający nie zaakceptuje propozycji zakupu, zostanie ona uznana za odrzuconą.
          </li>
          <li>
            Kurier Gearro odbierze Przedmiot i dostarczy go Użytkownikowi kupującemu. Jeśli w ciągu 48 godzin od dostawy
            Użytkownik kupujący nie zgłosi żadnych reklamacji dotyczących otrzymanego Przedmiotu, Gearro przeleje cenę
            Przedmiotu do Wirtualnego Portfela Użytkownika sprzedającego, a następnie na jego konto bankowe.
          </li>
          <li>
            Po tym, jak Użytkownik kupujący zdecyduje się na zakup i naciśnie przycisk Kup teraz, Użytkownik kupujący
            nie może anulować swojej propozycji zakupu. Jeśli użytkownicy nie osiągną porozumienia w sprawie
            proponowanego zakupu lub użytkownik sprzedający odrzuci propozycję, Gearro zwróci pełną kwotę płatności
            użytkownikowi sprzedającemu.
          </li>
          <li>
            Użytkownik sprzedający może odmówić sprzedaży swojego Przedmiotu nie później niż 48 godzin po złożeniu
            odpowiedniej propozycji zakupu. W takim przypadku Gearro poinformuje o tym Użytkownika kupującego i zwróci
            mu pełną kwotę dokonanej płatności.
          </li>
          <li>
            Użytkownik dokonujący zakupu za pośrednictwem Witryny z ochroną Kupującego będzie stroną dwóch odrębnych
            umów: (i) kupna-sprzedaży z Użytkownikiem sprzedającym oraz (ii) umowy o świadczenie usług z Gearro
            (Warunki).
          </li>
          <li>
            Zarówno Użytkownik kupujący, jak i Użytkownik sprzedający przyjmują do wiadomości, że poprzez procedurę
            ochrony Kupującego zostaje między nimi zawarta prawnie wiążąca umowa, gdy akceptacja Użytkownika
            sprzedającego dotrze do Użytkownika kupującego.
          </li>
          <li>
            Użytkownik korzystający z Ochrony Kupującego przyjmuje do wiadomości i wyraża zgodę na zawarcie prawnie
            wiążącej umowy z Gearro w odniesieniu do niniejszych Warunków i zgodnie z nimi.
          </li>
        </ol>
      </li>

      <li>
        <strong>ODPOWIEDZIALNOŚĆ</strong>
        <ol>
          <li>
            Wszyscy użytkownicy są w pełni odpowiedzialni za informacje, które publikują w Witrynie, a także za
            przedmioty, które oferują i sprzedają innym użytkownikom za pośrednictwem Witryny. W związku z tym
            użytkownicy przyjmują do wiadomości i zgadzają się, że ponoszą pełną odpowiedzialność za wszelkie przedmioty
            przesłane do Witryny, a także za opisy, zdjęcia oraz wszelkie informacje i funkcje zamieszczone w Witrynie
            oraz oświadczenia złożone innym użytkownikom, w tym prywatne wiadomości za pośrednictwem systemu czatu i
            recenzje.
          </li>
          <li>
            Użytkownicy ponoszą wyłączną odpowiedzialność wobec innych Użytkowników w odniesieniu do sporów, które mogą
            powstać między nimi, z wyjątkiem przypadków, gdy takie spory są spowodowane niewywiązaniem się przez Gearro
            ze swoich zobowiązań w odniesieniu do usługi ochrony kupujących. W każdym przypadku odpowiedzialność Gearro
            nie może przekroczyć ceny Przedmiotu opublikowanej na Stronie.
          </li>
          <li>
            Użytkownicy przyjmują do wiadomości i akceptują, że Gearro w żadnym wypadku nie ponosi odpowiedzialności za
            jakiekolwiek szkody i (lub) straty poniesione przez innych Użytkowników i (lub) osoby trzecie w związku z
            Przedmiotami, a także w wyniku naruszenia przez Użytkowników niniejszych Warunków. W szczególności, tytułem
            przykładu i bez ograniczeń, Gearro w żadnym wypadku nie ponosi odpowiedzialności za: (i) bezpieczeństwo,
            jakość i ilość Przedmiotów, które Użytkownicy sprzedają lub kupują za pośrednictwem Witryny, ani za ich
            zgodność z dostarczonym opisem i zdjęciami; (ii) działania lub zaniechania Użytkowników, (iii) adekwatność,
            dokładność, kompletność i (lub) zgodność z prawem wszelkich treści tworzonych przez Użytkowników w Witrynie;
            (iv) wszelkie problemy związane z transakcjami, z wyjątkiem przypadków wyraźnie przewidzianych przeciwko
            Gearro w niniejszych Warunkach.
          </li>
          <li>
            Użytkownicy muszą prawidłowo korzystać z Witryny i przestrzegać wszystkich przepisów prawa, zasad i
            regulacji, które mają do nich zastosowanie. W szczególności Użytkownicy muszą powstrzymać się od (i)
            naruszania praw osób trzecich, w tym praw własności intelektualnej; (ii) oferowania Przedmiotów na sprzedaż,
            które naruszają przepisy prawa lub regulacje; (iii) podżegania do popełniania przestępstw i/lub czynów
            opartych na dyskryminacji, nienawiści lub przemocy ze względu na rasę, pochodzenie etniczne lub narodowość;
            (iv) przekazywania nieprawidłowych lub poufnych informacji; (v) publikowania treści lub recenzji, które są
            zniesławiające, obraźliwe, niezgodne z prawem lub w inny sposób sprzeczne z porządkiem publicznym; (vi)
            publikowania danych osobowych innych osób lub naruszania ich prywatności.
          </li>
          <li>
            Gearro działa jedynie jako pośrednik między Użytkownikami. Jeśli Użytkownik zgłosi Gearro niewłaściwe
            postępowanie innego Użytkownika, Gearro może udzielić pomocy zainteresowanej stronie. W razie potrzeby
            Gearro będzie również współpracować z lokalnymi władzami.
          </li>
          <li>
            Gearro nie ponosi odpowiedzialności za treści tworzone przez Użytkowników. Użytkownicy pozostają wyłącznie
            odpowiedzialni za swoje treści. W związku z tym przyjmują oni do wiadomości i akceptują, że Gearro nie ma
            umownego obowiązku przeprowadzania jakiejkolwiek weryfikacji publikowanych treści lub artykułów i że w
            żadnym wypadku nie może ponosić odpowiedzialności za jakiekolwiek szkody i/lub straty poniesione w związku z
            tym przez Użytkowników i/lub osoby trzecie. W związku z tym Gearro usunie z Witryny wszelkie nielegalne
            treści, które zostały należycie zgłoszone przez Użytkowników i/lub osoby trzecie.
          </li>
          <li>
            Jeśli Produkt ma jakiekolwiek problemy z bezpieczeństwem i jakością produktu, Gearro nie ponosi żadnej
            odpowiedzialności i zaleca użytkownikom natychmiastowe zaprzestanie korzystania z Produktu.
          </li>
          <li>
            Gearro nie ponosi odpowiedzialności za jakiekolwiek zobowiązania podatkowe lub sprawozdawcze, które mogą
            powstać dla Użytkowników w związku z działaniami prowadzonymi za pośrednictwem Witryny. Użytkownicy ponoszą
            wyłączną odpowiedzialność za wszelkie zobowiązania podatkowe, które mogą wynikać z obowiązujących przepisów.
          </li>
          <li>
            Zgodnie z obowiązującymi przepisami prawa i regulacjami, Gearro podejmuje wszelkie odpowiednie środki w celu
            ochrony bezpieczeństwa danych dostarczanych przez Użytkowników i zapobiegania narażaniu przez oferowane
            usługi danych i oprogramowania przechowywanego na komputerach, tabletach lub smartfonach Użytkowników.
            Jednak nawet w przypadku podjęcia odpowiednich środków zgodnych z wymogami prawnymi, systemy Gearro mogą
            zostać naruszone przez nieprzewidziane zdarzenia, takie jak cyberataki lub naruszenia bezpieczeństwa
            wpływające na transmisję danych lub wpływające na ilość i szybkość transmisji danych. W związku z tym
            Użytkownicy powinni podjąć wszelkie odpowiednie środki w celu ochrony swoich danych i/lub oprogramowania, w
            szczególności przed zanieczyszczeniem wirusami krążącymi w Internecie.
          </li>
          <li>
            Witryna oraz zawarte w niej informacje i treści są dostarczane przez Gearro bez jakichkolwiek gwarancji.
            Gearro (i jego agenci, podmioty stowarzyszone, licencjodawcy i dostawcy) zrzekają się wszelkich gwarancji,
            wyraźnych i/lub dorozumianych, w tym między innymi wszelkich dorozumianych gwarancji przydatności handlowej,
            przydatności do określonego celu, tytułu i nienaruszania praw.
          </li>
          <li>
            W żadnym wypadku Gearro (ani żaden z jego agentów, podmiotów stowarzyszonych, licencjodawców lub dostawców)
            nie ponosi odpowiedzialności za jakiekolwiek pośrednie, karne, przypadkowe, specjalne lub wynikowe szkody
            wynikające z lub w jakikolwiek sposób związane z korzystaniem z Witryny, publikowaniem ofert sprzedaży
            Przedmiotów, usługą ochrony kupujących, w tym m.in, szkód wynikających z korzystania z Witryny przez
            nieupoważnionych Użytkowników i/lub niezgodnie z niniejszymi Warunkami, roszczeń osób trzecich w związku z
            możliwymi naruszeniami praw osób trzecich, niedostępności i przerw w działaniu Witryny lub z problemów w
            jakikolwiek sposób związanych z siecią, liniami telefonicznymi, awarią i/lub nieprawidłowym działaniem
            sprzętu elektronicznego Użytkowników.
          </li>
        </ol>
      </li>

      <li>
        <strong>WŁASNOŚĆ INTELEKTUALNA</strong>
        <ol>
          <li>
            Gearro zachowuje wszelkie prawa, tytuły i udziały w tej Witrynie oraz jej produktach i usługach, w tym
            wszelkie prawa autorskie, patenty, tajemnice handlowe, znaki towarowe, inne prawa własności intelektualnej,
            nazwy handlowe, logo, slogany, niestandardowe grafiki, ikony przycisków, skrypty, filmy, tekst, obrazy,
            oprogramowanie, kod i inne materiały dostępne w naszej Witrynie i nic w tej Witrynie nie może być kopiowane,
            naśladowane ani wykorzystywane, w całości lub w części, bez uprzedniej pisemnej zgody naszej lub
            odpowiedniego licencjodawcy. Gearro zastrzega sobie wszelkie prawa, które nie zostały wyraźnie przyznane.
          </li>
          <li>Wszelkie nieautoryzowane powielanie jest zabronione.</li>
          <li>
            Użytkownikowi nie wolno w żaden inny sposób powielać, adaptować, przechowywać, przesyłać, dystrybuować,
            drukować, wyświetlać, komercjalizować, publikować ani tworzyć dzieł pochodnych na podstawie jakiejkolwiek
            części zawartości, formatu lub projektu niniejszej Witryny.
          </li>
          <li>
            Gearro ma prawo do wykorzystywania informacji i materiałów zamieszczonych w witrynie i witrynach powiązanych
            przez użytkownika. Gearro będzie w razie potrzeby podawać źródło informacji i stosować najlepsze praktyki w
            zakresie ochrony praw własności intelektualnej użytkowników.
          </li>
        </ol>
      </li>

      <li>
        <strong>PRAWO WŁAŚCIWE I ROZSTRZYGANIE SPORÓW</strong>
        <ol>
          <li>Niniejsze Warunki podlegają prawu Republiki Litewskiej.</li>
          <li>
            W przypadku sporu między Użytkownikiem a Gearro, Użytkownicy mogą najpierw skontaktować się z Gearro w celu
            osiągnięcia ugodowego i polubownego rozwiązania. W każdym przypadku wszelkie spory między Użytkownikami a
            Gearro podlegają wyłącznej jurysdykcji Sądu Republiki Litewskiej.
          </li>
        </ol>
      </li>

      <li>
        <strong>KONTAKT</strong>
        <ol>
          <li>
            W przypadku jakichkolwiek pytań dotyczących Regulaminu, prosimy o przesłanie wiadomości e-mail na adres
            {email}.
          </li>
        </ol>
      </li>
    </ol>
  </div>
);

TermsOfServicePL.defaultProps = {
  legalEntityCode: '[________]',
  registeredAddress: '[_____________]',
  email: '[____________________]',
  lastUpdated: '[__.__.____]',
};

TermsOfServicePL.propTypes = {
  legalEntityCode: string,
  registeredAddress: string,
  email: string,
  lastUpdated: string,
};
