import { useEffect, useReducer } from 'react';

const reducer = (state, action) => {
  switch (action.type) {
    case 'CREATE_ORDER':
      return { order: action.payload };
    case 'SET_DELIVERY_ADDRESS':
      return { ...state, order: { ...state.order, receiverAddress: action.payload }, missingAddressError: null };
    case 'UPDATE_ORDER':
      return { ...state, order: action.payload };
    case 'SET_PAYMENT_SECRET':
      return { ...state, paymentSecret: action.payload };
    case 'MISSING_ADDRESS_ERROR': {
      return { ...state, missingAddressError: true };
    }
    case 'PAYMENT_ERROR': {
      return { ...state, paymentError: action.payload };
    }
    case 'CLEAR_ORDER':
      return {};
    default:
      return state;
  }
};

export function useBuyReducer() {
  const [state, dispatch] = useReducer(reducer, loadInitialState());

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    if (Object.keys(state).length === 0) {
      window.sessionStorage.removeItem('order');
      return;
    }
    window.sessionStorage.setItem('order', JSON.stringify(state));
  }, [state]);

  function loadInitialState() {
    if (typeof window !== 'undefined') {
      const savedState = window.sessionStorage.getItem('order');
      return savedState ? JSON.parse(savedState) : {};
    }
    return {};
  }

  return [state, dispatch];
}
