import { createContext, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLoadUser } from '../../hooks/useLoadUser';
import { useBuyReducer } from './reducer';

const BuyFlowContext = createContext({});

export const useBuyFlowContext = () => {
  if (!BuyFlowContext) {
    throw new Error('useBuyFlowContext must be used within a BuyFlowContextProvider');
  }
  return useContext(BuyFlowContext);
};

export const BuyFlowContextProvider = ({ children }) => {
  const isAuthenticated = useSelector(state => state.Auth.isAuthenticated);
  const loadedUser = useLoadUser(isAuthenticated);
  const [state, dispatch] = useBuyReducer();

  return (
    <BuyFlowContext.Provider
      value={useMemo(() => ({ state, user: loadedUser, dispatch }), [state, loadedUser?.id, loadedUser.loading])}
    >
      {children}
    </BuyFlowContext.Provider>
  );
};
