import { string } from 'prop-types';

export const PrivacyPolicyEN = ({ lastUpdated, legalEntityCode, registeredAddress, email, phone }) => (
  <div>
    <p className="date">Last Updated: {lastUpdated}</p>
    <h1>PRIVACY POLICY</h1>

    <p>
      UAB “GEARRO”, company code {legalEntityCode}, address {registeredAddress}, which manages the personal data
      registered on the website www.gearro.com. The e-mail address of the website is {email}, tel. No. {phone}{' '}
      (hereinafter referred to as "Data Controller").
    </p>
    <p>
      The personal data you provide on our Website is processed in accordance with the General Data Protection
      Regulation (EU) 2016/679 (hereinafter referred to as the "Regulation"), the requirements and rules for the
      processing of personal data set out in the Law on the Legal Protection of Personal Data of the Republic of
      Lithuania and in the acts regulating and establishing the protection of personal data and other legal acts of the
      Republic of Lithuania or the European Union.
    </p>

    <h2>GENERAL PROVISIONS</h2>
    <p>
      This privacy policy ("Privacy Policy") describes our UAB “GEARRO” policies and procedures regarding the
      collection, use and disclosure of your information when you use our online store. It also describes your privacy
      rights and how the law protects you.
    </p>
    <p>
      We use your personal data to provide and improve the provision and sale of services-goods ("Services"). By using
      the Services, you consent to the collection and use of information described in this Privacy Policy.
    </p>

    <h2>CONCEPTS AND INTERPRETATION</h2>
    <h3>Interpretation</h3>
    <p>
      Words whose first letter is capitalised shall have the meanings defined in the following definitions. These
      definitions shall have the same meaning whether they are in the singular or plural.
    </p>
    <h3>Definitions</h3>
    <p>For the purposes of this Privacy Policy:</p>
    <ul>
      <li>
        <strong>Account</strong> - a unique account created for you to access our Services or parts thereof;
      </li>
      <li>
        <strong>Company</strong> (referred to in this Agreement as the Company, We, Us or Our) means UAB “GEARRO”;
      </li>
      <li>
        <strong>Cookies</strong> are small files that a website installs on your computer, mobile or any other device
        that contain information about your browsing history on that website;
      </li>
      <li>
        <strong>Country</strong> - Lithuania;
      </li>
      <li>
        <strong>Device</strong> means any device on which the Services may be accessed (whether a computer, mobile
        phone, tablet or otherwise);
      </li>
      <li>
        <strong>Personal data</strong> means any information relating to a natural person, i.e. a data subject, whose
        identity is known or can be established, directly or indirectly, by reference to data such as a personal
        identification number, one or more factors specific to that person, such as his or her physical, physiological,
        psychological, economic, cultural or social characteristics, location data and an online identifier.
      </li>
      <li>
        <strong>Shop</strong> - this website is operated by the company UAB “GEARRO”;
      </li>
      <li>
        <strong>Usage Data</strong> - data collected automatically or through the use of the Service (for example, the
        duration of a visit to a page);
      </li>
      <li>
        <strong>Website</strong> - GEARRO, available at www.gearro.com;
      </li>
      <li>
        <strong>You</strong> - the person accessing or using the Service. This includes companies, legal entities on
        whose behalf the person accesses or has accessed the Service.
      </li>
    </ul>

    <h2>COLLECTION AND USE OF PERSONAL DATA</h2>
    <h3>Types of data collected</h3>
    <p>
      While you are using our services, we may ask you to provide certain Personal Data to help us identify you, contact
      you and provide you with services. Personally Identifiable Information may include, but is not limited to:
    </p>
    <ul>
      <li>Name and surname;</li>
      <li>Email address;</li>
      <li>Telephone number;</li>
      <li>Address;</li>
      <li>Usage data.</li>
    </ul>

    <h3>Data for use</h3>
    <p>
      Usage data is collected automatically when you use the Website. Usage Data may include information such as your
      Device's Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Site you
      have visited, the time and date of your visit, the amount of time spent on those pages, unique Device identifiers,
      and other diagnostic data.
    </p>
    <p>
      When you use the Service on or through a mobile Device, we may automatically collect certain information,
      including, but not limited to: the type of mobile device you are using, your mobile Device's unique ID, the mobile
      Device's IP address, your mobile phone's operating system, the type of mobile web browser you are using, unique
      Device identifiers, and other diagnostics data.
    </p>
    <p>We may also collect information that your browser sends when you visit the Site.</p>

    <h2>TRACKING TECHNOLOGIES AND COOKIES</h2>
    <p>
      We and our partners use a variety of technologies to collect information about Site usage statistics and browsing
      activity, which may include technologies such as cookies or similar technologies such as pixels or web beacons for
      the analysis of trends on the Website, for the administration of the Website, and for the statistics of
      personalised demographic information about Website visitors, and the information we receive cannot identify a
      specific individual. Each visitor can control and refuse how the browser accepts or does not accept any cookie in
      their web browser. We use our own cookies on our Website, together with third party cookies, to ensure the full
      functionality of the Website and for marketing purposes to show you specialised offers.
    </p>

    <h2>USE OF YOUR PERSONAL DATA</h2>
    <p>We may use your Personal Data for the following purposes:</p>
    <ul>
      <li>To provide and maintain the service;</li>
      <li>
        For the management of your Account - the Personal Data you provide during registration provides you with access
        to the special functionality of the Website;
      </li>
      <li>
        For the performance of the contract: the performance and delivery obligations for the goods and/or services you
        purchase;
      </li>
      <li>
        To contact you about delivery of goods, implementation of services, ordering or other important information;
      </li>
      <li>
        Provide you with news, special offers and general information about other goods, services and events we offer
        that are similar to those you have already purchased or enquired about, unless you have chosen not to receive
        such information.
      </li>
      <li>To fulfil your requests;</li>
    </ul>
    <p>Your Personal Data is not disclosed to third parties except in the following cases:</p>
    <ul>
      <li>When the Client's consent is obtained;</li>
      <li>
        Personal data for e-commerce purposes is provided to partners who provide services to the Company in connection
        with the Company's activities;
      </li>
      <li>
        in cases provided for by the laws and regulations of the Republic of Lithuania, to the competent authorities;
      </li>
      <li>
        The website processes payments using:
        <ul>
          <li>
            Stripe, Inc. privacy policy: <a href="https://stripe.com/en-lt/privacy">https://stripe.com/en-lt/privacy</a>
          </li>
        </ul>
      </li>
    </ul>

    <h2>STORAGE OF PERSONAL DATA</h2>
    <p>
      Your personal data will be processed in accordance with the Regulation, the Law on Legal Protection of Personal
      Data of the Republic of Lithuania and other legal requirements, as mentioned before. When processing your personal
      data, we implement organisational and technical measures to ensure the protection of personal data against
      accidental or unlawful destruction, alteration, disclosure, as well as against any other unlawful processing. We
      retain your personal information for archiving purposes for a maximum period of 10 years or until we are required
      to comply with our legal obligations, resolve disputes and fulfil our commitments. This is also to prevent abuse
      and other unlawful activities.
    </p>
    <p>
      All Personal Data and other information provided by you shall be treated as confidential. Access to Personal Data
      is limited to those of our employees and data processors for whom it is necessary for the performance of their job
      functions or the provision of services.
    </p>

    <h2>LINKS TO OTHER WEBSITES</h2>
    <p>
      You may find links on our Website to other pages that are not operated by us. If you click on a third party link,
      you will be redirected to that third party's website. We strongly recommend that you review the privacy policy of
      each website you visit.
    </p>
    <p>
      We have no control over, and accept no responsibility for, the content, privacy policies or practices of third
      party websites or services.
    </p>

    <h2>PROCEDURE FOR AMENDING THIS PRIVACY POLICY</h2>
    <p>
      We may update our Privacy Policy. We will notify you of any changes by posting the new Privacy Policy on this
      page.
    </p>
    <p>
      We will notify you by email and/or a prominent notice on our service before the change takes effect and update the
      "Last Updated" date at the top of this Privacy Policy.
    </p>
    <p>
      We advise you to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are
      effective when posted on this page.
    </p>

    <h2>YOUR RIGHTS</h2>
    <p>
      Know what data we have collected about you: upon receipt of a free-form request from a person to provide what data
      we have collected about him or her, we will provide the data within 30 calendar days from the date of the request.
      Everyone has the right to know what information we have collected and to request its correction or deletion. If,
      following a request, we have any suspicions about the identity or age of the applicant, we have the right to ask
      for the applicant's identity document or a copy thereof.
    </p>
    <p>
      Opt-out of having your information used for direct marketing purposes: we do not oblige our customers to receive
      newsletters or any other promotional information sent to them, so you can freely opt-out of this service by
      logging in to your profile or by clicking the interactive link at the bottom of every promotional email you
      receive.
    </p>
    <p>
      To be forgotten: unless the Regulation or the legislation of the Republic of Lithuania provides otherwise, we
      will, at your lawful request, delete all the data we have collected about you from our servers and information
      system within 4 calendar days, unless this would be impossible or would require disproportionate effort on the
      part of the Data Controllers. Data may not be erased if its storage is required by European Union or Republic of
      Lithuania legislation.
    </p>
    <p>
      Disagree: you can disagree with our privacy policy, however, due to the fact that we need certain data from you
      for the performance of the contract and to ensure the smooth operation of the Website, we need your data, so if
      you disagree with our rules, we unfortunately cannot enter into a contract of sale with you and provide you with
      the services we offer.
    </p>
    <p>To enforce your rights, please contact us in writing, in the national language, by email:</p>

    <h2>RESPONSIBILITY</h2>
    <p>
      You are responsible for maintaining the confidentiality of your password and user data and for any actions (data
      transfers, orders placed, etc.) that are performed on our Website after logging in with your login data. You may
      not disclose your password to third parties. If a third party accesses the services provided on our Website using
      your login credentials, we will consider that you have logged in. If you lose your login credentials, you must
      notify us immediately by post, telephone, fax or email.
    </p>
    <p>
      You must keep your password and login name, if you have created an Account, and other details secure and not
      disclose your login details to any third party. We shall not be liable if your Personal Data is unlawfully
      altered, disclosed, destroyed, your identity is stolen or otherwise fraudulently used in connection with the use
      of your Personal Data, whether as a result of your own fault or negligence, which has arisen as a consequence of
      your non-compliance with this Privacy Policy, the rules of use of the Website, or the law. If a third party
      accesses the services provided on our Website using your login credentials, we will assume that you have logged
      in. If you lose your login details, you must inform us immediately by post, telephone, fax or e-mail. We will also
      not be liable in any event for any damage caused to you as a result of you having provided incorrect or incomplete
      personal data or having failed to inform us of any changes to such data.
    </p>

    <h2>CONTACT</h2>
    <p>If you have any questions about this Privacy Policy, you can contact us at {email}.</p>
  </div>
);

PrivacyPolicyEN.defaultProps = {
  legalEntityCode: '[________]',
  registeredAddress: '[_____________]',
  email: '[____________________]',
  phone: '[___________]',
  lastUpdated: '[__.__.____]',
};

PrivacyPolicyEN.propTypes = {
  legalEntityCode: string,
  registeredAddress: string,
  email: string,
  phone: string,
  lastUpdated: string,
};
