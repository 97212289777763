import React, { useEffect, useRef } from 'react';

import css from './ModalComponent.module.css';

function ModalComponent(props) {
  const { children, onClose, rootClassName, contentClassName } = props;

  const modalRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        return onClose(event);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalRef]);

  return (
    <div className={rootClassName || css.root}>
      <div ref={modalRef} className={contentClassName || css.modalContent}>
        {children}
      </div>
    </div>
  );
}

export default ModalComponent;
