import { Box, Chip } from '@mui/material';
import { useIntl } from 'react-intl';

export const SoldRefurbishedItemImageWrapper = ({
  children,
  borderRadius = '12px',
  sold = false,
  refurbished = false,
  hideLabel = false,
}) => {
  const intl = useIntl();

  if (!sold && !refurbished) {
    return children;
  }

  const soldLabel = intl.formatMessage({ id: 'item_status.sold' });
  const refurbishedLabel = intl.formatMessage({ id: 'item_status.refurbished' });

  return (
    <Box position="relative" borderRadius={borderRadius} overflow="hidden" width="100%">
      {children}
      {!!sold && (
        <Box position="absolute" top={0} right={0} bottom={0} left={0} bgcolor="#333" sx={{ opacity: 0.48 }} />
      )}
      {!hideLabel && (
        <Chip
          label={sold ? soldLabel : refurbishedLabel}
          size="small"
          role="presentation"
          sx={{
            position: 'absolute',
            top: 8,
            left: 8,
            zIndex: 1,
            bgcolor: sold ? '#CED4DA' : '#07120E',
            height: 26,
            px: 0.5,
            span: { color: sold ? '#07020D' : '#fff', fontSize: 14, fontWeight: 400 },
          }}
        />
      )}
    </Box>
  );
};
