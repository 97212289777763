export const plTranslations = {
  'banner.secure_payments': 'Bezpieczne płatności',
  'banner.buyer_protection': 'Ochrona kupującego',
  'banner.delivery': 'Szybka i zintegrowana dostawa',

  'landing_page.sell_button': 'Sprzedaj',
  'landing_page.view_bikes_and_parts': 'Znajdź rower lub części',
  'landing_page.buy_bike_button': 'Zobacz wszystkie rowery',
  'landing_page.buy_parts_button': 'Zobacz wszystkie części',
  'landing_page.schema_description':
    'Kupuj i sprzedawaj używane MTB, gravel i elektryczne rowery na GEARRO. Internetowy sklep rowerów z drugiej ręki. Ochrona kupującego, wysyłka i bezpieczne płatności.',
  'Landing_page.schema_title': 'Używane rowery MTB, gravel, elektryczne lub sprzęt narciarski',
  'landing_page.categories_label': 'Kategorie',
  'landing_page.most_recent_label': 'Najnowsze rowery',
  'landing_page.most_popular_label': 'Rekomendowane',
  'landing_page.most_recent_part_label': 'Części rowerowe',
  'landing_page.section_hero.title': 'Zamień swój',
  'landing_page.section_hero.sub_title': 'Rower na gotówkę',
  'landing_page.section_hero.description_1': 'Stwórz ogłoszenie za darmo w mniej niż 3 minuty.',
  'landing_page.section_hero.description_2': 'Dotrzyj do tysięcy kupujących.',
  'landing_page.popular_brands': 'Popularne marki',
  'landing_page.view_more': 'Zobacz więcej',
  'landing_page.sell_bike_ad.title': 'Sprzedaj swój rower',
  'landing_page.sell_bike_ad.description':
    'Proces dodawania ogłoszenia jest prosty i szybki, kupujący odpowiadają na Twoje ogłoszenie szybko.',
  'landing_page.sell_bike_ad.button': 'Sprzedaj swój rower',

  'locales.english': 'EN',
  'locales.lithuanian': 'LT',
  'locales.polish': 'PL',

  'months.january': 'Styczeń',
  'months.february': 'Luty',
  'months.march': 'Marzec',
  'months.april': 'Kwiecień',
  'months.may': 'Maj',
  'months.june': 'Czerwiec',
  'months.july': 'Lipiec',
  'months.august': 'Sierpień',
  'months.september': 'Wrzesień',
  'months.october': 'Październik',
  'months.november': 'Listopad',
  'months.december': 'Grudzień',

  'landing_page.section_info.label': 'Jak działa Gearro?',

  'landing_page.section_info.sell': 'Sprzedaj',
  'landing_page.section_info.sell.question_0': 'Opowiedz o sprzedawanym rowerze',
  'landing_page.section_info.sell.answer_0':
    'Podaj specyfikacje roweru. Dodaj informacje takie jak rozmiar, materiał, kolor i inne szczegóły.',
  'landing_page.section_info.sell.question_1': 'Odpowiadaj na zapytania',
  'landing_page.section_info.sell.answer_1':
    'Skontaktuj się z potencjalnymi klientami za pomocą systemu wiadomości, odpowiadaj na ich pytania lub umów się na jazdę próbną.',
  'landing_page.section_info.sell.question_2': 'Potwierdź płatność',
  'landing_page.section_info.sell.answer_2':
    'Spotkaj się z kupującym, aby odebrać płatność lub zaakceptować płatność online.',

  'landing_page.section_info.buy': 'Kup',
  'landing_page.section_info.buy.question_0': 'Wybierz rower, który Ci się podoba',
  'landing_page.section_info.buy.answer_0': 'Szukaj rowerów według rozmiaru, typu roweru lub innych cech roweru.',
  'landing_page.section_info.buy.question_1': 'Dowiedz się więcej o rowerze',
  'landing_page.section_info.buy.answer_1': 'Umów się na jazdę próbną lub, jeśli chcesz, odbierz go od razu.',
  'landing_page.section_info.buy.question_2': 'Potwierdź płatność',
  'landing_page.section_info.buy.answer_2':
    'Spotkaj się z sprzedawcą, aby zapłacić gotówką lub zorganizować płatność online.',

  'landing_page.section_info.sell.title_0': '1. Opowiedz o sprzedawanym rowerze',
  'landing_page.section_info.sell.description_0':
    'Podaj specyfikacje roweru. Dodaj informacje takie jak rozmiar, materiał, kolor i inne szczegóły.',
  'landing_page.section_info.sell.title_1': '2. Odpowiadaj na zapytania',
  'landing_page.section_info.sell.description_1':
    'Skontaktuj się z potencjalnymi klientami za pomocą systemu wiadomości, odpowiadaj na ich pytania lub umów się na jazdę próbną.',
  'landing_page.section_info.sell.title_2': '3. Potwierdź płatność',
  'landing_page.section_info.sell.description_2':
    'Spotkaj się z kupującym, aby odebrać płatność lub zaakceptować płatność online.',
  'landing_page.section_info.buy.title_0': '1. Wybierz rower, który Ci się podoba',
  'landing_page.section_info.buy.description_0': 'Szukaj rowerów według rozmiaru, typu roweru lub innych cech roweru.',
  'landing_page.section_info.buy.title_1': '2. Dowiedz się więcej o rowerze',
  'landing_page.section_info.buy.description_1': 'Umów się na jazdę próbną lub, jeśli chcesz, odbierz go od razu.',
  'landing_page.section_info.buy.title_2': '3. Potwierdź płatność',
  'landing_page.section_info.buy.description_2':
    'Spotkaj się z sprzedawcą, aby zapłacić gotówką lub zorganizować płatność online.',

  'landing_page.section_hero.slide_1.title': 'Używane rowery na sprzedaż',
  'landing_page.section_hero.slide_1.content': 'MTB, gravel, rowery elektryczne i więcej',
  'landing_page.section_hero.slide_1.button': 'Kup teraz',
  'landing_page.section_hero.slide_2.title': 'Sprzedawaj bez prowizji i opłat za konto',
  'landing_page.section_hero.slide_2.content': 'Uzyskaj pełną cenę za sprzedany przedmiot',
  'landing_page.section_hero.slide_2.button': 'Sprzedaj teraz',
  'landing_page.section_hero.slide_3.title': 'Zdobądź ulubiony sprzęt za mniej',
  'landing_page.section_hero.slide_3.content': 'Oszczędź średnio 30% kupując używany sprzęt sportowy.',
  'landing_page.section_hero.slide_3.button': 'Sprzedaj teraz',

  'config.labels.condition': 'Stan',
  'config.labels.shipping': 'Wysyłka',
  'config.labels.receiptAvailable': 'Paragon',
  'config.labels.refurbished': 'Odnowiony',
  'config.labels.frameColor': 'Kolor ramy',
  'config.labels.frameMaterial': 'Materiał ramy',
  'config.labels.wheelSize': 'Rozmiar kół',
  'config.labels.frameSize': 'Rozmiar ramy',
  'config.labels.year': 'Rok',
  'config.labels.brand': 'Marka',
  'config.labels.category': 'Typ',
  'config.labels.price': 'Cena',
  'config.labels.keyword': 'Słowo kluczowe',
  'config.labels.model': 'Model',
  'config.labels.country': 'Kraj',
  'config.labels.subCategory': 'Kategoria',
  'config.labels.discipline': 'Dyscyplina',
  'config.labels.frontTravel': 'Skok przedniego zawieszenia',
  'config.labels.rearTravel': 'Skok tylnego zawieszenia',
  'config.labels.dropperTravel': 'Skok droppera',
  'config.labels.dropperDiameter': 'Średnica droppera',
  'config.labels.dropperActivation': 'Aktywacja droppera',
  'config.labels.catalogTree': 'Kategoria',
  'config.labels.hubStandard': 'Standard piast',
  'config.labels.frontHubStandard': 'Standard przedniej piasty',
  'config.labels.rearHubStandard': 'Standard tylnej piasty',
  'config.labels.suspensionType': 'Typ zawieszenia',
  'config.labels.suspensionMounting': 'Montaż zawieszenia',
  'config.labels.suspensionLength': 'Długość zawieszenia',
  'config.labels.suspensionStroke': 'Skok zawieszenia',
  'config.labels.axleDimension': 'Wymiar osi',
  'config.labels.gears': 'Przerzutki',
  'config.labels.frontGears': 'Przerzutki przednie',
  'config.labels.rearGears': 'Przerzutki tylne',
  'config.labels.placement': 'Typ',
  'config.labels.activation': 'Aktywacja',
  'config.labels.chainringMounting': 'Montaż łańcucha',
  'config.labels.bottomBracketMounting': 'Montaż suportu',
  'config.labels.pedals': 'Typ',
  'config.labels.frontWheelSize': 'Rozmiar przedniego koła',
  'config.labels.rearWheelSize': 'Rozmiar tylnego koła',
  'config.labels.wheelsPlacement': 'Typ',
  'config.labels.brakeType': 'Typ hamulców',
  'config.labels.brakesActivation': 'Aktywacja hamulców',
  'config.labels.hubsPlacement': 'Typ',
  'config.labels.handlebarType': 'Typ kierownicy',
  'config.labels.gripsType': 'Typ chwytaków',
  'config.labels.steererType': 'Typ rurki sterowej',
  'config.labels.crankArmLength': 'Długość ramion korby',
  'config.labels.chainringTeeth': 'Zęby',
  'config.labels.handlebarWidth': 'Szerokość kierownicy',

  'categories.all': 'Rowery i części',
  'categories.none': 'Wszystkie kategorie',
  'categories.bike': 'Rowery',
  'categories.parts': 'Części',

  'categories.city': 'Rowery Miejskie',
  'categories.road': 'Rowery Szosowe',
  'categories.mountain': 'Rowery Górskie',
  'categories.gravel': 'Rowery Gravel',
  'categories.bmx_dirt': 'Rowery Bmx & Dirt',
  'categories.folding': 'Rowery Składane',
  'categories.electric': 'Rowery Elektryczne',
  'categories.enduro': 'Rowery Enduro',
  'categories.downhill': 'Rowery Downhill',
  'categories.other': 'Inne',

  'categories.frame': 'Rama',
  'categories.suspension': 'Zawieszenie',
  'categories.drivetrain': 'Napęd',
  'categories.brakes': 'Hamulce',
  'categories.wheels': 'Koła',
  'categories.tyres_tubes': 'Opony',
  'sub_categories.tyres_tubes': 'Opony',
  'categories.cockpit': 'Kokpit',
  'categories.seat': 'Siodełko',

  'categories.track': 'Torowe',
  'categories.aearo_triathlon_tt': 'Aero/Triathlon/TT',
  'categories.single_gear': 'Jednoślad',
  'categories.xc_cross_country': 'XC / Cross Country',
  'categories.trail': 'Trasy górskie',
  'categories.bmx': 'Bmx',
  'categories.dirt_street': 'Rower Dirt/Street',
  'categories.electric_city': 'Elektryczne miejskie',
  'categories.electric_mountain': 'Elektryczne górskie',
  'categories.electric_road': 'Elektryczne szosowe',
  'categories.electric_other': 'Inne',
  'categories.cargo': 'Transportowe',
  'categories.tricycle': 'Trójkołowe',
  'categories.tandem': 'Tandem',
  'categories.balance': 'Balans',
  'categories.unicycle': 'Monocykl',

  'sub_categories.frame_full_suspension': 'Pełne zawieszenie',
  'sub_categories.frame_hard_tail_mtb': 'Hardtail MTB',
  'sub_categories.frame_road': 'Szosowe',
  'sub_categories.frame_gravel': 'Gravel',
  'sub_categories.frame_full_suspension_e_bike': 'Pełne zawieszenie E-Bike',
  'sub_categories.frame_hard_tail_e_bike': 'Hardtail E-Bike',
  'sub_categories.frame_city': 'Miejskie',
  'sub_categories.frame_dirt_n_street': 'Dirt & Street',

  'sub_categories.suspension_rigid_fork': 'Sztywna widelca',
  'sub_categories.suspension_suspension_fork': 'Widelca z zawieszeniem',
  'sub_categories.suspension_rear': 'Zawieszenie tył',

  'sub_categories.drivetrain_crank': 'Korba',
  'sub_categories.drivetrain_chain': 'Łańcuch',
  'sub_categories.drivetrain_cassette': 'Kaseta',
  'sub_categories.drivetrain_shifter': 'Manetka',
  'sub_categories.drivetrain_derailleur': 'Przerzutka',
  'sub_categories.drivetrain_chainring': 'Koło łańcuchowe',
  'sub_categories.drivetrain_bottom_bracket': 'Łożysko suportu',
  'sub_categories.drivetrain_chain_guides': 'Prowadnica łańcucha',
  'sub_categories.drivetrain_pedals': 'Pedały',

  'sub_categories.brakes_disk': 'Hamulce tarczowe',
  'sub_categories.brakes_rim': 'Hamulce felgowe',
  'sub_categories.brakes_rotor': 'Tarcza hamulcowa',
  'sub_categories.brakes_other': 'Inne',

  'sub_categories.wheels_complete': 'Kompletne koła',
  'sub_categories.wheels_hubs': 'Piasty',
  'sub_categories.wheels_rims': 'Obręcze',

  'sub_categories.cockpit_handlebar': 'Kierownica',
  'sub_categories.cockpit_stem': 'Mostek',
  'sub_categories.cockpit_grips': 'Chwytaki',
  'sub_categories.cockpit_headset': 'Łożysko steru',

  'sub_categories.seat_dropper_post': 'Dropper post',
  'sub_categories.seat_post': 'Sztyca',
  'sub_categories.seat_saddle': 'Siodełko',
  'sub_categories.seat_other': 'Inne',

  // Snow gear

  'new_listing_page.type.winter': 'Sprzęt zimowy',
  'new_listing_page.description.winter': 'Snowboardy, narty, buty i akcesoria',

  'categories.winter': 'Zima',
  'categories.skis': 'Narty',
  'categories.snowboard': 'Snowboard',
  'categories.accessories': 'Akcesoria',

  'sub_categories.skis_alpine': 'Narty alpejskie',
  'sub_categories.skis_touring_backcountry': 'Narty alpejskie/turystyczne',
  'sub_categories.skis_cross_country': 'Narty biegowe',
  'sub_categories.skis_park_pipe': 'Park & Pipe',
  'sub_categories.skis_boots': 'Buty',
  'sub_categories.skis_bindings': 'Wiązania',
  'sub_categories.skis_poles': 'Kije',
  'sub_categories.snowboard': 'Snowboard',
  'sub_categories.snowboard_boots': 'Buty',
  'sub_categories.snowboard_bindings': 'Wiązania',
  'sub_categories.helmets': 'Kaski',
  'sub_categories.goggles': 'Gogle',

  'parts_listing_page.specifications.ski_length': 'Długość nart (cm)',
  'parts_listing_page.specifications.ski_length_placeholder': 'Wybierz długość',
  'parts_listing_page.specifications.ski_length_required': 'Proszę wybrać długość nart',
  'parts_listing_page.specifications.ski_waist_width': 'Szerokość w talii',
  'parts_listing_page.specifications.ski_waist_width_placeholder': 'Wybierz szerokość w talii',
  'parts_listing_page.specifications.with_bindings': 'Wiązania w zestawie',
  'parts_listing_page.specifications.with_bindings_placeholder': 'Wybierz',
  'parts_listing_page.specifications.gender': 'Płeć',
  'parts_listing_page.specifications.gender_placeholder': 'Wybierz płeć',
  'parts_listing_page.specifications.snowboard_size': 'Rozmiar',
  'parts_listing_page.specifications.snowboard_size_placeholder': 'Wybierz rozmiar',
  'parts_listing_page.specifications.snowboard_size_required': 'Proszę wybrać rozmiar snowboardu',
  'parts_listing_page.specifications.snowboard_type': 'Typ',
  'parts_listing_page.specifications.snowboard_type_placeholder': 'Wybierz typ',
  'parts_listing_page.specifications.snowboard_flex_rate': 'Elastyczność',
  'parts_listing_page.specifications.snowboard_flex_rate_placeholder': 'Wybierz elastyczność',
  'parts_listing_page.specifications.helmet_size': 'Rozmiar',
  'parts_listing_page.specifications.helmet_size_placeholder': 'Wybierz rozmiar',
  'parts_listing_page.specifications.helmet_size_required': 'Proszę wybrać rozmiar kasku',
  'parts_listing_page.specifications.color': 'Kolor',
  'parts_listing_page.specifications.color_placeholder': 'Wybierz kolor',
  'parts_listing_page.specifications.goggles_frame_size': 'Rozmiar ramki',
  'parts_listing_page.specifications.goggles_frame_size_placeholder': 'Wybierz rozmiar ramki',
  'parts_listing_page.specifications.goggles_frame_size_required': 'Proszę wybrać rozmiar ramki gogli',
  'parts_listing_page.specifications.poles_size': 'Rozmiar kijów',
  'parts_listing_page.specifications.poles_size_placeholder': 'Wybierz rozmiar kijów',
  'parts_listing_page.specifications.boots_size': 'Rozmiar butów',
  'parts_listing_page.specifications.boots_size_placeholder': 'Wybierz rozmiar butów',
  'parts_listing_page.specifications.boots_size_required': 'Proszę wybrać rozmiar butów',
  'parts_listing_page.specifications.ski_boots_type': 'Typ butów',
  'parts_listing_page.specifications.ski_boots_type_placeholder': 'Wybierz typ butów',
  'parts_listing_page.specifications.ski_boots_flex_rate': 'Elastyczność butów',
  'parts_listing_page.specifications.ski_boots_flex_rate_placeholder': 'Wybierz elastyczność butów',
  'parts_listing_page.specifications.ski_bindings_size': 'Rozmiar wiązań',
  'parts_listing_page.specifications.ski_bindings_size_placeholder': 'Wybierz rozmiar wiązań',
  'parts_listing_page.specifications.ski_bindings_size_required': 'Proszę wybrać rozmiar wiązań',
  'parts_listing_page.specifications.snowboard_boots_flex_rate': 'Elastyczność butów',
  'parts_listing_page.specifications.snowboard_boots_flex_rate_placeholder': 'Proszę wybrać elastyczność butów',
  'parts_listing_page.specifications.snowboard_bindings_size': 'Rozmiar wiązań',
  'parts_listing_page.specifications.snowboard_bindings_size_placeholder': 'Wybierz rozmiar wiązań',
  'parts_listing_page.specifications.snowboard_bindings_size_required': 'Proszę wybrać rozmiar wiązań',

  'gender.men': 'Męski',
  'gender.women': 'Damski',
  'gender.kids': 'Dziecięcy',
  'gender.unisex': 'Unisex',
  'gender.placeholder': 'Wybierz płeć',
  'with_bindings.yes': 'Tak',
  'with_bindings.no': 'Nie',
  'with_bindings.placeholder': 'Czy posiada wiązania',
  'snowboard_type.all_mountain': 'All mountain',
  'snowboard_type.freeride': 'Freeride',
  'snowboard_type.freestyle': 'Freestyle',
  'snowboard_type.park_pipe': 'Park & Pipe',
  'snowboard_type.powder': 'Puch',
  'snowboard_type.splitboards': 'Splitboard',
  'snowboard_type.wide': 'Szeroki',
  'snowboard_type.placeholder': 'Wybierz typ',
  'snowboard_flex_rate.soft': 'Miękki',
  'snowboard_flex_rate.medium': 'Średni',
  'snowboard_flex_rate.stiff': 'Sztywny',
  'snowboard_flex_rate.very_stiff': 'Bardzo sztywny',
  'snowboard_flex_rate.placeholder': 'Wybierz elastyczność',
  'goggles_frame_size.placeholder': 'Wybierz rozmiar ramki',
  'goggles_frame_size.small': 'Mały',
  'goggles_frame_size.medium': 'Średni',
  'goggles_frame_size.large': 'Duży',
  'snowboard_size.placeholder': 'Wybierz rozmiar',
  'boots_size.placeholder': 'Wybierz rozmiar',
  'snowboard_boots_flex_rate.soft': 'Miękki',
  'snowboard_boots_flex_rate.medium': 'Średni',
  'snowboard_boots_flex_rate.adjustable': 'Regulowany',
  'snowboard_boots_flex_rate.placeholder': 'Wybierz elastyczność',
  'snowboard_bindings_size.xs': 'Bardzo mały',
  'snowboard_bindings_size.s': 'Mały',
  'snowboard_bindings_size.m': 'Średni',
  'snowboard_bindings_size.l': 'Duży',
  'snowboard_bindings_size.xl': 'Bardzo duży',
  'snowboard_bindings_size.one_size': 'Uniwersalny',
  'snowboard_bindings_size.other': 'Inny',
  'snowboard_bindings_size.placeholder': 'Wybierz rozmiar',
  'helmet_size.placeholder': 'Wybierz rozmiar',
  'color.placeholder': 'Wybierz kolor',
  'ski_length.placeholder': 'Wybierz długość',
  'ski_waist_width.placeholder': 'Wybierz szerokość w talii',
  'ski_boots_type.alpine': 'Alpejskie',
  'ski_boots_type.alpine_touring_backcountry': 'Alpejskie/Turystyczne',
  'ski_boots_type.cross_country': 'Biegowe',
  'ski_boots_type.park_pipe': 'Park & Pipe',
  'ski_boots_type.placeholder': 'Wybierz typ',
  'ski_boots_flex_rate.very_soft': 'Bardzo miękkie (poniżej 70)',
  'ski_boots_flex_rate.soft': 'Miękkie (70-90)',
  'ski_boots_flex_rate.medium': 'Średnie (95-115)',
  'ski_boots_flex_rate.stiff': 'Sztywne (120-130)',
  'ski_boots_flex_rate.very_stiff': 'Bardzo sztywne (powyżej 130)',
  'ski_boots_flex_rate.placeholder': 'Wybierz elastyczność',
  'ski_bindings_size.placeholder': 'Wybierz rozmiar wiązań',
  'poles_size.adjustable': 'Regulowane',
  'poles_size.34': '34in (85cm)',
  'poles_size.36': '36in (90cm)',
  'poles_size.38': '38in (95cm)',
  'poles_size.40': '40in (100cm)',
  'poles_size.42': '42in (105cm)',
  'poles_size.44': '44in (110cm)',
  'poles_size.46': '46in (115cm)',
  'poles_size.48': '48in (120cm)',
  'poles_size.50': '50in (125cm)',
  'poles_size.52': '52in (130cm)',
  'poles_size.54': '54in (135cm)',
  'poles_size.56': '56in (140cm)',
  'poles_size.more_than_56': 'Powyżej 56in (140cm)',
  'poles_size.other': 'Inny',
  'poles_size.placeholder': 'Wybierz rozmiar',

  'config.labels.snowboardSize': 'Rozmiar',
  'config.labels.snowboardType': 'Typ',
  'config.labels.snowboardFlexRate': 'Elastyczność',
  'config.labels.withBindings': 'Z wiązaniami',
  'config.labels.gender': 'Płeć',
  'config.labels.bootsSize': 'Rozmiar butów',
  'config.labels.snowboardBootsFlexRate': 'Elastyczność butów',
  'config.labels.snowboardBindingsSize': 'Rozmiar wiązań',
  'config.labels.helmetSize': 'Rozmiar kasku',
  'config.labels.gogglesFrameSize': 'Rozmiar ramki',
  'config.labels.skiLenght': 'Długość',
  'config.labels.skiWaistWidth': 'Szerokość w talii',
  'config.labels.skiBootsType': 'Typ butów',
  'config.labels.skiBootsFlexRate': 'Elastyczność butów',
  'config.labels.skiBindingsSize': 'Rozmiar wiązań',
  'config.labels.color': 'Kolor',
  'config.labels.polesSize': 'Rozmiar kijów',

  'year.placeholder': 'Wybierz rok',
  'frame_size.placeholder': 'Wybierz rozmiar ramy',
  'brand.placeholder': 'Wybierz markę',
  'country.placeholder': 'Wybierz kraj',
  'front_travel.placeholder': 'Wybierz skok przedniego zawieszenia',
  'rear_travel.placeholder': 'Wybierz skok tylnego zawieszenia',

  'country.LT': 'Litwa',
  'country.LV': 'Łotwa',
  'country.EE': 'Estonia',
  'country.PL': 'Polska',
  'country.all': 'Wszystkie',

  'sub_categories.road': 'Szosowe',
  'sub_categories.track': 'Torowe',
  'sub_categories.aearo_triathlon_tt': 'Aero/Triathlon/TT',
  'sub_categories.single_gear': 'Jednoślad',
  'sub_categories.xc_cross_country': 'XC / Cross Country',
  'sub_categories.trail': 'Trasy górskie',
  'sub_categories.enduro': 'Enduro',
  'sub_categories.downhill': 'Downhill',
  'sub_categories.bmx': 'Bmx',
  'sub_categories.dirt_street': 'Rower Dirt/Street',
  'sub_categories.electric_city': 'Elektryczne miejskie',
  'sub_categories.electric_mountain': 'Elektryczne górskie',
  'sub_categories.electric_road': 'Elektryczne szosowe',
  'sub_categories.electric_other': 'Inne',
  'sub_categories.cargo': 'Transportowe',
  'sub_categories.tricycle': 'Trójkołowe',
  'sub_categories.tandem': 'Tandem',
  'sub_categories.balance': 'Balans',
  'sub_categories.unicycle': 'Monocykl',

  'shipping.no_shipping': 'Odbiór osobisty',
  'shipping.domestic_shipping': 'Wysyłka krajowa',
  'shipping.international_shipping': 'Wysyłka międzynarodowa',

  'frame_material.aluminum': 'Aluminiowy',
  'frame_material.carbon': 'Karbonowy',
  'frame_material.steel': 'Stalowy',
  'frame_material.other': 'Inny',

  'color.pink': 'Różowy',
  'color.green': 'Zielony',
  'color.orange': 'Pomarańczowy',
  'color.blue': 'Niebieski',
  'color.red': 'Czerwony',
  'color.white': 'Biały',
  'color.yellow': 'Żółty',
  'color.black': 'Czarny',
  'color.grey': 'Szary',
  'color.other': 'Inny',

  'wheel_size.16': '16″',
  'wheel_size.20': '20″',
  'wheel_size.28': '28″ (700c)',
  'wheel_size.26': '26”',
  'wheel_size.27.5': '27.5” (650b)',
  'wheel_size.29': '29”',
  'wheel_size.650B': '650B',
  'wheel_size.700C': '28” (700c)',
  'wheel_size.other': 'Inny',

  'suspension_type.air': 'Zawieszenie pneumatyczne',
  'suspension_type.coil': 'Zawieszenie sprężynowe',

  'suspension_mounting.trunnion': 'Trunion',
  'suspension_mounting.standard': 'Standardowe',

  'discipline.xc_marathon': 'XC / Maraton',
  'discipline.trail_all_mountain': 'Trasa / Góry',
  'discipline.enduro': 'Enduro',
  'discipline.downhill': 'Downhill',

  'hub_standard.15_110': '15 x 110mm (Boost)',
  'hub_standard.20_110': '20 x 110mm (Boost)',
  'hub_standard.9_100': '9 x 100mm',
  'hub_standard.15_100': '15 x 100mm',
  'hub_standard.20_100': '20 x 100mm',
  'hub_standard.9_135': '9 x 135mm',
  'hub_standard.10_135': '10 x 135mm',
  'hub_standard.15_142': '15 x 142mm',
  'hub_standard.15_150': '15 x 150mm',
  'hub_standard.10_170': '10 x 170mm',
  'hub_standard.12_190': '12 x 190mm',
  'hub_standard.12_197': '12 x 197mm',

  'axle_dimension.30': '30mm',
  'axle_dimension.29_9': '29.9mm',
  'axle_dimension.22_24': '22/24mm (DUB)',
  'axle_dimension.25': '25mm',
  'axle_dimension.24': '24mm',
  'axle_dimension.19': '19mm',
  'axle_dimension.square_taper': 'Square taper',
  'axle_dimension.octalink': 'Octalink',
  'axle_dimension.m30': 'M30',
  'axle_dimension.isis': 'ISIS',
  'axle_dimension.american': 'Amerykański',
  'axle_dimension.mid': 'Mid',

  'gears.1': '1',
  'gears.2': '2',
  'gears.3': '3',
  'gears.4': '4',
  'gears.5': '5',
  'gears.6': '6',
  'gears.7': '7',
  'gears.8': '8',
  'gears.9': '9',
  'gears.10': '10',
  'gears.11': '11',
  'gears.12': '12',
  'gears.13': '13',

  'placement.front': 'Przednie',
  'placement.rear': 'Tylne',
  'placement.set': 'Zestaw',

  'activation.mechanical': 'Mechaniczny',
  'activation.electric': 'Elektryczny',

  'chainring_mounting.3_arm': '3-ramienny',
  'chainring_mounting.4_arm': '4-ramienny',
  'chainring_mounting.5_arm': '5-ramienny',

  'bottom_bracket_mounting.bsa': 'BSA',
  'bottom_bracket_mounting.ita': 'ITA',
  'bottom_bracket_mounting.pressfit': 'Pressfit',

  'pedals.flat': 'Flat',
  'pedals.clipless': 'Bezstopniowe',
  'pedals.hybrid': 'Hybrydowe',

  'brakes_activation.hydraulic': 'Hydrauliczne',
  'brakes_activation.mechanical': 'Mechaniczne',

  'handlebar_type.mtb': 'MTB',
  'handlebar_type.dropbar': 'Kierownica sportowa',
  'handlebar_type.bmx': 'BMX',
  'handlebar_type.city_trekking': 'Miejska/Trekkingowa',

  'grips_type.lock_on': 'Z blokadą',
  'grips_type.push_on': 'Naciskane',
  'grips_type.bar_tape': 'Taśma kierownicy',

  'steerer_type.1_5': '1.5”',
  'steerer_type.1_8': '1-1/8”',
  'steerer_type.threaded': 'Nawrotny',
  'steerer_type.tapered': 'Rozsuwany',

  'dropper_diameter.27_2': '27.2mm',
  'dropper_diameter.28_6': '28.6mm',
  'dropper_diameter.30_9': '30.9mm',
  'dropper_diameter.31_6': '31.6mm',
  'dropper_diameter.31_8': '31.8mm',
  'dropper_diameter.34_9': '34.9mm',

  'dropper_activation.mechanical': 'Mechaniczny',
  'dropper_activation.electric': 'Elektryczny',
  'dropper_activation.hydraulic': 'Hydrauliczny',

  'rotor_mounting.6_hole': '6-otworowe',
  'rotor_mounting.centerlock': 'Centerlock',

  'rotor_placement.front': 'Przedni',
  'rotor_placement.rear': 'Tylny',
  'rotor_placement.set': 'Komplet',

  'wheels_placement.front': 'Przednie',
  'wheels_placement.rear': 'Tylne',
  'wheels_placement.set': 'Komplet',

  'hubs_placement.front': 'Przednie',
  'hubs_placement.rear': 'Tylny',
  'hubs_placement.set': 'Komplet',

  'receipt.yes': 'Tak',
  'receipt.no': 'Nie',

  'refurbished.yes': 'Tak',
  'refurbished.no': 'Nie',

  'condition.bad': 'Częściowy rower',
  'condition.partly_bad': 'Wymaga konserwacji',
  'condition.good': 'Dobry',
  'condition.perfect': 'Doskonały',
  'condition.brand_new': 'Nowy',

  'condition.bad.label': '✔ Nie nadaje się do jazdy\n✔ Wymaga przeglądu i serwisu\n✔ Konieczna wymiana części',
  'condition.partly_bad.label':
    '✔ Niektóre zadrapania na powierzchni\n✔ Wymaga dostosowania serwisowego\n✔ Niektóre części mogą wymagać wymiany',
  'condition.good.label':
    '✔ Niektóre zadrapania na powierzchni\n✔ Może wymagać dostosowania serwisowego\n✔ Nie wymaga wymiany części',
  'condition.perfect.label':
    '✔ Kilka lekkich zadrapań\n✔ Mechanicznie doskonały\n✔ Brak znaczących uszkodzeń komponentów',
  'condition.brand_new.label': '✔ Rower nigdy nie był używany',

  'condition.used_part': 'Używane',
  'condition.good_part': 'Dobre',
  'condition.new_part': 'Nowe',

  'condition.used_part.label': '✔ Wcześniej używane, z zauważalnymi wadami.',
  'condition.good_part.label': '✔ Mało używane, w świetnym stanie.',
  'condition.new_part.label': '✔ Stan idealny, nigdy nie używane.',

  'frame_size.xxl': 'XXL',
  'frame_size.xl': 'XL',
  'frame_size.l_xl': 'L/XL',
  'frame_size.l': 'L',
  'frame_size.m_l': 'M/L',
  'frame_size.m': 'M',
  'frame_size.s_m': 'S/M',
  'frame_size.s': 'S',
  'frame_size.xs': 'XS',
  'frame_size.xxs': 'XSS',

  'front_travel.60': '60',
  'front_travel.70': '70',
  'front_travel.80': '80',
  'front_travel.90': '90',
  'front_travel.100': '100',
  'front_travel.110': '110',
  'front_travel.120': '120',
  'front_travel.130': '130',
  'front_travel.140': '140',
  'front_travel.150': '150',
  'front_travel.160': '160',
  'front_travel.170': '170',
  'front_travel.180': '180',
  'front_travel.190': '190',
  'front_travel.200': '200',
  'front_travel.210': '210',
  'front_travel.220': '220',
  'front_travel.230': '230',
  'rear_travel.60': '60',
  'rear_travel.70': '70',
  'rear_travel.80': '80',
  'rear_travel.90': '90',
  'rear_travel.100': '100',
  'rear_travel.110': '110',
  'rear_travel.120': '120',
  'rear_travel.130': '130',
  'rear_travel.140': '140',
  'rear_travel.150': '150',
  'rear_travel.160': '160',
  'rear_travel.170': '170',
  'rear_travel.180': '180',
  'rear_travel.190': '190',
  'rear_travel.200': '200',
  'rear_travel.210': '210',
  'rear_travel.220': '220',
  'rear_travel.230': '230',

  'top_bar.search_form.placeholder': 'Szukaj',
  'top_bar.generic_error_message': 'Coś poszło nie tak. Proszę spróbuj ponownie.',
  'top_bar.logo_icon_label': 'Przejdź na stronę główną',
  'top_bar.menu_icon_label': 'Otwórz menu',
  'top_bar.under_menu_bar_text':
    '<span style="font-size: inherit; font-weight: 600; text-decoration: underline; color: inherit">Sprzedaj swój sprzęt</span> i wygraj voucher o wartości <span style="font-size: inherit; font-weight: 600; color: inherit">1000 zł</span>',

  'top_bar.log_in': 'Zaloguj się | Zarejestruj się',
  'top_bar.log_out': 'Wyloguj się',
  'top_bar.sell': 'Sprzedaj',
  'top_bar.buy': 'Kup',
  'top_bar.inbox_label': 'Skrzynka odbiorcza',
  'top_bar.listings_label': 'Twoje oferty',
  'top_bar.profile_settings_label': 'Ustawienia profilu',
  'top_bar.account_settings_label': 'Ustawienia konta',
  'top_bar.profile': 'Profil',
  'top_bar.purchases': 'Zakupy',
  'top_bar.sales': 'Sprzedaż',

  'top_bar.mobile_menu.buy': 'Kup',
  'top_bar.mobile_menu.about_page': 'O nas',
  'top_bar.mobile_menu.how_it_works_page': 'Jak to działa',
  'top_bar.mobile_menu.show_profile': 'Pokaż profil',
  'top_bar.mobile_menu.account_label': 'Konto',
  'top_bar.mobile_menu.services_label': 'Usługi',
  'top_bar.mobile_menu.favorite_listings': 'Ulubione oferty',
  'top_bar.mobile_menu.explore': 'Odkryj',
  'top_bar.mobile_menu.all_categories': 'Wszystkie kategorie',
  'top_bar.mobile_menu.terms_of_service': 'Warunki korzystania z usługi',
  'top_bar.mobile_menu.privacy_policy': 'Polityka prywatności',

  'top_bar.desktop.logo_label': 'Gearro',

  'authentication_page.or': 'lub',
  'authentication_page.facebook_login': 'Kontynuuj z Facebookiem',
  'authentication_page.google_login': 'Kontynuuj z Google',
  'authentication_page.email_login': 'Kontynuuj z adresem e-mail',
  'authentication_page.terms_and_conditions_accept_label':
    'Wybierając Zgadzam się i kontynuuj, akceptuję {termsLink} Gearro oraz {privacyPolicyLink}',
  'authentication_page.terms_and_conditions_label': 'Warunki korzystania z usługi',
  'authentication_page.privacy_policy_label': 'Polityka prywatności',

  'log_in.email_label': 'Adres e-mail',
  'log_in.email_placeholder': 'Adres e-mail',
  'log_in.email_required': 'Proszę podać adres e-mail',
  'log_in.email_invalid': 'Podany adres e-mail jest nieprawidłowy',
  'log_in.password_label': 'Hasło',
  'log_in.password_placeholder': 'Hasło',
  'log_in.password_required': 'Proszę podać hasło',
  'log_in.forgot_password': 'Zapomniałeś hasła?',
  'log_in.log_in_label': 'Zaloguj się',
  'log_in.sign_up_prompt': 'Nie masz konta? ',
  'log_in.sign_up_label': 'Zarejestruj się',
  'log_in.failed': 'Podany e-mail i hasło nie pasują do naszych danych. Proszę sprawdź i spróbuj ponownie.',

  'sign_up.email_label': 'Adres e-mail',
  'sign_up.email_placeholder': 'Adres e-mail',
  'sign_up.email_required': 'Proszę podać adres e-mail',
  'sign_up.email_invalid': 'Podany adres e-mail jest nieprawidłowy',
  'sign_up.password_label': 'Hasło',
  'sign_up.password_placeholder': 'Hasło',
  'sign_up.password_required': 'Proszę podać hasło',
  'sign_up.password_too_short': 'Hasło powinno mieć co najmniej {minLength} znaków',
  'sign_up.password_too_long': 'Hasło powinno mieć co najwyżej {maxLength} znaków',
  'sign_up.first_name_label': 'Imię',
  'sign_up.first_name_placeholder': 'Imię',
  'sign_up.first_name_required': 'Proszę podać imię',
  'sign_up.last_name_label': 'Nazwisko',
  'sign_up.last_name_placeholder': 'Nazwisko',
  'sign_up.last_name_required': 'Proszę podać nazwisko',
  'sign_up.user_name_label': 'Nazwa użytkownika',
  'sign_up.user_name_placeholder': 'Nazwa użytkownika',
  'sign_up.user_name_required': 'Proszę podać nazwę użytkownika',
  'sign_up.sign_up_label': 'Zarejestruj się',
  'sign_up.business.sign_up_label': 'Utwórz konto partnerskie',
  'sign_up.log_in_prompt': 'Masz już konto? ',
  'sign_up.log_in_label': 'Zaloguj się',
  'sign_up.failed':
    'Rejestracja nie powiodła się. Upewnij się, że wszystkie wprowadzone informacje są poprawne i spróbuj ponownie.',
  'sign_up.email_already_taken': 'Adres e-mail jest już zajęty',

  'confirm_sign_up.email_label': 'Email',
  'confirm_sign_up.email_placeholder': 'Email',
  'confirm_sign_up.email_required': 'Proszę podać adres e-mail',
  'confirm_sign_up.email_invalid': 'Podany adres e-mail jest nieprawidłowy',
  'confirm_sign_up.first_name_label': 'Imię',
  'confirm_sign_up.first_name_placeholder': 'Imię',
  'confirm_sign_up.first_name_required': 'Proszę podać imię',
  'confirm_sign_up.last_name_label': 'Nazwisko',
  'confirm_sign_up.last_name_placeholder': 'Nazwisko',
  'confirm_sign_up.last_name_required': 'Proszę podać nazwisko',
  'confirm_sign_up.user_name_label': 'Nazwa użytkownika',
  'confirm_sign_up.user_name_placeholder': 'Nazwa użytkownika',
  'confirm_sign_up.user_name_required': 'Proszę podać nazwę użytkownika',
  'confirm_sign_up.confirm_and_continue_label': 'Zgadzam się i kontynuuj',

  'terms_of_service_page.schema_title': 'Warunki korzystania z usługi | {siteTitle}',

  'avatar.banned_user_label': 'Zbanowany użytkownik',
  'avatar.deleted_user_label': 'Usunięty użytkownik',

  'footer.copyright': '© Gearro',
  'footer.product': 'Produkt',
  'footer.buy_bike': 'Kup rower',
  'footer.sell_bike': 'Sprzedaj rower',
  'footer.company': 'Firma',
  'footer.about_us': 'O nas',
  'footer.support': 'Wsparcie',
  'footer.faq': 'FAQ',
  'footer.contact_us': 'info@gearro.com',
  'footer.business_join': 'Dołącz jako sprzedawca komercyjny',
  'footer.legal': 'Prawne',
  'footer.terms_conditions': 'Warunki korzystania z usługi',
  'footer.privacy_policy': 'Polityka prywatności',
  'footer.instagram_link': 'Przejdź do strony Instagram',
  'footer.facebook_link': 'Przejdź do strony Facebook',
  'footer.linkedin_link': 'Przejdź do strony LinkedIn',
  'footer.content_rights': '© Gearro. Wszelkie prawa zastrzeżone.',

  'modal.close': 'Zamknij modal',

  'filter_form.cancel': 'Anuluj',
  'filter_form.clear': 'Wyczyść',
  'filter_form.submit': 'Zastosuj',

  'actions.cancel': 'Anuluj',
  'actions.clear': 'Wyczyść',
  'actions.submit': 'Zastosuj',
  'actions.publish': 'Opublikuj',
  'actions.continue': 'Kontynuuj',
  'actions.back': 'Wstecz',
  'actions.add': 'Dodaj',
  'actions.edit': 'Edytuj',
  'actions.pay': 'Zapłać',
  'actions.got_it': 'Rozumiem',
  'actions.return_home': 'Strona główna',

  'item_status.sold': 'Sprzedany',
  'item_status.refurbished': 'Odnowiony',

  'order_actions.accept_order': 'Akceptuj',
  'order_actions.reject_order': 'Odrzuć',
  'order_actions.get_shipping_label': 'Odbierz etykietę wysyłki',
  'order_actions.download_shipping_label': 'Pobierz etykietę wysyłki',
  'order_actions.request_courier': 'Poproś o kuriera',
  'order_actions.track_order': 'Śledź zamówienie',
  'order_actions.mark_order_as_shipped': 'Oznacz jako wysłane',
  'order_actions.mark_order_as_delivered': 'Oznacz jako dostarczone',
  'order_actions.packing_instructions': 'Instrukcje pakowania',
  'order_actions.issue_notice': 'Mam problem',
  'order_actions.mark_as_ok': 'Wszystko jest OK',

  'search_page.title.all': 'Kup lub sprzedaj używany sprzęt sportowy',
  'search_page.subtitle.all':
    'Kupuj rowery górskie, szosowe, gravelowe i elektryczne lub odkryj używane narty i snowboardy | Kupuj bezpiecznie dzięki ochronie kupujących, wysyłce i bezpiecznym płatnościom',
  'search_page.title.parts': 'Części rowerowe: Kupuj i sprzedaj',
  'search_page.subtitle.parts':
    'Odkryj używane i nowe części do rowerów szosowych, górskich, szutrowych i elektrycznych | Najlepsze marki z bezpiecznymi płatnościami i wysyłką',
  'search_page.title.road': 'Rowery szosowe na sprzedaż - używane i nowe',
  'search_page.subtitle.road':
    'Odkryj używane rowery szosowe. Wybieraj spośród popularnych marek, takich jak Cube, Trek, Canyon, Scott i wiele innych. | Z ochroną kupującego, wysyłką i bezpiecznymi płatnościami',
  'search_page.title.mountain': 'Rowery górskie MTB, enduro i downhill',
  'search_page.subtitle.mountain':
    'Odkryj używane rowery MTB hardtail, enduro, trail i downhill. Wybieraj spośród marek takich jak Cube, Trek, Canyon, Scott i wielu innych. Z ochroną kupującego, wysyłką i bezpiecznymi płatnościami',
  'search_page.title.gravel': 'Rowery gravel - używane i nowe',
  'search_page.subtitle.gravel':
    'Odkryj używane rowery szutrowe. Wybieraj spośród popularnych marek, takich jak Cube, Trek, Canyon, Scott i wielu innych. Kupuj bezpiecznie z ochroną kupującego, wysyłką i bezpiecznymi płatnościami',
  'search_page.title.city': 'Rowery miejskie na sprzedaż - używane i nowe',
  'search_page.subtitle.city':
    'Odkryj używane rowery miejskie. Wybieraj spośród popularnych marek, takich jak Cube, Trek, Canyon, Scott i wiele innych. | Z ochroną kupującego, wysyłką i bezpiecznymi płatnościami',
  'search_page.title.electric': 'Rowery elektryczne - używane i nowe na sprzedaż',
  'search_page.subtitle.electric':
    'Odkryj używane rowery elektryczne. Wybieraj spośród popularnych marek, takich jak Cube, Trek, Canyon, Scott i wiele innych. | Z ochroną kupującego, wysyłką i bezpiecznymi płatnościami',
  'search_page.title.bmx_dirt': 'BMX i Dirt - używane rowery na sprzedaż',
  'search_page.subtitle.bmx_dirt':
    'Odkryj używane rowery BMX i Dirt. Wybieraj spośród popularnych marek, takich jak Cube, Trek, Canyon, Scott i wiele innych. | Z ochroną kupującego, wysyłką i bezpiecznymi płatnościami',
  'search_page.title.winter': 'Narty i snowboard na sprzedaż - używane i nowe',
  'search_page.subtitle.winter':
    'Odkryj używany sprzęt narty i snowboard | najlepsze marki z bezpiecznymi płatnościami i wysyłką',
  'search_page.title.skis': 'Narty na sprzedaż - używane i nowe',
  'search_page.subtitle.skis':
    'odkryj używany sprzęt narty. Wybieraj spośród najlepszych marek, takich jak Salomon, Atomic, Rossignol i więcej | Kupuj bezpiecznie z bezpiecznymi płatnościami i wysyłką',
  'search_page.title.snowboard': 'Snowboard na sprzedaż - używane i nowe',
  'search_page.subtitle.snowboard':
    'Odkryj używane deski snowboard, buty i wiązania. Wybieraj spośród najlepszych marek, takich jak Burton, Ride i Lib Tech | Kupuj bezpiecznie dzięki bezpiecznym płatnościom i wysyłce',
  'search_page.title.accessories': 'Akcesoria do sportów zimowych',
  'search_page.subtitle.accessories':
    'Odkryj używane i nowe rękawice, gogle, kaski i nie tylko |Sklepuj bezpiecznie Bezpieczne płatności i wysyłka',

  'search_page.price_filter.cancel': 'Anuluj',
  'search_page.price_filter.clear': 'Wyczyść',
  'search_page.price_filter.plain_label': 'Cena',
  'search_page.price_filter.label': 'Zakres cenowy:',
  'search_page.price_filter.submit': 'Zastosuj',
  'search_page.price_filter.to': 'do',
  'search_page.header.foundResults': '{count, number} {count, plural, one {wynik} other {wyników}}',
  'search_page.header.loadingResults': 'Ładowanie wyników wyszukiwania',
  'search_page.sortBy': 'Sortuj według',
  'search_page.filter_plain.selected': '• {count}',
  'search_page.multiple_filter.selected': '{labelText} • {count}',
  'search_page.reset_filters': 'Zresetuj filtry',
  'search_page.no_results': 'Nie znaleziono ofert odpowiadających Twoim kryteriom wyszukiwania.',
  'search_page.empty_state.message':
    'Niestety nie udało się znaleźć żadnych ofert pasujących do Twoich kryteriów wyszukiwania',
  'search_page.price_filter.selected': '{minPrice} - {maxPrice}',
  'search_page.mobile_filters.label': 'Filtry',
  'search_page.mobile_filters.reset': 'Resetuj',
  'search_page.range_filter.label.selected': '{minValue} - {maxValue}',

  'search_page.mobile.show_listings': 'Pokaż',
  'search_page.upsell_card.title': 'Kup z pewnością',
  'search_page.upsell_card.description':
    'Każde zamówienie na Gearro jest zabezpieczone bezpiecznymi płatnościami, zaufanymi sprzedawcami i łatwymi zwrotami.',

  'profile_page.label': 'Profil',
  'profile_page.listing.view': 'Zobacz',
  'profile_page.edit_profile': 'Edytuj profil',
  'profile_page.joined': 'Dołączono {createdAt}',
  'profile_page.bikes.seller': 'Twoje oferty',
  'profile_page.bikes.buyer': 'Oferty sprzedaży',
  'profile_page.data_load_failed': 'Ups, coś poszło nie tak. Spróbuj ponownie.',
  'profile_page.bio_label': 'O mnie',
  'profile_page.edit_listing': 'Edytuj',
  'profile_page.delete_listing': 'Usuń',
  'profile_page.delete_modal.title': 'Usuń ofertę',
  'profile_page.delete_modal.subtitle': 'Jak sprzedałeś swoją rzecz?',
  'profile_page.delete_modal.reason.gearro': 'Znalazłem kupca na Gearro',
  'profile_page.delete_modal.reason.outside': 'Znalazłem kupca poza platformą',
  'profile_page.delete_modal.reason.other': 'Inne',
  'profile_page.delete_modal.delete_action': 'Usuń',
  'profile_page.delete_modal.cancel_action': 'Anuluj',
  'profile_page.no_listings.owner_label': 'Zmień swój stary rower w gotówkę',

  'purchases_page.label': 'Zakupy',
  'purchases_page.schema_title': 'Zakupy | {siteTitle}',
  'purchases_page.placeholder': 'Rower, który kupiłeś, pojawi się tutaj.',
  'purchases_page.error_loading_purchases': 'Nie udało się załadować zakupów, spróbuj ponownie później.',
  'purchases_page.action_button': 'Szukaj rowerów',

  'sales_page.label': 'Sprzedaż',
  'sales_page.schema_title': 'Sprzedaż | {siteTitle}',
  'sales_page.placeholder': 'Rower, który sprzedałeś, pojawi się tutaj.',
  'sales_page.error_loading_sales': 'Nie udało się załadować sprzedaży, spróbuj ponownie później.',
  'sales_page.action_button': 'Sprzedaj teraz',

  'listing_page.sold_by': '{name}',
  'listing_page.loading': 'Ładowanie oferty',
  'listing_page.order_panel': 'Sprzedane przez {name}',
  'listing_page.buy_now': 'Kup teraz',
  'listing_page.contact_seller': 'Skontaktuj się z sprzedawcą',
  'listing_page.add_to_favorites': 'Dodaj do ulubionych',
  'listing_page.remove_from_favorites': 'Usuń z ulubionych',
  'listing_page.description.about_bike_label': 'O rowerze',
  'listing_page.details.label': 'Szczegóły',
  'listing_page.image_gallery.view_photos': 'Zobacz zdjęcia ({count})',
  'listing_page.image_gallery.image_alt_text': 'Zdjęcie oferty {index}/{count}',
  'listing_page.image_gallery.image_thumb_alt_text': 'Zdjęcie oferty {index}/{count}',
  'listing_page.basic_information.label': 'Podstawowe informacje',
  'listing_page.location.label': 'Lokalizacja',
  'listing_page.seller_details.label': 'Informacje o produkcie',
  'listing_page.offered_by.label': 'Oferowane przez',
  'listing_page.offered_by.phone_number': 'Numer telefonu',

  'listing_page.shopping.label': 'Kupowanie na Gearro',
  'listing_page.shopping.buy_sell.title': 'Kupuj i sprzedawaj z rowerzystami z całego świata.',
  'listing_page.shopping.buy_sell.description':
    'Dołącz do Gearro, jednego miejsca do kupowania i sprzedawania wysokiej jakości sprzętu z rabatem do 70%, oferowanego przez innych rowerzystów.',
  'listing_page.shopping.protection.title': 'Kupuj bezpie nie z naszą gwarancją dla kupujących.',
  'listing_page.shopping.protection.description':
    'Jeśli Twoje zamówienie znacząco nie odpowiada opisowi ogłoszenia, skontaktuj się z nami w ciągu 48 godzin. Poinformuj nas o problemie, a my przeanalizujemy Twoje zgłoszenie. Jeśli jest ważne, zwrócimy Ci szybko pieniądze.',
  'listing_page.shopping.shipping.title': 'Szybka wysyłka i śledzenie.',
  'listing_page.shopping.shipping.description':
    'Większość zamówień wysyłamy za pośrednictwem DPD Express (1-3 dni robocze po wysłaniu przez sprzedawcę). Sprzedawcy otrzymują etykiety wstępnie opłacone, a Ty otrzymujesz śledzenie do dostawy.',
  'listing_page.shopping.save.title': 'Oszczędzaj pieniądze. Ratuj planetę.',
  'listing_page.shopping.save.description':
    'Oszczędzanie na jakości używanego sprzętu pozwala na więcej sprzętu na boisku i pomaga środowisku.',

  'listing_page.buyer_protection_info.label': 'Ochrona kupujących Gearro',
  'listing_page.buyer_protection_info.description': 'Kupuj pewnie dzięki programowi ochrony kupujących Gearro.',
  'listing_page.buyer_protection_info.proposition.shipping': 'Szybka i ubezpieczona wysyłka',
  'listing_page.buyer_protection_info.proposition.secure_payments': 'Bezpieczne płatności',
  'listing_page.buyer_protection_info.proposition.verified_sellers': 'Zweryfikowani sprzedawcy',
  'listing_page.buyer_protection_info.proposition.returns': 'Bezpłatne zwroty',

  'listing_page.refurbished_program.label': 'Program odnowiony Gearro',
  'listing_page.refurbished_program.description':
    'Profesjonalnie przywrócone przez nasz zaufany partner w restauracji.',
  'listing_page.refurbished_program.proposition.functional': 'Funkcjonalne jak nowe',
  'listing_page.refurbished_program.proposition.warranty': '12 miesięcy gwarancji',
  'listing_page.refurbished_program.proposition.returns': '14 dniowy zwrot',

  'listing_page.similar_items_label': 'Podobne przedmioty',
  'listing_page.view_more': 'Zobacz więcej',
  'listing_page.more_details.label': 'Więcej szczegółów',
  'listing_page.pay_at_gearro':
    'Aby chronić swoje płatności, nigdy nie komunikuj się ani nie przelewaj pieniędzy poza witryną Gearro.',

  'listing_order_page.schema_title': 'Kup {title} | {siteTitle}',
  'listing_order_page.summary': 'Podsumowanie',
  'listing_order_page.shipping': 'Dostawa',
  'listing_order_page.payment': 'Płatność',
  'listing_order_page.listing_summary.info_section_message':
    'Kupuj z pewnością z programem ochrony zakupów Gearro. Otrzymasz pełny zwrot, jeśli zamówienie nie odpowiada opisowi.',
  'listing_order_page.listing_summary.your_purchase': 'Twoje zamówienie',
  'listing_order_page.listing_summary.size': 'Rozmiar',
  'listing_order_page.listing_summary.price_details': 'Szczegóły ceny',
  'listing_order_page.listing_summary.bike_price': 'Cena ofertowa',
  'listing_order_page.listing_summary.purchase_protection': 'Ochrona zakupów',
  'listing_order_page.listing_summary.shipping': 'Dostawa',
  'listing_order_page.listing_summary.total': 'Razem ({currency})',
  'listing_order_page.shipping.delivery_provider': 'DPD Express Shipping',
  'listing_order_page.shipping.delivery_estimate': 'Przewidywana dostawa: {from} - {to}',
  'listing_order_page.shipping.delivery_info': 'Informacje o dostawie',
  'listing_order_page.shipping.add_delivery_info': 'Dodaj informacje o dostawie',
  'listing_order_page.shipping.save_address': 'Zapisz adres',
  'listing_order_page.shipping.save_address_error': 'Nie udało się zapisać adresu, spróbuj ponownie później.',
  'listing_order_page.shipping.missing_address_error': 'Twoje dane dostawy są wymagane do zakończenia zamówienia.',
  'listing_order_page.shipping.delivery_info_disclaimer':
    'Twoje dane kontaktowe mogą być udostępnione dostawcy w celu zakończenia przesyłki.',
  'listing_order_page.delivery_info.first_name': 'Imię',
  'listing_order_page.delivery_info.first_name_placeholder': 'Imię',
  'listing_order_page.delivery_info.first_name_required': 'Proszę podać imię',
  'listing_order_page.delivery_info.last_name': 'Nazwisko',
  'listing_order_page.delivery_info.last_name_placeholder': 'Nazwisko',
  'listing_order_page.delivery_info.last_name_required': 'Proszę podać nazwisko',
  'listing_order_page.delivery_info.country': 'Kraj',
  'listing_order_page.delivery_info.country_placeholder': 'Kraj',
  'listing_order_page.delivery_info.address_line_1': 'Adres linia 1',
  'listing_order_page.delivery_info.address_line_1_placeholder': 'Adres linia 1',
  'listing_order_page.delivery_info.address_line_1_required': 'Proszę podać adres linia 1',
  'listing_order_page.delivery_info.address_line_2': 'Adres linia 2',
  'listing_order_page.delivery_info.address_line_2_placeholder': 'Adres linia 2',
  'listing_order_page.delivery_info.postal_code': 'Kod pocztowy',
  'listing_order_page.delivery_info.postal_code_placeholder': 'Kod pocztowy',
  'listing_order_page.delivery_info.postal_code_required': 'Proszę podać kod pocztowy',
  'listing_order_page.delivery_info.city': 'Miasto',
  'listing_order_page.delivery_info.city_placeholder': 'Miasto',
  'listing_order_page.delivery_info.city_required': 'Proszę podać miasto',
  'listing_order_page.delivery_info.phone_number': 'Numer telefonu',
  'listing_order_page.delivery_info.phone_number_placeholder': 'Numer telefonu',
  'listing_order_page.delivery_info.phone_number_required': 'Proszę podać numer telefonu',
  'listing_order_page.payment.or_card': 'Lub zapłać kartą',
  'listing_order_page.payment.mobile_pay_inactive': 'Płatność mobilna jest niedostępna',

  'listing_order_processing_page.schema_title': 'Przetwarzanie zamówienia | {siteTitle}',
  'listing_order_processing_page.title': 'Zakupy',
  'listing_order_processing_page.processing': 'Przetwarzanie zamówienia, proszę czekać...',
  'listing_order_processing_page.error':
    'Przetwarzanie zamówienia nie powiodło się, sprawdź stan zamówienia w swoim profilu lub skontaktuj się z pomocą techniczną.',

  'listing_order_details_page.schema_title': 'Szczegóły zamówienia | {siteTitle}',
  'listing_order_details_page.title': 'Zakupy',
  'listing_order_details_page.order_placed': 'Zamówienie złożone',
  'listing_order_details_page.whats_next': 'Co dalej?',
  'listing_order_details_page.stage_1.title': 'Prośba o zamówienie',
  'listing_order_details_page.stage_1.description':
    'Oczekiwanie na zatwierdzenie sprzedawcy. Jeśli nie otrzymasz potwierdzenia w ciągu 24 godzin, transakcja zostanie automatycznie anulowana.',
  'listing_order_details_page.stage_2.title': 'Pakowanie i wysyłka',
  'listing_order_details_page.stage_2.description':
    'Sprzedawca ma 5-dniowe okno na spakowanie i wysłanie zamówienia. Otrzymasz śledzenie przesyłki, gdy będzie w drodze.',
  'listing_order_details_page.stage_3.title': 'Potwierdzenie zamówienia',
  'listing_order_details_page.stage_3.description':
    'Masz 48-godzinne okno po dostarczeniu przedmiotu do potwierdzenia, że odpowiada opisowi.',

  'listing_order_info_page.schema_title': 'Informacje o zamówieniu | {siteTitle}',

  'listing_order_status_page.schema_title': 'Status zamówienia | {siteTitle}',
  'listing_order_status_page.title': 'Status zamówienia',
  'listing_order_status_page.message_seller': 'Wiadomość do sprzedawcy',
  'listing_order_status_page.message_buyer': 'Wiadomość do kupującego',
  'listing_order_status_page.order_status': 'Status zamówienia',
  'listing_order_status_page.delivery_address': 'Adres dostawy',
  'listing_order_status_page.offered_by': 'Oferowane przez',
  'listing_order_status_page.bought_by': 'Kupione przez',
  'listing_order_status_page.payment_info': 'Informacje o płatności',
  'listing_order_status_page.price_details': 'Szczegóły ceny',
  'listing_order_status_page.bike_price': 'Cena ofertowa',
  'listing_order_status_page.purchase_protection': 'Ochrona zakupów',
  'listing_order_status_page.shipping': 'Dostawa',
  'listing_order_status_page.total': 'Razem ({currency})',
  'listing_order_status_page.return_policy': 'Polityka zwrotów',
  'listing_order_status_page.return_policy_info': `
    Jeśli Twoje zamówienie nie znacząco nie odpowiada opisowi ogłoszenia, skontaktuj się z nami w ciągu 48 godzin. Poinformuj nas o problemie, a my przeanalizujemy Twoje zgłoszenie. Jeśli jest ważne, zwrócimy Ci szybko pieniądze.
    `,
  'listing_order_status_page.get_help': 'Pomoc',
  'listing_order_status_page.get_help_info':
    'Jeśli potrzebujesz pomocy, skontaktuj się z nami pod adresem info@gearro.com',
  'listing_order_status_page.reject_order_modal.title': 'Odrzuć pro śbę o zamówienie?',
  'listing_order_status_page.reject_order_modal.description': 'Tej akcji nie można cofnąć.',
  'listing_order_status_page.reject_order_modal.reject_button': 'Tak, odrzuć',
  'listing_order_status_page.reject_order_modal.cancel_button': 'Anuluj',
  'listing_order_status_page.error':
    'Nie udało się załadować statusu zamówienia, sprawdź URL lub spróbuj ponownie później.',
  'listing_order_status_page.mark_as_ok_modal.title': 'Potwierdź, że wszystko jest OK i zakończ zamówienie?',
  'listing_order_status_page.mark_as_ok_modal.description':
    'Po potwierdzeniu, że wszystko jest OK, płatność zostanie przekazana sprzedawcy, a Ty nie będziesz mógł zgłosić żadnych problemów.',
  'listing_order_status_page.mark_as_ok_modal.confirm_button': 'Tak, wszystko jest OK',
  'listing_order_status_page.mark_as_ok_modal.cancel_button': 'Anuluj',

  'listing_order_seller_address_page.schema_title': 'Odbierz etykietę wysyłki | {siteTitle}',
  'listing_order_seller_address_page.title': 'Odbierz etykietę wysyłki',
  'listing_order_seller_address_page.return_page': 'Status zamówienia',
  'listing_order_seller_address_page.shipping.delivery_provider': 'DPD Express Shipping',
  'listing_order_seller_address_page.shipping.provider_info': 'Usługa drzwi do drzwi DPD',
  'listing_order_seller_address_page.shipping.pick_up_info': 'Informacje o odbiorze',
  'listing_order_seller_address_page.shipping.add_delivery_info': 'Dodaj informacje o odbiorze',
  'listing_order_seller_address_page.shipping.save_address_error':
    'Nie udało się zapisać adresu, spróbuj ponownie później.',
  'listing_order_seller_address_page.shipping.get_label_btn': 'Odbierz etykietę wysyłki',

  'listing_order_courier_request_page.schema_title': 'Zamów kuriera | {siteTitle}',
  'listing_order_courier_request_page.title': 'Zamów kuriera',
  'listing_order_courier_request_page.return_page': 'Status zamówienia',
  'listing_order_courier_request_page.form_title': 'Zamów kuriera',
  'listing_order_courier_request_page.form_subtitle': 'Zamów kuriera, który odbierze Twój rower z Twojego adresu.',
  'listing_order_courier_request_page.form_date_label': 'Data odbioru',
  'listing_order_courier_request_page.form_date_placeholder': 'Wybierz datę odbioru',
  'listing_order_courier_request_page.form_date_required': 'Proszę wybrać datę odbioru',
  'listing_order_courier_request_page.form_time_label': 'Godzina odbioru',
  'listing_order_courier_request_page.form_time_placeholder': 'Wybierz godzinę odbioru',
  'listing_order_courier_request_page.form_time_required': 'Proszę wybrać godzinę odbioru',
  'listing_order_courier_request_page.request_pick_up_btn': 'Zamów kuriera',

  'orders_page.schema_title': 'Zamówienia | {siteTitle}',
  'orders_page.purchases': 'Zakupy',
  'orders_page.sales': 'Sprzedaż',

  'listing_stage_info_page.schema_title': 'Informacje o ofercie | {siteTitle}',
  'listing_stage_info_page.uploaded': 'Wgrane',
  'listing_stage_info_page.whats_next': 'Co dalej?',
  'listing_stage_info_page.stage_1.title': 'Czekaj na prośbę o zamówienie',
  'listing_stage_info_page.stage_1.description':
    'Po otrzymaniu prośby od kupującego będziesz miał 24-godzinne okno na jej zaakceptowanie.',
  'listing_stage_info_page.stage_2.title': 'Pakowanie i wysyłka',
  'listing_stage_info_page.stage_2.description': `Po zaakceptowaniu prośby kupującego będziesz miał 5 dni na spakowanie i wysłanie zamówienia. Instrukcje dotyczące przesyłki zostaną przesłane.`,
  'listing_stage_info_page.stage_3.title': 'Wypłata',
  'listing_stage_info_page.stage_3.description':
    'Płatność zostanie automatycznie przetworzona 48 godzin po dostarczeniu przedmiotu pomyślnie kupującemu.',

  'packaging_instructions_page.schema_title': 'Instrukcje pakowania | {siteTitle}',
  'packaging_instructions_page.title': 'Pakowanie przedmiotu',
  'packaging_instructions_page.return_page': 'Status zamówienia',

  'new_listing_page.page_title': 'Co będziesz sprzedawać?',
  'new_listing_page.type.bike': 'Rower',
  'new_listing_page.description.bike': 'Pełny rower, w dowolnym stanie.',
  'new_listing_page.type.part': 'Komponenty rowerowe',
  'new_listing_page.description.part': 'Rama, widelec, koła lub inne części.',

  'edit_listing_page.location.label': 'Lokalizacja',
  'edit_listing_page.details.label': 'Szczegóły',
  'edit_listing_page.categories.label': 'Typ',
  'edit_listing_page.general_details.label': 'Zdjęcia i szczegóły',
  'edit_listing_page.show_listing_failed': 'Pobieranie danych oferty nie powiodło się',
  'edit_listing_page.update_failed': 'Aktualizacja oferty nie powiodła się. Spróbuj ponownie.',
  'edit_listing_page.upload_failed': 'Publikowanie oferty nie powiodło się',
  'edit_listing_page.max_length': 'Musi mieć maksymalnie {maxLength} znaków',

  'edit_listing_page.location.tab_title': 'Sprzedaj swój nowy lub używany rower',
  'edit_listing_page.location.address': 'Gdzie znajduje się Twój rower?',
  'edit_listing_page.location.address_hint': 'To nie będzie widoczne dla innych członków.',
  'edit_listing_page.location.address_not_recognized':
    'Nie rozpoznaliśmy tej lokalizacji. Proszę wybrać inną lokalizację.',
  'edit_listing_page.location.address_placeholder': 'Wprowadź adres',
  'edit_listing_page.location.address_required': 'Musisz podać lokalizację',
  'edit_listing_page.location.shipping_label': 'Czy wysyłasz rower?',
  'edit_listing_page.location.shipping_required': 'Proszę wybrać metodę wysyłki',

  'edit_listing_page.categories.tab_title': 'Który opisuje Twój rower najlepiej?',
  'edit_listing_page.categories.type_select': 'Wybierz typ swojego roweru',
  'edit_listing_page.categories.sub_category_required': 'Proszę wybrać podkategorie roweru',
  'edit_listing_page.categories.category_required': 'Proszę wybrać kategorię roweru',

  'edit_listing_page.details.tab_title': 'Ogólne informacje o Twoim rowerze',
  'edit_listing_page.details.frame_details': 'Szczegóły ramy',
  'edit_listing_page.details.brand': 'Marka',
  'edit_listing_page.details.brand_placeholder': 'Wybierz markę roweru',
  'edit_listing_page.details.brand_required': 'Proszę wybrać markę',
  'edit_listing_page.details.model': 'Model',
  'edit_listing_page.details.model_placeholder': 'Wprowadź model ramy roweru',
  'edit_listing_page.details.model_required': 'Proszę wprowadzić model ramy roweru',
  'edit_listing_page.details.year': 'Rok',
  'edit_listing_page.details.year_placeholder': 'Wybierz rok',
  'edit_listing_page.details.year_required': 'Proszę wybrać rok',
  'edit_listing_page.details.frame_size': 'Rozmiar ramy',
  'edit_listing_page.details.frame_size_placeholder': 'Wybierz rozmiar ramy',
  'edit_listing_page.details.frame_size_required': 'Proszę wybrać rozmiar ramy',
  'edit_listing_page.details.frame_material': 'Materiał',
  'edit_listing_page.details.frame_material_required': 'Proszę wybrać materiał',
  'edit_listing_page.details.frame_color': 'Kolor',
  'edit_listing_page.details.frame_color_required': 'Proszę wybrać kolor',
  'edit_listing_page.details.wheel_size': 'Rozmiar koła',
  'edit_listing_page.details.wheel_size_info': 'Rozmiar koła to średnica koła rowerowego, zwykle podawana na oponie.',
  'edit_listing_page.details.wheel_size_placeholder': 'Wybierz rozmiar koła',
  'edit_listing_page.details.wheel_size_required': 'Proszę wybrać rozmiar koła',
  'edit_listing_page.details.component_details': 'Szczegóły komponentów',
  'edit_listing_page.details.component_details_placeholder':
    'Jakie komponenty - grupa osprzętu, koła, widelec, amortyzatory, sztyca itp. - znajdują się na rowerze? Na przykład: grupa osprzętu Shimano Dura-Ace, koła Bontrager Aeolus Pro 3v TLR...',
  'edit_listing_page.details.component_details_required': 'Proszę podać szczegóły komponentów',
  'edit_listing_page.details.frame_size_table_title': 'Odpowiedni rozmiar ramy',
  'edit_listing_page.details.frame_size_table_info':
    'Rozmiar ramy roweru zwykle znajduje się na rurze podsiodłowej. Jeśli nie możesz go znaleźć, skonsultuj się z poniższą tabelą.',
  'edit_listing_page.details.frame_size_table_header_1': 'Rozmiar ramy',
  'edit_listing_page.details.frame_size_table_header_2': 'Rama w cm',
  'edit_listing_page.details.wheel_size.hint': 'Rozmiar koła to średnica koła rowerowego, zwykle podawana na oponie.',

  'edit_listing_page.general_details.tab_title': 'Dodaj kilka zdjęć swojego roweru',
  'edit_listing_page.general_details.image_upload_failed': 'Nie udało się przesłać zdjęcia',
  'edit_listing_page.general_details.image_upload_over_limit': 'Maksymalny rozmiar pliku wynosi 20 MB',
  'edit_listing_page.general_details.image_required': 'Proszę przesłać co najmniej 3 zdjęcia',
  'edit_listing_page.general_details.parts.image_required': 'Proszę przesłać co najmniej 3 zdjęcia',
  'edit_listing_page.general_details.image_upload.info': 'Wybierz co najmniej 3 zdjęcia',
  'edit_listing_page.general_details.image_upload.from_device': 'Prześlij z urządzenia',
  'edit_listing_page.general_details.image_upload.add_more': 'Dodaj więcej',
  'edit_listing_page.general_details.image_upload.main_image': 'Zdjęcie główne',
  'edit_listing_page.general_details.title': 'Tytuł',
  'edit_listing_page.general_details.title_placeholder': 'np. Trek Slash 9.7',
  'edit_listing_page.general_details.title_required': 'Proszę podać tytuł',
  'edit_listing_page.general_details.condition': 'Stan',
  'edit_listing_page.general_details.condition_required': 'Proszę wybrać stan roweru',
  'edit_listing_page.general_details.receipt': 'Czy rower ma oryginalny paragon?',
  'edit_listing_page.general_details.receipt_required': 'Proszę podać dostępność paragonu',
  'edit_listing_page.general_details.refurbished': 'Odnowiony rower?',
  'edit_listing_page.general_details.refurbished_info':
    'Odnowiony rower ma 12-miesięczną gwarancję udzieloną przez sprzedającego i 14-dniową prawem do zwrotu.',
  'edit_listing_page.general_details.refurbished_required': 'Proszę podać dostępność odnowionego roweru',
  'edit_listing_page.general_details.price': 'Cena',
  'edit_listing_page.general_details.placeholder': '0,00 €',
  'edit_listing_page.general_details.price_required': 'Proszę podać cenę',
  'edit_listing_page.general_details.price_too_low': 'Cena powinna wynosić co najmniej {minPrice}.',
  'edit_listing_page.general_details.price_too_high': 'Cena nie powinna przekraczać {maxPrice}.',
  'edit_listing_page.general_details.saved_image_alt': 'Zapisane zdjęcie oferty',

  'edit_listing_page.payment_info.tab_title': 'Informacje o płatności',
  'edit_listing_page.payment_info.label': 'Informacje o płatności',
  'edit_listing_page.payment_info.info_section_title': 'Uzupełnij swój profil, aby otrzymać płatność za rower',
  'edit_listing_page.payment_info.info_section_message':
    'Aby przetworzyć płatność za rower, wprowadź swoje dane poniżej. Będziesz musiał to zrobić tylko raz.',
  'edit_listing_page.payment_info.first_name': 'Imię',
  'edit_listing_page.payment_info.first_name_placeholder': 'Imię',
  'edit_listing_page.payment_info.first_name_required': 'Proszę podać imię',
  'edit_listing_page.payment_info.last_name': 'Nazwisko',
  'edit_listing_page.payment_info.last_name_placeholder': 'Nazwisko',
  'edit_listing_page.payment_info.last_name_required': 'Proszę podać nazwisko',
  'edit_listing_page.payment_info.date_of_birth': 'Data urodzenia',
  'edit_listing_page.payment_info.date_of_birth_placeholder': 'Data urodzenia',
  'edit_listing_page.payment_info.date_of_birth_required': 'Proszę podać poprawną datę urodzenia',
  'edit_listing_page.payment_info.address_line_1': 'Adres',
  'edit_listing_page.payment_info.address_line_1_placeholder': 'Adres',
  'edit_listing_page.payment_info.address_line_1_required': 'Proszę podać adres',
  'edit_listing_page.payment_info.address_line_2': 'Adres (cd.)',
  'edit_listing_page.payment_info.address_line_2_placeholder': 'Adres (cd.)',
  'edit_listing_page.payment_info.postal_code': 'Kod pocztowy',
  'edit_listing_page.payment_info.postal_code_placeholder': 'Kod pocztowy',
  'edit_listing_page.payment_info.postal_code_required': 'Proszę podać kod pocztowy',
  'edit_listing_page.payment_info.city': 'Miasto',
  'edit_listing_page.payment_info.city_placeholder': 'Miasto',
  'edit_listing_page.payment_info.city_required': 'Proszę podać miasto',

  'FieldBirthdayInput.birthdayDatePlaceholder': 'DD',
  'FieldBirthdayInput.birthdayMonthPlaceholder': 'MM',
  'FieldBirthdayInput.birthdayYearPlaceholder': 'YYYY',

  'edit_listing_page.payment_info.terms_and_conditions_required': 'Proszę zaakceptować warunki korzystania z usługi',
  'edit_listing_page.payment_info.payment_provider_error':
    'Coś poszło nie tak z dostawcą płatności. Proszę spróbuj ponownie.',
  'edit_listing_page.payment_info.generic_error': 'Coś poszło nie tak. Proszę spróbuj ponownie.',

  'parts_listing_page.details.label': 'Szczegóły',
  'parts_listing_page.categories.label': 'Typ',
  'parts_listing_page.general_details.label': 'Zdjęcia i szczegóły',
  'parts_listing_page.specifications.label': 'Specyfikacje',
  'parts_listing_page.payment_information.label': 'Informacje o płatności',

  'parts_listing_page.categories.tab_title': 'Co będziesz sprzedawać?',
  'parts_listing_page.details.tab_title': 'Ogólne informacje o sprzęcie',
  'parts_listing_page.specification.tab_title': 'Podziel się specyfikacjami komponentu.',

  'parts_listing_page.specification.panel_description':
    'Zwiększ swoje szanse na sprzedaż, dostarczając dodatkowych informacji.',

  'parts_listing_page.details.sub_category': 'Podkategoria',
  'parts_listing_page.details.sub_category_required': 'Proszę wybrać podkategorię',
  'parts_listing_page.details.sub_category_placeholder': 'Wybierz podkategorię',

  'parts_listing_page.details.brand': 'Marka',
  'parts_listing_page.details.brand_placeholder': 'Wybierz markę komponentu',
  'parts_listing_page.details.brand_required': 'Proszę wybrać markę',
  'parts_listing_page.details.model': 'Model',
  'parts_listing_page.details.model_placeholder': 'Wprowadź model komponentu',
  'parts_listing_page.details.model_required': 'Proszę podać model komponentu',
  'parts_listing_page.details.year_placeholder': 'Wybierz rok',

  'parts_listing_page.specification.more_details': 'Więcej szczegółów',
  'parts_listing_page.specifications.frame_size': 'Rozmiar',
  'parts_listing_page.specifications.frame_size_placeholder': 'Wybierz rozmiar',
  'parts_listing_page.specifications.frame_size_required': 'Proszę wybrać rozmiar',
  'parts_listing_page.specifications.frame_material': 'Materiał',
  'parts_listing_page.specifications.frame_material_placeholder': 'Wybierz materiał',
  'parts_listing_page.specifications.frame_material_required': 'Proszę wybrać materiał',
  'parts_listing_page.specifications.wheel_size': 'Rozmiar koła',
  'parts_listing_page.specifications.wheel_size_placeholder': 'Wybierz rozmiar koła',
  'parts_listing_page.specifications.wheel_size_required': 'Proszę wybrać rozmiar koła',
  'parts_listing_page.specifications.front_travel': 'Skok przedni',
  'parts_listing_page.specifications.front_travel_placeholder': 'Wprowadź skok przedni',
  'parts_listing_page.specifications.front_travel_required': 'Proszę wprowadzić skok przedni',
  'parts_listing_page.specifications.rear_travel': 'Skok tylny',
  'parts_listing_page.specifications.rear_travel_placeholder': 'Wprowadź skok tylny',
  'parts_listing_page.specifications.rear_travel_required': 'Proszę wprowadzić skok tylny',
  'parts_listing_page.specifications.discipline': 'Dyscyplina',
  'parts_listing_page.specifications.discipline_placeholder': 'Wybierz dyscyplinę',
  'parts_listing_page.specifications.hub_standard': 'Standard piasty',
  'parts_listing_page.specifications.hub_standard_placeholder': 'Wybierz standard piasty',
  'parts_listing_page.specifications.suspension_type': 'Typ',
  'parts_listing_page.specifications.suspension_mounting': 'Montaż',
  'parts_listing_page.specifications.suspension_length': 'Długość',
  'parts_listing_page.specifications.suspension_length_placeholder': 'Wprowadź długość',
  'parts_listing_page.specifications.suspension_stroke': 'Skok',
  'parts_listing_page.specifications.suspension_stroke_placeholder': 'Wprowadź długość skoku',
  'parts_listing_page.specifications.axle_dimension': 'Wymiar osi',
  'parts_listing_page.specifications.axle_dimension_placeholder': 'Wybierz wymiar osi',
  'parts_listing_page.specifications.crank_arm_length': 'Długość korby',
  'parts_listing_page.specifications.crank_arm_length_placeholder': 'Wprowadź długość korby',
  'parts_listing_page.specifications.gears': 'Przerzutki',
  'parts_listing_page.specifications.gears_placeholder': 'Wybierz przerzutki',
  'parts_listing_page.specifications.placement': 'Typ',
  'parts_listing_page.specifications.activation': 'Aktywacja',
  'parts_listing_page.specifications.front_gears': 'Przerzutki przednie',
  'parts_listing_page.specifications.front_gears_placeholder': 'Wybierz przerzutki przednie',
  'parts_listing_page.specifications.rear_gears': 'Przerzutki tylne',
  'parts_listing_page.specifications.rear_gears_placeholder': 'Wybierz przerzutki tylne',
  'parts_listing_page.specifications.chainring_mounting': 'Montaż tarczy',
  'parts_listing_page.specifications.chainring_mounting_placeholder': 'Wybierz montaż tarczy',
  'parts_listing_page.specifications.chainring_teeth': 'Ilość zębów tarczy',
  'parts_listing_page.specifications.chainring_teeth_placeholder': 'Wprowadź ilość zębów tarczy',
  'parts_listing_page.specifications.bottom_bracket_mounting': 'Montaż suportu',
  'parts_listing_page.specifications.bottom_bracket_mounting_placeholder': 'Wybierz montaż suportu',
  'parts_listing_page.specifications.bottom_bracket_width': 'Szerokość suportu',
  'parts_listing_page.specifications.bottom_bracket_width_placeholder': 'Wprowadź szerokość suportu',
  'parts_listing_page.specifications.pedals': 'Typ pedałów',
  'parts_listing_page.specifications.pedals_placeholder': 'Wybierz typ pedałów',
  'parts_listing_page.specifications.brake_type': 'Typ hamulców',
  'parts_listing_page.specifications.brakes_activation': 'Aktywacja hamulców',
  'parts_listing_page.specifications.rotor_mounting': 'Montaż tarczy',
  'parts_listing_page.specifications.rotor_placement': 'Typ tarczy',
  'parts_listing_page.specifications.front_disk_diameter': 'Średnica tarczy przedniej',
  'parts_listing_page.specifications.front_disk_diameter_placeholder': 'Wprowadź średnicę tarczy przedniej',
  'parts_listing_page.specifications.rear_disk_diameter': 'Średnica tarczy tylnej',
  'parts_listing_page.specifications.rear_disk_diameter_placeholder': 'Wprowadź średnicę tarczy tylnej',
  'parts_listing_page.specifications.wheels_placement': 'Typ kół',
  'parts_listing_page.specifications.front_wheel_size': 'Rozmiar koła przedniego',
  'parts_listing_page.specifications.front_wheel_size_placeholder': 'Wybierz rozmiar koła przedniego',
  'parts_listing_page.specifications.rear_wheel_size': 'Rozmiar koła tylnej',
  'parts_listing_page.specifications.rear_wheel_size_placeholder': 'Wybierz rozmiar koła tylnej',
  'parts_listing_page.specifications.hubs_placement': 'Typ piast',
  'parts_listing_page.specifications.front_hub_standard': 'Wymiar piasty przedniej',

  'parts_listing_page.general_details.tab_title': 'Dodaj zdjęcia swojego sprzętu',
  'parts_listing_page.general_details.description.label': 'Opis',
  'parts_listing_page.general_details.description.placeholder':
    'Inne informacje o sprzedawanym przedmiocie, w tym opis wad, opakowania, paragonu i innych istotnych danych.',
  'parts_listing_page.general_details.description.required': 'Prosimy o podanie opisu sprzętu',
  'parts_listing_page.location.label': 'Lokalizacja',
  'parts_listing_page.general_details.shipping.label': 'Wysyłka',
  'parts_listing_page.general_details.shipping.required': 'Proszę wybrać opcję wysyłki',
  'parts_listing_page.general_details.image_upload.info': 'Wybierz co najmniej 3 zdjęcia',

  'your_listings_page.edit_listing': 'Edytuj ofertę',
  'your_listings_page.delete_listing': 'Usuń ofertę',
  'your_listings_page.delete_draft': 'Usuń szkic',
  'your_listings_page.title': 'Rowery na sprzedaż',
  'your_listings_page.schema_title': 'Twoje oferty',
  'your_listings_page.delete_modal.title': 'Czy na pewno chcesz usunąć rower?',
  'your_listings_page.delete_modal.delete_action': 'Usuń',
  'your_listings_page.delete_modal.cancel_action': 'Anuluj',

  'inbox_page.label': 'Skrzynka odbiorcza',
  'inbox_page.empty_state.message': 'Nie masz żadnych wiadomości',
  'inbox_page.message_input.placeholder': 'Wyślij wiadomość',

  'about_page.title': 'O nas',
  'about_page.mission_statement':
    'Mamy misję, chcemy aby jazda na rowerze była dostępna i przystępna dla każdego niezależnie od wieku, płci, zdolności fizycznych czy statusu ekonomicznego. Pasjonuje nas promowanie zrównoważonego transportu i pomaganie ludziom w podejmowaniu zdrowego stylu życia. Dlatego nasza platforma łączy kupujących ze sprzedawcami z całego świata, oferując szeroki wybór wysokiej jakości używanych rowerów w przystępnych cenach.',
  'about_page.sustainability_title': 'Zrównoważony wybór',
  'about_page.sustainability_statement':
    'Zakup i jazda na rowerze to zrównoważone wybory korzystne zarówno dla nas - ludzi, jak i dla środowiska. Jazda na redukuje emisję dwutlenku węgla i zanieczyszczenie powietrza, podczas gdy zakup używanego roweru przedłuża jego żywotność i redukuje odpady. Dodatkowo, jazda na rowerze to promocja zdrowego stylu życia, to również mniejsze obciążenie dla systemu ochrony zdrowia. Te zrównoważone wybory przyczyniają się do zdrowszej i bardziej zrównoważonej przyszłości nas wszystkich.',
  'about_page.our_work_title': 'Nasza praca',
  'about_page.our_work_statement':
    'Nasza przygoda z rowerami zaczęła się wraz z pierwszym wyścigiem w którym braliśmy udział. Był to rok 2008 na Litwie. Od tego czasu nasza pasja do kolarstwa, poczucie wspólnoty oraz chęć wyznaczania nowych granic napędzały nas do nieustannego rozwoju w środowisku kolarskim. Angażowaliśmy się w inicjatywy dzięki którym każdy może korzystać z radości z jazdy na rowerze. Tworzyliśmy filmy, organizowaliśmy wydarzenia, uczyliśmy innych tego, czego sami nauczyliśmy się w wymagających warunkach. Dążymy do szerzenia naszej pasji do kolarstwa na szeroką skalę. Jesteśmy przekonani, że miłość do jazdy powinna być dzielona i jesteśmy oddani tej sprawie.',
  'about_page.our_team_title': 'Nasz zespół',
  'about_page.our_team_statement':
    'Jesteśmy grupą pasjonatów. Pochodzimy z Wilna na Litwie. Tworzymy rozwiązania które pomagają rozwiązywać problemy kolarzy na całym Świecie. Chcemy aby doświadczenia rowerzystów, związane z zakupem, użytkowaniem i jazdą na rowerze przyczyniały się do poprawy rzeczywistości wokół nas. Pracujemy razem aby stworzyć lepszą przyszłość dla wszystkich nas!',

  'profile_settings.title': 'Ustawienia profilu',
  'profile_settings.first_name.label': 'Imię',
  'profile_settings.first_name.placeholder': 'Imię',
  'profile_settings.first_name.required': 'Proszę podać imię',
  'profile_settings.last_name.label': 'Nazwisko',
  'profile_settings.last_name.placeholder': 'Nazwisko',
  'profile_settings.last_name.required': 'Proszę podać nazwisko',
  'profile_settings.user_name.label': 'Nazwa użytkownika',
  'profile_settings.user_name.placeholder': 'Nazwa użytkownika',
  'profile_settings.user_name.required': 'Proszę podać nazwę użytkownika',
  'profile_settings.bio.label': 'O mnie',
  'profile_settings.bio.placeholder': 'Opowiedz nam trochę o sobie...',
  'profile_settings.save': 'Zapisz zmiany',
  'profile_settings.profile_picture.file_too_large': 'Proszę załadować mniejszy obraz',
  'profile_settings.profile_picture.upload_failed': 'Wczytywanie zdjęcia profilowego nie powiodło się',
  'profile_settings.profile_picture.edit': 'Edytuj zdjęcie',
  'profile_settings.profile_update_failed': 'Aktualizacja profilu nie powiodła się',

  'account_settings.label': 'Ustawienia',
  'account_settings.page_title': 'Ustawienia konta',
  'account_settings.email.heading': 'Dane kontaktowe',
  'account_settings.email.verified': 'Twój adres email jest zweryfikowany.',
  'account_settings.errors.generic': 'Coś poszło nie tak, spróbuj ponownie.',
  'account_settings.email.label': 'Adres email',
  'account_settings.email.required': 'Proszę podać adres email',
  'account_settings.email.invalid': 'Wprowadzony adres email jest nieprawidłowy',
  'account_settings.email.taken': 'Adres email jest już używany',
  'account_settings.confirm_password.label': 'Hasło',
  'account_settings.confirm_password.placeholder': 'Wprowadź nowe hasło',
  'account_settings.confirm_password.required': 'Proszę podać hasło',
  'account_settings.confirm_password.too_short': 'Hasło powinno mieć co najmniej {minLength} znaków',
  'account_settings.confirm_email_change_label': 'Aby potwierdzić zmianę adresu email, wprowadź obecne hasło',
  'account_settings.confirm_password.failed': 'Nieprawidłowe hasło',

  'account_settings.password.heading': 'Bezpieczeństwo',

  'account_settings.save_changes': 'Zapisz zmiany',

  'password_recovery_page.forgot_password.title': 'Zapomniałeś hasła?',
  'password_recovery_page.forgot_password.message':
    'Podaj adres e-mail, którego użyłeś/aś podczas rejestracji. Wyślemy Ci e-mail z linkiem do zresetowania hasła.',
  'password_recovery_page.forgot_password.email.label': 'E-mail',
  'password_recovery_page.forgot_password.email.placeholder': 'E-mail',
  'password_recovery_page.forgot_password.email.required': 'Proszę podać e-mail',
  'password_recovery_page.forgot_password.email.not_found': 'E-mail nie znaleziony',
  'password_recovery_page.forgot_password.email.invalid': 'Nieprawidłowy adres e-mail',
  'password_recovery_page.forgot_password.submit': 'Wyślij',

  'PasswordRecoveryForm.emailInvalid': 'Wymagany jest prawidłowy adres e-mail',
  'PasswordRecoveryForm.emailLabel': 'E-mail',
  'PasswordRecoveryForm.emailNotFound':
    'Hmm. Nie znaleźliśmy konta o podanym adresie e-mail. Sprawdź poprawność adresu i spróbuj ponownie.',
  'PasswordRecoveryForm.emailPlaceholder': '[jan.kowalski@example.com](mailto:jan.kowalski@example.com)',
  'PasswordRecoveryForm.emailRequired': 'To pole jest wymagane',
  'PasswordRecoveryForm.loginLinkInfo': 'Nagle przypomniałeś/aś sobie hasło? {loginLink}',
  'PasswordRecoveryForm.loginLinkText': 'Zaloguj się.',
  'PasswordRecoveryForm.sendInstructions': 'Wyślij instrukcje',
  'PasswordRecoveryPage.actionFailedMessage': 'Coś poszło nie tak. Proszę odświeżyć stronę i spróbować ponownie.',
  'PasswordRecoveryPage.actionFailedTitle': 'Ups!',
  'PasswordRecoveryPage.emailSubmittedMessage':
    'Instrukcje dotyczące resetowania hasła zostały wysłane na adres {submittedEmailText}.',
  'PasswordRecoveryPage.emailSubmittedTitle': 'Sprawdź swoją skrzynkę odbiorczą',
  'PasswordRecoveryPage.fixEmailInfo': 'Ups, literówka w adresie e-mail? {fixEmailLink}',
  'PasswordRecoveryPage.fixEmailLinkText': 'Popraw ją.',
  'PasswordRecoveryPage.forgotPasswordMessage':
    'Spoko, podaj adres e-mail, który użyłeś/aś podczas rejestracji, a my wyślemy Ci instrukcje dotyczące ustawienia nowego hasła.',
  'PasswordRecoveryPage.forgotPasswordTitle': 'Zapomniałeś hasła?',
  'PasswordRecoveryPage.resendEmailInfo': 'Nie otrzymałeś/aś e-maila? {resendEmailLink}',
  'PasswordRecoveryPage.resendEmailLinkText': 'Wyślij kolejny e-mail.',
  'PasswordRecoveryPage.resendingEmailInfo': 'Ponowne wysyłanie instrukcji...',
  'PasswordRecoveryPage.title': 'Poproś o nowe hasło',

  'favorite_listings_page.label': 'Ulubione ogłoszenia',
  'favorite_listings_page.search_button.bike': 'Szukaj rowerów',
  'favorite_listings_page.search_button.parts': 'Szukaj części',
  'favorite_listings_page.empty_state.bike.title': 'Twoje zapisane rowery',
  'favorite_listings_page.empty_state.bike.description': 'Po zapisaniu roweru pojawi się tutaj',
  'favorite_listings_page.empty_state.parts.title': 'Twoje zapisane części rowerowe',
  'favorite_listings_page.empty_state.parts.description': 'Po zapisaniu części rowerowej pojawi się tutaj',
  'favorite_listings_page.schema_title': 'Ulubione ogłoszenia',
  'favorite_listings_page.tabs.bikes': 'Rowerów',
  'favorite_listings_page.tabs.parts': 'Części',

  'favorite_listings_page.search_button.winter': 'Wyszukiwanie sprzętu zimowego',
  'favorite_listings_page.empty_state.winter.title': 'Zachowany sprzęt zimowy',
  'favorite_listings_page.empty_state.winter.description': 'Po zapisaniu sprzętu zimowego pojawi się on tutaj',
  'favorite_listings_page.tabs.winter': 'Zima',

  'sign_up.confirm_signup_with_idp_title': 'Zarejestruj się za pomocą {idp}',
  'sign_up.confirm_signup_info_text': 'Sprawdź, czy Twoje dane są poprawne.',

  'cookies.title': 'Korzystamy z plików cookie',
  'cookies.consent_message':
    'Gearro używa plików cookie, aby poprawić jakość przeglądania, dostosować treść i reklamy oraz analizować ruch na stronie. Klikając „Akceptuj”, wyrażasz zgodę na korzystanie z plików cookie. Jeśli wolisz nie akceptować plików cookie, możesz je wyłączyć w ustawieniach przeglądarki.',
  'cookies.accept': 'Akceptować',

  'pagination.previous': 'Poprzednia strona',
  'pagination.next': 'Następna strona',
  'pagination.to_page': 'Przejdź do strony {page}',

  'validators.min_max_value': 'Wartość musi być między {minValue} a {maxValue}',
  'validators.min_max_value.invalid_type.numeric': 'Wartość powinna być liczbą',

  'about_page.schema_title': 'O nas | {siteTitle}',
  'about_page.schema_description': 'O Gearro',
  'listing_page.schema_title': '{title} | {siteTitle}',
  'profile_page.schema_title': '{name} | {siteTitle}',
  'search_page.schema_for_search': 'zapytanie',
  'page.schema_title': 'Sprzedawaj i kupuj rowery | {siteTitle}',
  'page.schema_description': 'Największa społeczność rowerowa online.',
  'edit_listing_page.schema_title': 'Utwórz ogłoszenie',
  'log_in.page_schema': 'Zaloguj się | Gearro',
  'sign_up.page_schema': 'Zarejestruj się | Gearro',
  'password_recovery_page.title': 'Poproś o nowe hasło',
  'inbox_page.schema_title': 'Skrzynka odbiorcza',
  'new_listing_page.schema_title': 'Nowe ogłoszenie',

  'search_page.schema_title': 'Kup używane rowery | Gearro',
  'search_page.schema_description': 'Kupuj i sprzedawaj używane rowery na Gearro.',
  'search_page.schema_title.bike': 'AOdkryj swój następny rower: Rowery używane wysokiej jakości | Gearro',
  'search_page.schema_description.bike':
    'Odkryj szeroką gamę wysokiej jakości używanych rowerów w Gearro. Znajdź swój idealny rower szosowy, górski lub gravelowy dzięki bezpiecznym transakcjom i ochronie kupującego. Rozpocznij swoją rowerową przygodę już dziś.',
  'search_page.schema_title.bike.road': 'Nowe i używane rowery szosowe | Gearro',
  'search_page.schema_description.bike.road':
    'Rowery szosowe z aluminium i karbonu w najlepszej cenie. Wybieraj spośród popularnych marek, takich jak Cube, Trek, Canyon, Scott i wielu innych.',
  'search_page.schema_title.bike.mountain': 'Nowe i używane rowery górskie | Gearro',
  'search_page.schema_description.bike.mountain':
    'Używane rowery MTB typu hardtail, enduro, trail i downhill w najlepszej cenie. Wybieraj spośród popularnych marek, takich jak Cube, Trek, Canyon, Scott i wielu innych.',
  'search_page.schema_title.bike.gravel': 'Rowery gravelowe | Gearro',
  'search_page.schema_description.bike.gravel':
    'Wyjedź poza utwardzone drogi z naszymi wszechstronnymi rowerami gravelowymi, zaprojektowanymi z myślą o komforcie i odporności w zróżnicowanym terenie. Odkryj więcej z Gearro.',
  'search_page.schema_title.bike.city': 'Nowe i używane rowery miejskie | Gearro',
  'search_page.schema_description.bike.city':
    'Kup sobie idealny rower miejski lub hybrydowy w najlepszej cenie. Wybieraj spośród popularnych marek, takich jak Cube, Trek, Canyon, Scott i wielu innych.',
  'search_page.schema_title.bike.electric': 'Rowery elektryczne | Rowery elektryczne | Gearro',
  'search_page.schema_description.bike.electric':
    'Wzmocnij swoją jazdę dzięki naszym rowerom elektrycznym, oferującym wspomaganą energią jazdę na rowerze dla łatwości i prędkości. Odkryj idealny e-rower pasujący do Twojego stylu życia w Gearro.',
  'search_page.schema_title.bike.bmx_dirt': 'Rowery BMX i Dirt | Gearro',
  'search_page.schema_description.bike.bmx_dirt':
    'Wskocz do akcji z naszymi rowerami BMX i dirtowymi, zaprojektowanymi do wyzwań freestyle i off-road w Gearro.',
  'search_page.schema_title.bike.folding': 'Kompaktowe rowery składane | Rowery przenośne | Gearro',
  'search_page.schema_description.bike.folding':
    'Zmaksymalizuj wygodę dzięki naszym składanym rowerom, łączącym przenośność i wydajność. Idealny do dojazdów do pracy i przechowywania w ciasnych przestrzeniach, znajdź swój w Gearro.',
  'search_page.schema_title.bike.other': 'Unikalne i specjalistyczne rowery | Znajdź swój idealny rower | Gearro',
  'search_page.schema_description.bike.other':
    'Szukasz czegoś innego? Znajdź unikalne i specjalistyczne rowery dla wszystkich preferencji. Zanurz się w naszej eklektycznej kolekcji w Gearro.',

  'search_page.schema_title.parts': 'Części i komponenty rowerowe | Gearro',
  'search_page.schema_description.parts':
    'Kupuj wysokiej jakości części i komponenty rowerowe. Znajdź ramy, zawieszenia, układy napędowe, hamulce i nie tylko, aby ulepszyć swoją jazdę w Gearro.',
  'search_page.schema_title.parts.frame': 'Ramy rowerowe na sprzedaż | Gearro',
  'search_page.schema_description.parts.frame':
    'Odkryj wytrzymałe i lekkie ramy rowerowe. Wybierz idealną podstawę dla swojej niestandardowej konstrukcji dzięki wyborowi Gearro.',
  'search_page.schema_title.parts.suspension': 'Systemy zawieszenia rowerowego | Gearro',
  'search_page.schema_description.parts.suspension':
    'Ulepsz swoją jazdę dzięki najlepiej ocenianym systemom zawieszenia rowerowego. Znajdź widelce i amortyzatory zapewniające płynną pracę w Gearro.',
  'search_page.schema_title.parts.drivetrain': 'Układy napędowe do rowerów | Gearro',
  'search_page.schema_description.parts.drivetrain':
    'Ulepsz swój rower dzięki niezawodnym układom napędowym. Łańcuchy, przerzutki i korby zapewniające wydajny transfer mocy w Gearro.',
  'search_page.schema_title.parts.brakes': 'Hamulce rowerowe | Gearro',
  'search_page.schema_description.parts.brakes':
    'Zapewnij sobie bezpieczeństwo dzięki wydajnym hamulcom rowerowym. Od hamulców tarczowych po obręczowe, znajdź wszystko, czego potrzebujesz w Gearro.',
  'search_page.schema_title.parts.wheels': 'Koła i obręcze rowerowe | Gearro',
  'search_page.schema_description.parts.wheels':
    'Płynnie tocz się dzięki naszej ofercie kół i obręczy rowerowych. Odkryj trwałe opcje dla wszystkich terenów w Gearro.',
  'search_page.schema_title.parts.tyres_tubes': 'Opony rowerowe na każdy teren | Gearro',
  'search_page.schema_description.parts.tyres_tubes':
    'Znajdź idealne opony rowerowe na każdy teren w Gearro. Wybieraj spośród opon szosowych, górskich i szutrowych.',
  'search_page.schema_title.parts.cockpit': 'Kierownice rowerowe i elementy kokpitu | Gearro',
  'search_page.schema_description.parts.cockpit':
    'Dostosuj swoje centrum sterowania dzięki kierownicom rowerowym i elementom kokpitu. Kupuj wysokiej jakości części zapewniające komfort i wydajność w Gearro.',
  'search_page.schema_title.parts.seat': 'Foteliki i siodełka rowerowe | Gearro',
  'search_page.schema_description.parts.seat':
    'Doświadcz komfortu dzięki naszej gamie fotelików i siodełek rowerowych. Znajdź odpowiednie dopasowanie na długie przejażdżki w Gearro.',

  'verify_email_page.title': 'Potwierdź swój adres email',
  'verify_email_page.verify_email_address': 'Potwierdź swój adres email',
  'verify_email_page.finish_account_setup':
    'Aby potwierdzić swój adres email, kliknij przycisk w e-mailu, który wysłaliśmy na adres {email}',
  'verify_email_page.loading_user_information': 'Ładowanie informacji o użytkowniku',
  'verify_email_page.email_not_received': 'Nie otrzymałeś/aś e-maila?',
  'verify_email_page.resend_email': 'Wyślij ponownie',
  'verify_email_page.verification_error': 'Coś poszło nie tak... Spróbuj ponownie później.',

  'verify_phone_page.title': 'Potwierdź swój numer telefonu',
  'verify_phone_page.verify_phone': 'Potwierdź swój numer telefonu',
  'verify_phone_page.phone_number': 'Numer telefonu',
  'verify_phone_page.phone_number_placeholder': '+48',
  'verify_phone_page.phone_number_required': 'Proszę podać numer telefonu',
  'verify_phone_page.phone_number_invalid': 'Nieprawidłowy numer telefonu',
  'verify_phone_page.incorrect_number_format': 'Numer telefonu jest nieprawidłowy, czy zawiera kod kraju?',
  'verify_phone_page.code': 'Kod',
  'verify_phone_page.code_placeholder': '4-cyfrowy kod z SMS',
  'verify_phone_page.code_required': 'Proszę podać kod',
  'verify_phone_page.code_invalid': 'Nieprawidłowy kod',
  'verify_phone_page.phone_number_info': 'Podaj swój numer telefonu. Zwiększy to bezpieczeństwo Twojego konta.',
  'verify_phone_page.code_info_line_1': 'Wysłaliśmy SMS na numer {phone}.',
  'verify_phone_page.code_info_line_2': 'Wprowadź 4-cyfrowy kod poniżej.',
  'verify_phone_page.loading_user_information': 'Ładowanie informacji o użytkowniku',
  'verify_phone_page.resend_code': 'Nie dostałeś/aś kodu? Wyślij ponownie',
  'verify_phone_page.generic_error': 'Coś poszło nie tak... Spróbuj ponownie później.',
  'verify_phone_page.already_created_error': 'Ten numer telefonu jest już używany przez innego użytkownika.',

  'PrivacyPolicyPage.schemaTitle': 'Polityka prywatności | {siteTitle}',

  'PasswordResetPage.passwordChangedHeading': 'Hasło zmienione',
  'PasswordResetPage.passwordChangedHelpText': 'Możesz teraz zalogować się za pomocą nowego hasła.',
  'PasswordResetPage.loginButtonText': 'Zaloguj się',
  'PasswordResetPage.mainHeading': 'Zresetuj hasło',
  'PasswordResetPage.helpText': 'Wprowadź nowe hasło poniżej.',
  'PasswordResetForm.passwordLabel': 'Nowe hasło',
  'PasswordResetForm.passwordPlaceholder': 'Wprowadź nowe hasło',
  'PasswordResetForm.passwordRequired': 'Proszę podać nowe hasło',
  'PasswordResetForm.passwordTooShort': 'Hasło powinno mieć co najmniej {minLength} znaków',
  'PasswordResetForm.passwordTooLong': 'Hasło powinno mieć maksymalnie {maxLength} znaków',
  'PasswordResetForm.submitButtonText': 'Zresetuj hasło',

  'NotFoundPage.heading': 'Strona nie znaleziona',
  'NotFoundPage.description': 'Strona, której szukasz, nie istnieje lub została przeniesiona.',

  'wallet-identity-verifaction-failed':
    'Weryfikacja tożsamości nie powiodła się; sprawdź swoje dane i spróbuj ponownie',
  'wallet.account-number-invalid': 'Numer konta bankowego jest nieprawidłowy',
  'wallet.add-an-id': 'Dodaj identyfikator',
  'wallet.available-balance': 'Dostępne saldo',
  'wallet.bank-account-number': 'Numer konta bankowego',
  'wallet.check-information': 'Upewnij się, że informacje są widoczne wyraźnie, bez rozmycia lub odblasku.',
  'wallet.check-quality': 'Sprawdź jakość',
  'wallet.choose-an-id-type': 'Wybierz typ identyfikatora do dodania',
  'wallet.continue': 'Kontynuuj',
  'wallet.country-of-bank': 'Kraj banku',
  'wallet.done': 'Gotowe',
  'wallet.driving-license': 'Prawo jazdy',
  'wallet.error': 'Błąd',
  'wallet.error-description': 'Wystąpił błąd podczas weryfikacji Twojej tożsamości. Spróbuj ponownie później.',
  'wallet.flip-the-document': 'Odwróć dokument',
  'wallet.flip-to-backside': 'Odwróć dokument i prześlij jego tylną stronę.',
  'wallet.front-of-the-document': 'Przód dokumentu',
  'wallet.full-name': 'Pełne imię i nazwisko',
  'wallet.full-name-required': 'Pełne imię i nazwisko jest wymagane',
  'wallet.identity-card': 'Dowód osobisty',
  'wallet.identity-description':
    'Pomaga nam to zweryfikować Twoją tożsamość i upewnić się, że jesteś tym, za kogo się podajesz. Weryfikacja tożsamości jest jednym ze sposobów, w jaki dbamy o bezpieczeństwo w Gearro',
  'wallet.identity-verification': 'Weryfikacja tożsamości',
  'wallet.identity-verification-in-progress': 'Weryfikacja tożsamości w toku...',
  'wallet.identity-verified': 'Twoja tożsamość została zweryfikowana. Możesz kontynuować transakcje.',
  'wallet.menu-title': 'Portfel',
  'wallet.name-and-surname': 'Imię i nazwisko',
  'wallet.next': 'Dalej',
  'wallet.no-pending-balances': 'Brak oczekujących sald',
  'wallet.passport': 'Paszport',
  'wallet.passport-description':
    'Prześlij całą stronę ze swoim zdjęciem, upewniając się, że na dole znajdują się dwie linijki kodu maszynowo czytelnego.',
  'wallet.passport-page': 'Strona paszportu',
  'wallet.pending': 'Oczekujące',
  'wallet.resume': 'Tymczasem możesz kontynuować od miejsca, w którym skończyłeś.',
  'wallet.retake': 'Ponów',
  'wallet.thank-you': 'Dziękujemy za przesłanie',
  'wallet.thank-you-for-id':
    'Dziękujemy za przesłanie swojego identyfikatora. Damy ci znać, jeśli będziemy potrzebować od ciebie czegoś jeszcze.',
  'wallet.try-again': 'Spróbuj ponownie',
  'wallet.upload-document-front': 'Prześlij przednią stronę swojego dokumentu.',
  'wallet.upload-from-device': 'Prześlij z urządzenia',
  'wallet.verify-your-identity': 'Zweryfikuj swoją tożsamość',
  'wallet.withdraw': 'Wypłać na konto bankowe',
  'parts_listing_page.specifications.brake_type_placeholder': 'Wybierz typ',

  'parts_listing_page.specifications.dropper_activation': 'Aktywacja',
  'parts_listing_page.specifications.dropper_activation_placeholder': 'Wybierz aktywację',
  'parts_listing_page.specifications.dropper_diameter': 'Średnica',
  'parts_listing_page.specifications.dropper_diameter_placeholder': 'Wybierz średnicę',
  'parts_listing_page.specifications.dropper_travel': 'Przesuw',
  'parts_listing_page.specifications.dropper_travel_placeholder': 'Wprowadź przesuw',
  'parts_listing_page.specifications.front_hub_standard_placeholder': 'Wybierz wymiar piasty przedniej',
  'parts_listing_page.specifications.grips_type': 'Typ',
  'parts_listing_page.specifications.grips_type_placeholder': 'Wybierz typ',
  'parts_listing_page.specifications.handlebar_type': 'Typ',
  'parts_listing_page.specifications.handlebar_type_placeholder': 'Wybierz typ kierownicy',
  'parts_listing_page.specifications.handlebar_width': 'Szerokość',
  'parts_listing_page.specifications.handlebar_width_placeholder': 'Wprowadź szerokość kierownicy',
  'parts_listing_page.specifications.inner_rim_width': 'Wewnętrzna szerokość obręczy',
  'parts_listing_page.specifications.inner_rim_width_placeholder': 'Wprowadź wewnętrzną szerokość obręczy',
  'parts_listing_page.specifications.rear_hub_standard': 'Wymiar tylny',
  'parts_listing_page.specifications.rear_hub_standard_placeholder': 'Wybierz wymiar tylnej piasty',
  'parts_listing_page.specifications.seat_length': 'Długość',
  'parts_listing_page.specifications.seat_length_placeholder': 'Wprowadź długość siodełka',
  'parts_listing_page.specifications.steerer_type': 'Typ steru',
  'parts_listing_page.specifications.steerer_type_placeholder': 'Wybierz typ steru',
  'parts_listing_page.specifications.stem_length': 'Długość',
  'parts_listing_page.specifications.stem_length_placeholder': 'Wprowadź długość mostka',

  'accept_terms_of_service_page.accept_button': 'Akceptuj',
  'accept_terms_of_service_page.accept_terms_of_service': 'Akceptuj warunki usługi',
  'accept_terms_of_service_page.generic_error': 'Coś poszło nie tak... Spróbuj ponownie później.',
  'accept_terms_of_service_page.title': 'Warunki usługi',

  'business.business-details': 'Dane firmy',
  'business.business-details.description': 'Wprowadź podstawowe dane identyfikacyjne firmy.',
  'business.company-location': 'Lokalizacja firmy',
  'business.company-location.description': 'Podaj oficjalny adres swojej firmy.',
  'business.fields.business_name': 'Nazwa firmy',
  'business.fields.business_name_placeholder': 'Nazwa firmy',
  'business.fields.business_name_required': 'Nazwa firmy jest wymagana',
  'business.fields.city': 'Miasto',
  'business.fields.city_placeholder': 'Miasto',
  'business.fields.city_required': 'Miasto jest wymagane',
  'business.fields.company_address_line_1': 'Adres firmy, wiersz 1',
  'business.fields.company_address_line_1_placeholder': 'Adres firmy, wiersz 1',
  'business.fields.company_address_line_1_required': 'Adres firmy, wiersz 1 jest wymagany',
  'business.fields.company_address_line_2': 'Adres firmy, wiersz 2',
  'business.fields.company_address_line_2_placeholder': 'Adres firmy, wiersz 2',
  'business.fields.date_of_birth': 'Data urodzenia',
  'business.fields.date_of_birth_placeholder': 'Data urodzenia',
  'business.fields.date_of_birth_required': 'Data urodzenia jest wymagana',
  'business.fields.first_name': 'Imię',
  'business.fields.first_name_placeholder': 'Imię',
  'business.fields.first_name_required': 'Imię jest wymagane',
  'business.fields.last_name': 'Nazwisko',
  'business.fields.last_name_placeholder': 'Nazwisko',
  'business.fields.last_name_required': 'Nazwisko jest wymagane',
  'business.fields.nip': 'NIP',
  'business.fields.nip_placeholder': 'NIP',
  'business.fields.nip_required': 'NIP jest wymagany',
  'business.fields.postal_code': 'Kod pocztowy',
  'business.fields.postal_code_placeholder': 'Kod pocztowy',
  'business.fields.postal_code_required': 'Kod pocztowy jest wymagany',
  'business.fields.region': 'Region',
  'business.fields.region_placeholder': 'Region',
  'business.fields.region_required': 'Region jest wymagany',
  'business.owner-information': 'Dane właściciela',
  'business.owner-information.description': 'Wprowadź dane osobowe właściciela firmy.',
  'business.title': 'Zweryfikuj swoją firmę',

  'listing_import_page.confirm_import_listing':
    'Importując swój produkt, potwierdzasz, że jesteś właścicielem oferty połączonej powyżej i upoważniasz Gearro do kontynuowania importu.',
  'listing_import_page.continue': 'Kontynuuj',
  'listing_import_page.import_from_url': 'Importuj z adresu URL',
  'listing_import_page.import_from_url_required': 'Wprowadź adres URL',
  'listing_import_page.link_to_listing': 'Link do Twojej oferty',
  'listing_import_page.link_to_listing_description':
    'Wprowadź pełny adres URL swojej oferty i kliknij importuj, aby przesłać produkt do Gearro. Czy możesz edytować ofertę, gdy już ją utworzymy?',
  'listing_import_page.listing_is_being_imported': 'Twoja oferta jest importowana',
  'listing_import_page.price': 'Cena',
  'listing_import_page.price_description': 'Aby sfinalizować ofertę, podaj cenę, za którą chcesz sprzedać przedmiot.',
  'listing_import_page.price_enter': 'Wprowadź cenę',
  'listing_import_page.see_my_ad': 'Zobacz moje ogłoszenia',
  'listing_import_page.import_in_progress': 'Import w toku',
  'listing_import_page.import_in_progress_description': 'Zatwierdź zamówienie, aby rozpocząć proces wysyłki',
  'listing_import_page.listing_verification': 'Weryfikacja ogłoszenia',
  'listing_import_page.listing_verification_description':
    'Weryfikacja ogłoszenia przez nasz zespół może potrwać do 48 godzin',
  'listing_import_page.listing_is_active': 'Ogłoszenie jest aktywne',
  'listing_import_page.listing_is_active_description': 'Możesz wprowadzać zmiany w razie potrzeby',
  'listing_import_page.whats_next': 'Co dalej?',

  'listing_page.error_creating_order': 'Nie udało się utworzyć zamówienia, spróbuj ponownie później.',
  'listing_page.error_loading_listing': 'Nie udało się załadować oferty, spróbuj ponownie później.',
  'listing_type_select.badge_new': 'Nowy',
  'listing_type_select.continue': 'Kontynuuj',
  'listing_type_select.import_from_link_description':
    'Opublikowano przedmiot na innej platformie? Wklej link, aby go zaimportować.',
  'listing_type_select.import_from_link_title': 'Importuj z linku',
  'listing_type_select.start_from_scratch_description': 'Wprowadź ręcznie szczegóły swojego przedmiotu.',
  'listing_type_select.start_from_scratch_title': 'Rozpocznij od zera',
  'listing_type_select.time_content_import_from_link': '10 sekund',
  'listing_type_select.time_content_start_from_scratch': '1 minuta',
  'listing_type_select.how_would_you_like_to_post_your_listing': 'Jak chcesz opublikować swoją ofertę?',
  'listing_type_select.imported_by_url': 'Importowane z URL',
  'listing_type_select.in_progress': 'W toku',
  'listing_type_select.rejected': 'Odrzucone',
  'listing_type_select.active': 'Aktywne',

  'faq.frequently_asked_questions': 'Często zadawane pytania',
  'faq.faq': 'FAQ',

  'faq.account_and_general_questions': 'Konto Gearro i pytania ogólne',

  'faq.how_do_i_create_an_account_on_gearro': 'Jak utworzyć konto na Gearro?',
  'faq.how_do_i_reset_my_password': 'Jak zresetować hasło?',
  'faq.how_do_i_delete_my_gearro_account': 'Jak usunąć konto Gearro?',
  'faq.is_it_safe_to_buy_and_sell_on_gearro': 'Czy kupowanie i sprzedawanie na Gearro jest bezpieczne?',
  'faq.how_do_i_report_a_suspicious_listing_or_user': 'Jak mogę zgłosić podejrzaną ofertę lub użytkownika?',
  'faq.how_can_i_contact_gearro_customer_support': 'Jak mogę skontaktować się z obsługą klienta Gearro?',

  'faq.how_do_i_create_an_account_on_gearro_answer':
    'Aby utworzyć konto, kliknij przycisk „Zarejestruj się” w górnej części paska górnego. Zarejestruj się przy użyciu swojego adresu e-mail lub zaloguj się za pomocą konta „Google” i postępuj zgodnie z instrukcjami, aby dokończyć rejestrację.',
  'faq.how_do_i_reset_my_password_answer':
    'Jeśli zapomniałeś hasła, wybierz „Nie pamiętam hasła” podczas procesu logowania. Otrzymasz wiadomość e-mail z linkiem do zresetowania hasła. Skontaktuj się z nami w przypadku jakichkolwiek problemów podczas procesu resetowania.',
  'faq.how_do_i_delete_my_gearro_account_answer':
    'Jeśli rozważasz opuszczenie Gearro, będziemy wdzięczni za Twoją opinię. Aby usunąć konto zgodnie z RODO, napisz do nas na adres info@gearro.com.',
  'faq.is_it_safe_to_buy_and_sell_on_gearro_answer':
    'Tak, o ile pozostajesz na platformie. Staramy się zapewnić bezpieczny rynek i zalecamy przeprowadzanie transakcji za pośrednictwem naszej platformy, aby zminimalizować ryzyko.',
  'faq.how_do_i_report_a_suspicious_listing_or_user_answer':
    'Jeśli napotkasz podejrzaną ofertę lub użytkownika, skontaktuj się z naszym zespołem pomocy technicznej. Przeanalizujemy zgłoszenie i podejmiemy odpowiednie działania.',
  'faq.how_can_i_contact_gearro_customer_support_answer':
    'Skontaktuj się z naszym działem obsługi klienta, wysyłając zapytanie na adres info@gearro.com. Opisz swoją sprawę, a nasz zespół niezwłocznie Ci pomoże.',

  'faq.selling_on_gearro': 'Sprzedaż na Gearro',

  'faq.selling_on_gearro.how_do_i_sell_an_item_on_gearro': 'Jak sprzedać przedmiot na Gearro?',
  'faq.selling_on_gearro.is_it_free_to_list_an_item_on_gearro': 'Czy wystawienie przedmiotu na Gearro jest darmowe?',
  'faq.selling_on_gearro.how_can_i_increase_the_chances_of_selling_my_item_quickly':
    'Jak mogę zwiększyć szanse na szybką sprzedaż mojego przedmiotu?',
  'faq.selling_on_gearro.how_long_does_a_listing_stay_active_on_gearro':
    'Jak długo aukcja pozostaje aktywna na Gearro?',
  'faq.selling_on_gearro.how_can_i_delete_or_edit_my_listing': 'Jak mogę usunąć lub edytować swoją ofertę?',

  'faq.selling_on_gearro.how_do_i_sell_an_item_on_gearro_answer':
    'Sprzedaż jest łatwa: utwórz konto, kliknij przycisk „Sprzedaj", wypełnij szczegóły przedmiotu, prześlij zdjęcia, a Twoja oferta zostanie uruchomiona. Kupujący mogą skontaktować się z Tobą bezpośrednio lub dokonać zakupu.',
  'faq.selling_on_gearro.is_it_free_to_list_an_item_on_gearro_answer':
    'Tak, możesz przesłać i sprzedać dowolną liczbę przedmiotów za darmo.',
  'faq.selling_on_gearro.how_can_i_increase_the_chances_of_selling_my_item_quickly_answer':
    'Podaj szczegółowy i uczciwy opis, dołącz wysokiej jakości zdjęcia i ustal rozsądną cenę.',
  'faq.selling_on_gearro.how_long_does_a_listing_stay_active_on_gearro_answer':
    'Ogłoszenia pozostają aktywne do momentu sprzedaży przedmiotu lub usunięcia ogłoszenia.',
  'faq.selling_on_gearro.how_can_i_delete_or_edit_my_listing_answer':
    'Aby usunąć lub edytować ofertę, zaloguj się na swoje konto, przejdź do „Moje oferty” i wybierz odpowiednią opcję dla oferty, którą chcesz zmodyfikować.',

  'faq.buying_on_gearro': 'Kupowanie na Gearro',

  'faq.buying_on_gearro_how_do_i_buy_items_on_gearro': 'Jak kupować przedmioty na Gearro?',
  'faq.buying_on_gearro_what_should_i_do_if_i_have_a_problem_with_a_transaction':
    'Co powinienem zrobić, jeśli mam problem z transakcją?',
  'faq.buying_on_gearro_can_i_return_an_item_after_purchase': 'Czy mogę zwrócić przedmiot po zakupie?',

  'faq.buying_on_gearro_how_do_i_buy_items_on_gearro_answer':
    'Przeglądaj oferty, korzystając z opcji wyszukiwania i filtrowania, kliknij ofertę, aby uzyskać szczegółowe informacje, a następnie dokonaj zakupu, klikając przycisk „KUP TERAZ". W razie pytań można również skontaktować się bezpośrednio ze sprzedawcą.',
  'faq.buying_on_gearro_what_should_i_do_if_i_have_a_problem_with_a_transaction_answer':
    'Najpierw spróbuj rozwiązać go bezpośrednio z drugą stroną; jeśli się nie powiedzie, skontaktuj się z obsługą klienta Gearro, podając szczegóły transakcji.',
  'faq.buying_on_gearro_can_i_return_an_item_after_purchase_answer':
    'Zwroty i refundacje są możliwe w ciągu 48 godzin od zakupu, jeśli zgłosisz problem. Każda sytuacja zostanie rozwiązana przez nasz zespół obsługi klienta.',

  'faq.shipping': 'Wysyłka',

  'faq.shipping.how_does_dpd_shipping_work_on_gearro': 'Jak działa wysyłka DPD na Gearro?',
  'faq.shipping.how_do_i_prepare_my_bike_for_shipping_with_dpd': 'Jak przygotować rower do wysyłki z DPD?',
  'faq.shipping.can_i_schedule_a_specific_pickup_time_for_shipping':
    'Czy mogę zaplanować konkretny czas odbioru przesyłki?',
  'faq.shipping.are_there_any_additional_fees_for_using_dpd_shipping':
    'Czy są jakieś dodatkowe opłaty za korzystanie z wysyłki DPD?',

  'faq.shipping.how_does_dpd_shipping_work_on_gearro_answer':
    'Po dokonaniu zakupu sprzedawca otrzymuje opłaconą z góry etykietę wysyłkową od DPD i organizuje odbiór. Otrzymasz informacje o śledzeniu przesyłki, aby monitorować status dostawy.',
  'faq.shipping.how_do_i_prepare_my_bike_for_shipping_with_dpd_answer':
    'Rower należy bezpiecznie zapakować w pudełko na rowery lub odpowiedni pojemnik, zdjąć pedały i przednie koło oraz użyć wyściółki, aby zabezpieczyć go podczas transportu.',
  'faq.shipping.can_i_schedule_a_specific_pickup_time_for_shipping_answer':
    'Tak, po zakupie etykiety wysyłkowej można wybrać dogodny czas odbioru paczki przez DPD.',
  'faq.shipping.are_there_any_additional_fees_for_using_dpd_shipping_answer':
    'Sprzedawcy nie są obciążani żadnymi dodatkowymi opłatami za korzystanie z usług DPD. Koszty wysyłki dla kupujących są obliczane i wyświetlane przy kasie.',

  'faq.contact_us': 'Skontaktuj się z nami',
  'faq.contact_us_description': 'Możesz swobodnie napisać do nas na adres {email}. Odpowiemy w ciągu 24 godzin.',
  'packaging_instructions.schema_title': 'Pakowanie zamówienia | {siteTitle}',
  'packaging_instructions.page_title': 'Pakowanie swojego przedmiotu',
  'packaging_instructions.header_1': 'Pakowanie zamówienia',
  'packaging_instructions.description_1':
    'Aby utworzyć konto, kliknij przycisk „Zarejestruj się” w górnej części paska górnego. Zarejestruj się przy użyciu adresu e-mail lub zaloguj się za pomocą konta „Google” i postępuj zgodnie z instrukcjami, aby dokończyć rejestrację.',
  'packaging_instructions.header_2': 'Znajdź skrzynkę',
  'packaging_instructions.description_2':
    'Przed rozpoczęciem pakowania należy zabezpieczyć pudełko transportowe o odpowiednim rozmiarze. Sklepy rowerowe są doskonałym źródłem informacji na ten temat, często udostępniając pudełka odpowiednie dla rowerów za darmo lub za minimalną opłatą.',
  'packaging_instructions.header_3': 'Ochrona roweru',
  'packaging_instructions.description_3':
    'Chociaż ogólna wiedza na temat pakowania jest korzystna, wysyłka roweru wymaga dodatkowej uwagi. Postępuj zgodnie z poniższymi krokami, aby zapewnić bezpieczny i wydajny proces pakowania:',
  'packaging_instructions.packing.title_1': 'Zdejmij pedały',
  'packaging_instructions.packing.description_1':
    'Prawy pedał ma zwykły gwint (odkręcanie w kierunku przeciwnym do ruchu wskazówek zegara), natomiast lewy pedał ma gwint odwrotny (odkręcanie w kierunku zgodnym z ruchem wskazówek zegara).',
  'packaging_instructions.packing.title_2': 'Zdejmowanie kół',
  'packaging_instructions.packing.description_2':
    'Zdejmij koła z roweru. \nJeśli rower jest wyposażony w hamulce tarczowe, umieść podkładkę dystansową klocków hamulcowych w zaciskach hamulcowych, aby zachować separację hamulców tarczowych. Zabezpiecz je na miejscu za pomocą opaski zaciskowej.',
  'packaging_instructions.packing.title_3': 'Usuń paski',
  'packaging_instructions.packing.description_3':
    'Zdejmij kierownicę ze wspornika kierownicy. \nPozostaw wspornik kierownicy na miejscu, aby utrzymać kompresję łożyska w rurze czołowej. \nWkręć śruby mostka z powrotem w mostek.',
  'packaging_instructions.packing.title_4': 'Demontaż sztycy',
  'packaging_instructions.packing.description_4':
    'Zdejmij sztycę i dokręć kołnierz sztycy. \nUsunięcie tych części zmniejsza ryzyko uszkodzenia podczas transportu i skutkuje bardziej kompaktowym opakowaniem.',
  'packaging_instructions.packing.title_5': 'Ochrona roweru',
  'packaging_instructions.packing.description_5':
    'Folia bąbelkowa: Owiń ramę i odłączone części folią bąbelkową, aby zabezpieczyć je przed zarysowaniami i wgnieceniami. \nZabezpiecz luźne części: Upewnij się, że wszystkie odłączone części są bezpiecznie owinięte i unieruchomione wewnątrz pudełka, aby zapobiec uszkodzeniom.',
  'packaging_instructions.packing.title_6': 'Rób zdjęcia',
  'packaging_instructions.packing.description_6':
    'Należy udokumentować zawartość przesyłki oraz jej wewnętrzne i zewnętrzne opakowanie. \nW mało prawdopodobnym przypadku utraty lub uszkodzenia, takie zdjęcia pomogą ci zapamiętać, co zapakowałeś i posłużą jako dowód prawidłowego zapakowania do złożenia wniosku.',

  'blog_page.schema_title': 'Najnowsze artykuły | {siteTitle}',
  'blog_page.page_title': 'Najnowsze artykuły',
  'blog_page.header': 'Najnowsze artykuły',
  'blog_page.not_found_schema_title': 'Artykuł nie znaleziony | {siteTitle}',
  'blog_page.not_found_title': 'Artykuł nie znaleziony',
  'blog_page.not_found_description': 'Artykuł, którego szukasz, nie istnieje.',

  'banner.verified_sellers': 'Tylko zweryfikowani sprzedawcy',
  'landing_page.create_listing.create_listing': 'Utwórz ogłoszenie w {seconds} sekund',
  'landing_page.create_listing.import_from_link': 'Importuj z linku',
  'landing_page.grid.gear_fuels_passion': 'Sprzęt, który napędza Twoją pasję',
  'landing_page.grid.gear_fuels_passion_description':
    'Od szczytów po szlaki - znajdź sprzęt rowerowy i zimowy na każdą przygodę.',
  'landing_page.grid.shop_all': 'Zobacz wszystko',
  'landing_page.section_hero.slide_winter.button': 'Sprzedaj teraz',
  'landing_page.section_hero.slide_winter.content': 'Kup używany sprzęt śnieżny',
  'landing_page.section_hero.slide_winter.title': 'Sprzedaj swoje narty i snowboard',
  'landing_page.section_hero.slide_winter_2.title': 'Czy jesteś gotowy na zimę?',
  'landing_page.section_hero.slide_winter_2.content': 'Kupuj używany sprzęt śnieżny',
  'landing_page.section_hero.slide_winter_2.button': 'Kup teraz',
  'landing_page.why_gearro.description_1':
    'Bezpieczna i płynna realizacja zamówienia z elastycznymi opcjami płatności.',
  'landing_page.why_gearro.description_2':
    'Nasza usługa kurierska oferuje ubezpieczony odbiór i dostawę od drzwi do drzwi dla każdej przedpłaconej przesyłki.',
  'landing_page.why_gearro.description_3':
    'Kupuj z pewnością: pełne zwroty i darmowe zwroty, jeśli Twój sprzęt nie odpowiada opisowi.',
  'landing_page.why_gearro.title_1': 'Bezpieczne płatności',
  'landing_page.why_gearro.title_2': 'Doskonała dostawa',
  'landing_page.why_gearro.title_3': 'Ochrona kupującego',
  'landing_page.why_gearro.why': 'Dlaczego Gearro?',
};
