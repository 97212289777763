/**
 * Export loadData calls from ducks modules of different containers
 */
import { setInitialValues as CheckoutPageInitialValues } from './CheckoutPage/CheckoutPage.duck';
import { loadData as ContactDetailsPageLoader } from './ContactDetailsPage/ContactDetailsPage.duck';
import { loadData as EditListingPageLoader } from './EditListingPage/EditListingPage.duck';
import { loadData as InboxPageLoader } from './InboxPage/InboxPage.duck';
import { loadData as LandingPageLoader } from './LandingPage/LandingPage.duck';
import { loadData as ListingPageLoader } from './ListingPage/ListingPage.duck';
import { loadData as PaymentMethodsPageLoader } from './PaymentMethodsPage/PaymentMethodsPage.duck';
import { loadData as SearchPageLoader } from './SearchPage/SearchPage.duck';
import { loadData as StripePayoutPageLoader } from './StripePayoutPage/StripePayoutPage.duck';
import {
  setInitialValues as TransactionPageInitialValues,
  loadData as TransactionPageLoader,
} from './TransactionPage/TransactionPage.duck';
import { loadData as VerifyYourEmailPageLoader } from './VerifyYourEmailPage/VerifyYourEmailPage.duck';
import { loadData as VerifyYourPhonePageLoader } from './VerifyYourPhonePage/VerifyYourPhonePage.duck';
import { loadData as VerifyYourBusinessPageLoader } from './VerifyYourBusinessPage/VerifyYourBusinessPage.duck';

const getPageDataLoadingAPI = () => ({
  LandingPage: {
    loadData: LandingPageLoader,
  },
  CheckoutPage: {
    setInitialValues: CheckoutPageInitialValues,
  },
  ContactDetailsPage: {
    loadData: ContactDetailsPageLoader,
  },
  EditListingPage: {
    loadData: EditListingPageLoader,
  },
  VerifyYourEmailPage: {
    loadData: VerifyYourEmailPageLoader,
  },
  VerifyYourPhonePage: {
    loadData: VerifyYourPhonePageLoader,
  },
  VerifyYourBusinessPage: {
    loadData: VerifyYourBusinessPageLoader,
  },
  InboxPage: {
    loadData: InboxPageLoader,
  },
  ListingPage: {
    loadData: ListingPageLoader,
  },
  PaymentMethodsPage: {
    loadData: PaymentMethodsPageLoader,
  },
  SearchPage: {
    loadData: SearchPageLoader,
  },
  StripePayoutPage: {
    loadData: StripePayoutPageLoader,
  },
  TransactionPage: {
    loadData: TransactionPageLoader,
    setInitialValues: TransactionPageInitialValues,
  },
});

export default getPageDataLoadingAPI;
