import React from 'react';
import { Field } from 'react-final-form';
import ValidationError from '../ValidationError/ValidationError';

import css from './FieldTextArea.module.css';

function FieldTextAreaComponent(props) {
  const { label, id, meta, input, ...restProps } = props;

  const { type, ...inputWithoutType } = input;

  return (
    <div className={css.root}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <textarea
        className={css.input}
        {...restProps}
        {...inputWithoutType}
        onChange={e => input.onChange(e.target.value)}
      />
      <ValidationError fieldMeta={meta} />
    </div>
  );
}

function FieldTextArea(props) {
  return <Field component={FieldTextAreaComponent} {...props} />;
}

export default FieldTextArea;
