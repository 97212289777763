import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactDOMServer from 'react-dom/server';

import { ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter, StaticRouter } from 'react-router-dom';
import { getDefaultLocale } from './api/locale';
import { NotificationsContextProvider } from './contexts/NotificationsContext';
import { RootContextProvider } from './contexts/RootContext';
import { BuyFlowContextProvider } from './contexts/buy/BuyFlowContext';
import Routes from './routing/Routes';
import routeConfiguration from './routing/routeConfiguration';
import configureStore from './store';
import { theme } from './styles/theme';
import { messages } from './translations/messages';
import { IntlProvider } from './util/reactIntl';

export function ClientApp(props) {
  const { store } = props;

  const { host } = window.location;
  const defaultLocale = getDefaultLocale(host);

  const getInitialLocale = () => localStorage.getItem('language_locale') || defaultLocale;

  const [currentLocale, setCurrentLocale] = useState(getInitialLocale());

  const changeLocale = option => {
    setCurrentLocale(option.value);
    localStorage.setItem('language_locale', option.value);
  };

  return (
    <IntlProvider
      locale={currentLocale}
      messages={messages[currentLocale]}
      defaultLocale={defaultLocale}
      textComponent="span"
    >
      <Provider store={store}>
        <HelmetProvider>
          <ThemeProvider theme={theme}>
            <RootContextProvider>
              <SnackbarProvider maxSnack={3}>
                <NotificationsContextProvider>
                  <BuyFlowContextProvider>
                    <BrowserRouter>
                      <Routes
                        host={host}
                        routes={routeConfiguration()}
                        onLocaleChange={changeLocale}
                        currentLocale={currentLocale}
                      />
                    </BrowserRouter>
                  </BuyFlowContextProvider>
                </NotificationsContextProvider>
              </SnackbarProvider>
            </RootContextProvider>
          </ThemeProvider>
        </HelmetProvider>
      </Provider>
    </IntlProvider>
  );
}

const { any, string } = PropTypes;

ClientApp.propTypes = { store: any.isRequired };

export function ServerApp(props) {
  const { url, host, context, helmetContext, store } = props;

  const defaultLocale = getDefaultLocale(host);

  const [currentLocale, setCurrentLocale] = useState(defaultLocale);

  const changeLocale = option => {
    setCurrentLocale(option.value);
  };

  HelmetProvider.canUseDOM = false;
  return (
    <IntlProvider
      locale={currentLocale}
      messages={messages[currentLocale]}
      defaultLocale={defaultLocale}
      textComponent="span"
    >
      <Provider store={store}>
        <HelmetProvider context={helmetContext}>
          <ThemeProvider theme={theme}>
            <RootContextProvider>
              <SnackbarProvider maxSnack={3}>
                <NotificationsContextProvider>
                  <BuyFlowContextProvider>
                    <StaticRouter location={url} context={context}>
                      <Routes
                        host={host}
                        routes={routeConfiguration()}
                        onLocaleChange={changeLocale}
                        currentLocale={currentLocale}
                      />
                    </StaticRouter>
                  </BuyFlowContextProvider>
                </NotificationsContextProvider>
              </SnackbarProvider>
            </RootContextProvider>
          </ThemeProvider>
        </HelmetProvider>
      </Provider>
    </IntlProvider>
  );
}

ServerApp.propTypes = { url: string.isRequired, context: any.isRequired, store: any.isRequired };

/**
 * Render the given route.
 *
 * @param {String} url Path to render
 * @param {Object} serverContext Server rendering context from react-router
 *
 * @returns {Object} Object with keys:
 *  - {String} body: Rendered application body of the given route
 *  - {Object} head: Application head metadata from react-helmet
 */
export const renderApp = (url, host, serverContext, preloadedState, hostedTranslations, collectChunks) => {
  // Don't pass an SDK instance since we're only rendering the
  // component tree with the preloaded store state and components
  // shouldn't do any SDK calls in the (server) rendering lifecycle.
  const store = configureStore(preloadedState);

  const helmetContext = {};

  // When rendering the app on server, we wrap the app with webExtractor.collectChunks
  // This is needed to figure out correct chunks/scripts to be included to server-rendered page.
  // https://loadable-components.com/docs/server-side-rendering/#3-setup-chunkextractor-server-side
  const WithChunks = collectChunks(
    <ServerApp
      url={url}
      host={host}
      context={serverContext}
      helmetContext={helmetContext}
      store={store}
      hostedTranslations={hostedTranslations}
    />
  );
  const body = ReactDOMServer.renderToString(WithChunks);
  const { helmet: head } = helmetContext;
  return { head, body };
};
