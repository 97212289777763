import axios from 'axios';

function createClient(options) {
  return axios.create(options);
}

export class Http {
  constructor(options, middlewareOptions = {}) {
    this.options = options;
    this.middlewareOptions = middlewareOptions;
    this.client = createClient(this.options);
    this.setupInterceptors();
  }

  setupInterceptors() {
    this.client.interceptors.request.use(
      req => {
        if (this.options.onRequest) {
          this.options.onRequest(req);
        }
        return req;
      },
      err => {
        if (this.options.onError) {
          this.options.onError(err, this);
        }
        return Promise.reject(err);
      }
    );

    this.client.interceptors.response.use(
      res => {
        if (this.options.onResponse) {
          this.options.onResponse(res);
        }
        return res;
      },
      err => {
        if (this.options.onError) {
          this.options.onError(err, this);
        }
        if (this.middlewareOptions.onErrorHandler) {
          return this.middlewareOptions.onErrorHandler(err, this);
        }
        return Promise.reject(err);
      }
    );
  }
}
