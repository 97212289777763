import Cookies from 'js-cookie';

const AUTH_INFO_COOKIE_KEY = `st-${process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID}-token`;

export const isAuthenticated = () => {
  const authInfo = getAuthInfo();
  return !!authInfo.access_token;
};

export function getAuthInfo() {
  try {
    return JSON.parse(Cookies.get(AUTH_INFO_COOKIE_KEY) || '{}');
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error parsing auth token from cookies', e);
    return {};
  }
}

export function updateAuthInfoTokens(accessToken, refreshToken) {
  const authInfo = getAuthInfo();
  const newAuthInfo = { ...authInfo, access_token: accessToken, refresh_token: refreshToken };

  Cookies.set(AUTH_INFO_COOKIE_KEY, JSON.stringify(newAuthInfo), { expires: 7 });
}

export function clearAuthInfo() {
  Cookies.remove(AUTH_INFO_COOKIE_KEY);
}
