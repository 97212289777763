import { Box, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { InlineTextButton } from '../Button/Button';
import ChevronLeft from './chevron-left.svg';

export const ContentCard = ({ children, linkText, onLinkClick, sx }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  if (isMobile) {
    return children;
  }

  const sxProps = {
    position: 'relative',
    mt: { xs: 0, md: 8 },
    mb: { xs: 0, md: 4 },
    mx: { xs: 0, md: 'auto' },
    width: { xs: '100%', md: '50%' },
    boxShadow: { xs: 'none', md: 3 },
    borderRadius: '8px',
    ...sx,
  };

  return (
    <Paper
      sx={sxProps}
    >
      {onLinkClick && linkText && (
        <Box mt={4} mx={5} display={{ xs: 'none', md: 'block' }}>
          <InlineTextButton onClick={onLinkClick}>
            <Stack direction="row" gap={1} alignItems="center">
              <img src={ChevronLeft} alt="chevron-left" height={24} width={24} />
              <Typography color="textPrimary" fontSize={18} fontWeight={500}>
                {linkText}
              </Typography>
            </Stack>
          </InlineTextButton>
        </Box>
      )}

      {children}
    </Paper>
  );
};
