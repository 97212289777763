import { Box, Divider, Stack, Typography } from '@mui/material';

export const AddressDetails = ({
  title,
  address,
  addressMissingLabel,
  addressError,
  missingAddressError,
  actionLabel,
  onActionClick,
}) => {
  const addressLines = addressToDetailsLines(address) || [addressMissingLabel];

  return (
    <Stack gap={3}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" fontSize={18} fontWeight={600}>
          {title}
        </Typography>
        <Box
          sx={{
            textDecoration: 'underline',
            cursor: 'pointer',
            color: 'var(--matterColorDark)',
            fontSize: 14,
            animation: 'color 0.3s ease',
            '&:hover': { color: 'var(--marketplaceColorDark)' },
          }}
          onClick={onActionClick}
          role="button"
          tabIndex="0"
        >
          {actionLabel}
        </Box>
      </Stack>
      <Typography
        variant="body2"
        sx={{ '& span': { color: 'var(--matterColorDark)' } }}
        fontSize={16}
        fontWeight={400}
        mb={1}
      >
        {addressLines?.map((line, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <span key={index}>
            {line}
            {index < addressLines.length - 1 && <br />}
          </span>
        ))}
      </Typography>
      <Divider />
      {!!addressError && (
        <Typography variant="body2" color="error" fontSize={14} fontWeight={400} mb={2}>
          {addressError}
        </Typography>
      )}
      {!addressError && !!missingAddressError && !address && (
        <Typography variant="body2" color="error" fontSize={14} fontWeight={400} mb={2}>
          {missingAddressError}
        </Typography>
      )}
    </Stack>
  );
};

function addressToDetailsLines(address) {
  if (address) {
    return [
      `${address.firstName} ${address.lastName}`,
      address.line1,
      address.line2,
      address.postalCode,
      address.city,
      address.phoneNumber,
    ].filter(Boolean);
  }
}
