import { Stack } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { ExternalLink } from '..';
import FacebookIcon from '../../assets/icons/socials/facebook.svg';
import InstagramIcon from '../../assets/icons/socials/instagram.svg';
import LinkedInIcon from '../../assets/icons/socials/linkedin.svg';
import config from '../../config';
import Spacer, { HORIZONTALL_AXIS, MEDIUM_SPACER } from '../Spacer/Spacer';
import css from './Footer.module.css';

export const FooterSocialLinks = () => {
  const intl = useIntl();
  const { siteFacebookPage, siteInstagramPage, siteLinkedInPage } = config;

  const goToFb = intl.formatMessage({ id: 'footer.facebook_link' });
  const goToInsta = intl.formatMessage({ id: 'footer.instagram_link' });
  const goToLinkedIn = intl.formatMessage({ id: 'footer.linkedin_link' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.socialLinkWrapper} title={goToFb}>
      <img className={css.socialIcon} src={FacebookIcon} />
    </ExternalLink>
  ) : null;

  const linkeIdLink = siteLinkedInPage ? (
    <ExternalLink key="linkToLinkedIn" href={siteLinkedInPage} className={css.socialLinkWrapper} title={goToLinkedIn}>
      <img className={css.socialIcon} src={LinkedInIcon} />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink key="linkToInstagram" href={siteInstagramPage} className={css.socialLinkWrapper} title={goToInsta}>
      <img className={css.socialIcon} src={InstagramIcon} />
    </ExternalLink>
  ) : null;

  const spacer = key => <Spacer key={key} size={MEDIUM_SPACER} axis={HORIZONTALL_AXIS} />;

  return (
    <Stack direction="row" gap={1}>
      {[instragramLink, spacer(0), linkeIdLink, spacer(1), fbLink].filter(v => v != null)}
    </Stack>
  );
};
