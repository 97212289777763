import { string } from 'prop-types';

export const PrivacyPolicyPL = ({ lastUpdated, legalEntityCode, registeredAddress, email, phone }) => (
  <div>
    <p className="date">Ostatnia aktualizacja: {lastUpdated}</p>
    <h1>POLITYKA PRYWATNOŚCI</h1>

    <p>
      UAB "GEARRO", kod firmy {legalEntityCode}, adres {registeredAddress}, która zarządza danymi osobowymi
      zarejestrowanymi na stronie internetowej www.gearro.com. Adres e-mail strony internetowej to {email}, nr tel.{' '}
      {phone}
      (zwany dalej "Administratorem danych").
    </p>
    <p>
      Dane osobowe podane przez użytkownika na naszej stronie internetowej są przetwarzane zgodnie z Ogólnym
      Rozporządzeniem o Ochronie Danych (UE) 2016/679 (zwanym dalej "rozporządzeniem"), wymogami i zasadami
      przetwarzania danych osobowych określonymi w Ustawie o Ochronie Prawnej Danych Osobowych Republiki Litewskiej oraz
      w aktach regulujących i ustanawiających ochronę danych osobowych oraz innych aktach prawnych Republiki Litewskiej
      lub Unii Europejskiej.
    </p>

    <h2>POSTANOWIENIA OGÓLNE</h2>
    <p>
      Niniejsza polityka prywatności ("Polityka Prywatności") opisuje zasady i procedury UAB "GEARRO" dotyczące
      gromadzenia, wykorzystywania i ujawniania informacji podczas korzystania z naszego sklepu internetowego. Opisuje
      ona również prawa użytkownika do prywatności oraz sposób, w jaki prawo chroni użytkownika.
    </p>
    <p>
      Wykorzystujemy dane osobowe użytkownika w celu świadczenia i ulepszania usług oraz sprzedaży towarów ("Usługi").
      Korzystając z Usług, użytkownik wyraża zgodę na gromadzenie i wykorzystywanie informacji opisanych w niniejszej
      Polityce prywatności.
    </p>

    <h2>POJĘCIA I INTERPRETACJA</h2>
    <h3>Interpretacja</h3>
    <p>
      Słowa pisane wielką literą mają znaczenie określone w poniższych definicjach. Definicje te mają takie samo
      znaczenie niezależnie od tego, czy występują w liczbie pojedynczej czy mnogiej.
    </p>
    <h3>Definicje</h3>
    <p>Dla celów niniejszej Polityki Prywatności:</p>
    <ul>
      <li>
        <strong>Konto</strong> - unikalne konto utworzone dla użytkownika w celu uzyskania dostępu do naszych Usług lub
        ich części;
      </li>
      <li>
        <strong>Spółka</strong> (określana w niniejszej Umowie jako Spółka, My, Nas lub Nasza) oznacza UAB "GEARRO";
      </li>
      <li>
        <strong>Pliki cookie</strong> to małe pliki instalowane przez witrynę internetową na komputerze, telefonie
        komórkowym lub innym urządzeniu, które zawierają informacje o historii przeglądania danej witryny;
      </li>
      <li>
        <strong>Kraj</strong> - Litwa;
      </li>
      <li>
        <strong>Urządzenie</strong> oznacza dowolne urządzenie, na którym można uzyskać dostęp do Usług (komputer,
        telefon komórkowy, tablet lub inne urządzenie);
      </li>
      <li>
        <strong>Dane osobowe</strong> oznaczają wszelkie informacje dotyczące osoby fizycznej, tj. osoby, której dane
        dotyczą, której tożsamość jest znana lub może zostać ustalona, bezpośrednio lub pośrednio, poprzez odniesienie
        do danych takich jak osobisty numer identyfikacyjny, jeden lub więcej czynników specyficznych dla tej osoby,
        takich jak jej cechy fizyczne, fizjologiczne, psychologiczne, ekonomiczne, kulturowe lub społeczne, dane
        dotyczące lokalizacji i identyfikator internetowy;
      </li>
      <li>
        <strong>Sklep</strong> - niniejsza strona internetowa jest obsługiwana przez spółkę UAB "GEARRO";
      </li>
      <li>
        <strong>Dane o użytkowaniu</strong> - dane zbierane automatycznie lub poprzez korzystanie z Usługi (na przykład
        czas trwania wizyty na stronie);
      </li>
      <li>
        <strong>Strona internetowa</strong> - GEARRO, dostępna pod adresem www.gearro.com;
      </li>
      <li>
        <strong>Użytkownik</strong> - osoba uzyskująca dostęp do Usługi lub korzystająca z niej. Obejmuje to firmy,
        osoby prawne, w imieniu których dana osoba uzyskuje lub uzyskała dostęp do Usługi.
      </li>
    </ul>

    <h2>GROMADZENIE I WYKORZYSTYWANIE DANYCH OSOBOWYCH</h2>
    <h3>Rodzaje gromadzonych danych</h3>
    <p>
      Podczas korzystania z naszych usług możemy poprosić użytkownika o podanie pewnych Danych Osobowych, które pomogą
      nam zidentyfikować użytkownika, skontaktować się z nim i świadczyć mu usługi. Dane osobowe mogą obejmować między
      innymi:
    </p>
    <ul>
      <li>Imię i nazwisko;</li>
      <li>Adres e-mail;</li>
      <li>Numer telefonu;</li>
      <li>Adres;</li>
      <li>Dane dotyczące użytkowania.</li>
    </ul>

    <h3>Dane dotyczące użytkowania</h3>
    <p>
      Dane dotyczące użytkowania są gromadzone automatycznie podczas korzystania z Witryny. Dane o użytkowaniu mogą
      obejmować takie informacje, jak adres protokołu internetowego urządzenia (np. adres IP), typ przeglądarki, wersja
      przeglądarki, odwiedzone strony naszej Witryny, godzina i data wizyty, czas spędzony na tych stronach, unikalne
      identyfikatory urządzeń i inne dane diagnostyczne.
    </p>
    <p>
      Gdy użytkownik korzysta z Usługi na Urządzeniu mobilnym lub za jego pośrednictwem, możemy automatycznie gromadzić
      określone informacje, w tym między innymi: typ używanego urządzenia mobilnego, unikalny identyfikator Urządzenia
      mobilnego, adres IP Urządzenia mobilnego, system operacyjny telefonu komórkowego, typ używanej mobilnej
      przeglądarki internetowej, unikalne identyfikatory Urządzenia i inne dane diagnostyczne.
    </p>
    <p>Możemy również gromadzić informacje wysyłane przez przeglądarkę użytkownika podczas odwiedzania Strony.</p>

    <h2>TECHNOLOGIE ŚLEDZENIA I PLIKI COOKIE</h2>
    <p>
      My i nasi partnerzy używamy różnych technologii do zbierania informacji o statystykach użytkowania Witryny i
      aktywności przeglądania, które mogą obejmować technologie takie jak pliki cookie lub podobne technologie, takie
      jak piksele lub sygnały nawigacyjne w celu analizy trendów w Witrynie, administrowania Witryną oraz statystyk
      spersonalizowanych informacji demograficznych o odwiedzających Witrynę, a otrzymane informacje nie mogą
      zidentyfikować konkretnej osoby. Każdy odwiedzający może kontrolować i odrzucać sposób, w jaki przeglądarka
      akceptuje lub nie akceptuje plików cookie w jego przeglądarce internetowej. Używamy własnych plików cookie w
      naszej Witrynie, wraz z plikami cookie stron trzecich, aby zapewnić pełną funkcjonalność Witryny oraz w celach
      marketingowych, aby wyświetlać specjalistyczne oferty.
    </p>

    <h2>WYKORZYSTANIE DANYCH OSOBOWYCH</h2>
    <p>Możemy wykorzystywać dane osobowe użytkownika do następujących celów:</p>
    <ul>
      <li>W celu świadczenia i utrzymania Usługi;</li>
      <li>
        W celu zarządzania Kontem - Dane Osobowe podane podczas rejestracji zapewniają dostęp do specjalnych funkcji
        Witryny;
      </li>
      <li>W celu wykonania umowy: zobowiązania do wykonania i dostawy zakupionych towarów i/lub usług;</li>
      <li>
        W celu skontaktowania się z użytkownikiem w sprawie dostawy towarów, realizacji usług, zamówienia lub innych
        ważnych informacji;
      </li>
      <li>
        Dostarczanie użytkownikowi wiadomości, ofert specjalnych i ogólnych informacji o innych oferowanych przez nas
        towarach, usługach i wydarzeniach, które są podobne do tych, które użytkownik już zakupił lub o które zapytał,
        chyba że użytkownik zdecydował się nie otrzymywać takich informacji.
      </li>
      <li>Aby spełnić żądania użytkownika;</li>
    </ul>
    <p>Dane osobowe użytkownika nie są ujawniane stronom trzecim z wyjątkiem następujących przypadków:</p>
    <ul>
      <li>Po uzyskaniu zgody Klienta;</li>
      <li>
        Dane osobowe do celów handlu elektronicznego są przekazywane partnerom, którzy świadczą usługi na rzecz Spółki w
        związku z działalnością Spółki;
      </li>
      <li>
        w przypadkach przewidzianych przez przepisy ustawowe i wykonawcze Republiki Litewskiej, właściwym organom;
      </li>
      <li>
        Strona internetowa przetwarza płatności przy użyciu:
        <ul>
          <li>
            Stripe, Inc. polityka prywatności:{' '}
            <a href="https://stripe.com/en-lt/privacy">https://stripe.com/en-lt/privacy</a>
          </li>
        </ul>
      </li>
    </ul>

    <h2>PRZECHOWYWANIE DANYCH OSOBOWYCH</h2>
    <p>
      Dane osobowe użytkownika będą przetwarzane zgodnie z Rozporządzeniem, Ustawą o prawnej ochronie danych osobowych
      Republiki Litewskiej i innymi wymogami prawnymi, jak wspomniano wcześniej. Podczas przetwarzania danych osobowych
      wdrażamy środki organizacyjne i techniczne w celu zapewnienia ochrony danych osobowych przed przypadkowym lub
      niezgodnym z prawem zniszczeniem, zmianą, ujawnieniem, a także przed jakimkolwiek innym niezgodnym z prawem
      przetwarzaniem. Przechowujemy dane osobowe użytkownika do celów archiwizacji przez okres maksymalnie 10 lat lub do
      czasu, gdy będziemy zobowiązani do przestrzegania naszych zobowiązań prawnych, rozstrzygania sporów i wypełniania
      naszych zobowiązań. Ma to również na celu zapobieganie nadużyciom i innym niezgodnym z prawem działaniom.
    </p>
    <p>
      Wszystkie dane osobowe i inne informacje dostarczone przez użytkownika są traktowane jako poufne. Dostęp do Danych
      Osobowych jest ograniczony do tych naszych pracowników i podmiotów przetwarzających dane, dla których jest to
      niezbędne do wykonywania ich funkcji zawodowych lub świadczenia usług.
    </p>

    <h2>LINKI DO INNYCH STRON INTERNETOWYCH</h2>
    <p>
      W naszej Witrynie mogą znajdować się łącza do innych stron, które nie są przez nas obsługiwane. Kliknięcie łącza
      strony trzeciej spowoduje przekierowanie na jej stronę internetową. Zdecydowanie zalecamy zapoznanie się z
      polityką prywatności każdej odwiedzanej witryny.
    </p>
    <p>
      Nie mamy kontroli i nie ponosimy odpowiedzialności za treść, politykę prywatności lub praktyki stron internetowych
      lub usług stron trzecich.
    </p>

    <h2>PROCEDURA ZMIANY NINIEJSZEJ POLITYKI PRYWATNOŚCI</h2>
    <p>
      Możemy aktualizować naszą Politykę prywatności. Powiadomimy użytkownika o wszelkich zmianach, publikując nową
      Politykę prywatności na tej stronie.
    </p>
    <p>
      Zanim zmiana wejdzie w życie, powiadomimy użytkownika pocztą elektroniczną i/lub w widocznym miejscu w naszym
      serwisie oraz zaktualizujemy datę "Ostatniej aktualizacji" w górnej części niniejszej Polityki prywatności.
    </p>
    <p>
      Zalecamy okresowe sprawdzanie niniejszej Polityki prywatności pod kątem wszelkich zmian. Zmiany w niniejszej
      Polityce prywatności wchodzą w życie z chwilą ich opublikowania na tej stronie.
    </p>

    <h2>TWOJE PRAWA</h2>
    <p>
      Wiedzieć, jakie dane zgromadziliśmy na Twój temat: po otrzymaniu od danej osoby bezpłatnego wniosku o podanie
      danych, które zgromadziliśmy na jej temat, przekażemy te dane w ciągu 30 dni kalendarzowych od daty złożenia
      wniosku. Każdy ma prawo wiedzieć, jakie informacje zgromadziliśmy i zażądać ich poprawienia lub usunięcia. Jeśli
      po złożeniu wniosku mamy jakiekolwiek podejrzenia co do tożsamości lub wieku wnioskodawcy, mamy prawo poprosić o
      dokument tożsamości wnioskodawcy lub jego kopię.
    </p>
    <p>
      Rezygnacja z wykorzystywania informacji do celów marketingu bezpośredniego: nie zobowiązujemy naszych klientów do
      otrzymywania biuletynów ani żadnych innych wysyłanych do nich informacji promocyjnych, więc możesz swobodnie
      zrezygnować z tej usługi, logując się do swojego profilu lub klikając interaktywny link na dole każdej otrzymanej
      wiadomości e-mail z promocją.
    </p>
    <p>
      Prawo do bycia zapomnianym: o ile Rozporządzenie lub ustawodawstwo Republiki Litewskiej nie stanowi inaczej, na
      zgodne z prawem żądanie użytkownika usuniemy wszystkie dane, które zebraliśmy na jego temat z naszych serwerów i
      systemu informatycznego w ciągu 4 dni kalendarzowych, chyba że byłoby to niemożliwe lub wymagałoby
      nieproporcjonalnego wysiłku ze strony Administratorów Danych. Dane nie mogą zostać usunięte, jeśli ich
      przechowywanie jest wymagane przez ustawodawstwo Unii Europejskiej lub Republiki Litewskiej.
    </p>
    <p>
      Brak zgody: możesz nie zgodzić się z naszą polityką prywatności, jednak ze względu na fakt, że potrzebujemy
      pewnych danych od Ciebie do wykonania umowy i zapewnienia sprawnego działania Witryny, potrzebujemy Twoich danych,
      więc jeśli nie zgadzasz się z naszymi zasadami, niestety nie możemy zawrzeć z Tobą umowy sprzedaży i świadczyć Ci
      oferowanych przez nas usług.
    </p>
    <p>
      Aby wyegzekwować swoje prawa, skontaktuj się z nami pisemnie, w języku narodowym, za pośrednictwem poczty
      elektronicznej:
    </p>

    <h2>ODPOWIEDZIALNOŚĆ</h2>
    <p>
      Użytkownik jest odpowiedzialny za zachowanie poufności swojego hasła i danych użytkownika oraz za wszelkie
      działania (przesyłanie danych, składanie zamówień itp.), które są wykonywane w naszej Witrynie po zalogowaniu się
      przy użyciu danych logowania. Użytkownik nie może ujawniać swojego hasła osobom trzecim. Jeśli osoba trzecia
      uzyska dostęp do usług świadczonych w naszej Witrynie przy użyciu danych logowania użytkownika, uznamy, że
      użytkownik zalogował się. W przypadku utraty danych logowania należy niezwłocznie powiadomić nas pocztą,
      telefonicznie, faksem lub pocztą elektroniczną.
    </p>
    <p>
      Użytkownik musi przechowywać swoje hasło i nazwę logowania, jeśli utworzył Konto, oraz inne dane w bezpiecznym
      miejscu i nie ujawniać swoich danych logowania osobom trzecim. Nie ponosimy odpowiedzialności, jeśli Dane Osobowe
      użytkownika zostaną bezprawnie zmienione, ujawnione, zniszczone, tożsamość użytkownika zostanie skradziona lub w
      inny sposób nieuczciwie wykorzystana w związku z korzystaniem z Danych Osobowych użytkownika, niezależnie od tego,
      czy wynika to z winy użytkownika, czy z zaniedbania, które powstało w wyniku nieprzestrzegania przez użytkownika
      niniejszej Polityki Prywatności, zasad korzystania z Witryny lub przepisów prawa. Jeśli osoba trzecia uzyska
      dostęp do usług świadczonych w naszej Witrynie przy użyciu danych logowania użytkownika, założymy, że użytkownik
      się zalogował. W przypadku utraty danych logowania należy niezwłocznie poinformować nas o tym pocztą,
      telefonicznie, faksem lub pocztą elektroniczną. W żadnym wypadku nie będziemy również ponosić odpowiedzialności za
      jakiekolwiek szkody wyrządzone użytkownikowi w wyniku podania przez niego nieprawidłowych lub niekompletnych
      danych osobowych lub niepoinformowania nas o jakichkolwiek zmianach takich danych.
    </p>

    <h2>KONTAKT</h2>
    <p>
      W przypadku jakichkolwiek pytań dotyczących niniejszej Polityki prywatności, można skontaktować się z nami pod
      adresem {email}.
    </p>
  </div>
);

PrivacyPolicyPL.defaultProps = {
  legalEntityCode: '[________]',
  registeredAddress: '[_____________]',
  email: '[____________________]',
  phone: '[___________]',
  lastUpdated: '[__.__.____]',
};

PrivacyPolicyPL.propTypes = {
  legalEntityCode: string,
  registeredAddress: string,
  email: string,
  phone: string,
  lastUpdated: string,
};
