import { IconButton, Slide } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { createContext, useCallback, useContext, useMemo } from 'react';
import CloseIcon from '../assets/icons/close.png';

const NotificationsContext = createContext({});

export const NotificationsContextProvider = ({ children }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  /**
   * Show a notification
   * @param {string} message
   * @param {string} type - 'info' | 'success' | 'warning' | 'error'
   * @param {number} durationS - duration in seconds
   */
  const show = useCallback(
    (message, type = 'info', durationS = 6) => {
      enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: durationS * 1000,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        TransitionComponent: Slide,
        action: key => (
          <IconButton onClick={() => closeSnackbar(key)} color="inherit" sx={{ img: { height: 14, width: 14 } }}>
            <img src={CloseIcon} alt="close" />
          </IconButton>
        ),
      });
    },
    [enqueueSnackbar, closeSnackbar]
  );

  const hide = useCallback(
    key => {
      closeSnackbar(key);
    },
    [closeSnackbar]
  );

  const value = useMemo(() => ({ show, hide }), [show, hide]);

  return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
};

export const useNotificationsContext = () => {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error('useNotificationsContext must be used within a NotificationsContextProvider');
  }
  return context;
};
